<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" [fullScreen]="true" type="ball-spin-clockwise">
    <p style="color: white">Loading... </p>
</ngx-spinner>

<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
        </div>
        <div class="content-body">
            <div class="row">
                <div class="col-xl-3 col-md-6 col-12">
                    <div class="card">
                        <div class="text-center">
                            <div class="card-body">
                                <img [src]="image" *ngIf="image != null && image != 'null'"
                                    class="rounded-circle  height-150 width-150" alt="Card image">
                                <img src="assets/images/no-image.png"
                                *ngIf="image==null || image=='null' "
                                    class="rounded-circle  height-150 width-150" alt="Card image">
                            </div>
                            <div class="card-body">
                                <h4 class="card-title">{{email}}</h4>
                                <h6 class="card-subtitle text-muted">{{mobile}}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3 col-md-6 col-12">
                    <form (ngSubmit)="setNewPassword()" [formGroup]="newPasswordFrom">
                        <div class="card p-1 row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="old_password">Old Password *</label>
                                    <input type="password" id="old_password" class="form-control"
                                        formControlName="old_password" placeholder="Old Password"
                                        [ngClass]="{ 'is-invalid': submitted && f.old_password.errors }" />
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.old_password.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="f.old_password.errors.required">
                                            Old password is required</div>
                                    </small>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="new_password">New Password *</label>
                                    <input type="password" id="new_password" class="form-control"
                                        formControlName="password" placeholder="New Password"
                                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.password.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="f.password.errors.required">
                                            New password is required</div>
                                        <div *ngIf="f.password.errors.minLength">
                                            Password must be greater than 8 characters long</div>
                                    </small>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="projectinput1">Confirm New Password*</label>
                                    <input type="password" id="projectinput1" class="form-control"
                                        formControlName="password_confirmation"
                                        [ngClass]="{ 'is-invalid': submitted && f.password_confirmation.errors }"
                                        placeholder="Confirm New Password" />
                                    <small class="form-text text-muted danger" class="invalid-feedback"
                                        *ngIf="submitted && f.password_confirmation.errors">
                                        <div *ngIf="f.password_confirmation.errors.required">
                                            Password confirmation is required</div>
                                    </small>
                                </div>
                            </div>
                            <div class="col-12 text-end">
                                <button type="submit" class="btn btn-outline-primary">Change</button>
                            </div>
                        </div>
                    </form>
                </div> -->
                <div class="col-xl-6 col-12 ">
                    
                </div>
            </div>
        </div>
    </div>
</div>