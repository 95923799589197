import { Component, Input, } from '@angular/core';
import { ApiService } from '../../_services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import {  Renderer2, ElementRef, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-show-assessment-form-output',
  templateUrl: './show-assessment-form-output.component.html',
  styleUrls: ['./show-assessment-form-output.component.css']
})
export class ShowAssessmentFormOutputComponent {
  [x: string]: any;
  @BlockUI('modalThemes') blockUIModalThemes: NgBlockUI;
  @BlockUI('zeroConfiguration') blockUIZeroConfiguration: NgBlockUI;
  @BlockUI('defaultOrdering') blockUIDefaultOrdering: NgBlockUI;

  public configScroll: PerfectScrollbarConfigInterface = { suppressScrollY: false, wheelPropagation: false };
  public config: PerfectScrollbarConfigInterface = { suppressScrollX: true };

  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: true }) directiveRef?: PerfectScrollbarDirective;
  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  
  member_id: string;
  questionAnsAnswer: [];
  baselineintakeData: any;
  submitTime: any;

  constructor(
    private renderer: Renderer2,
    private ApiService: ApiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute, 
    private activatedRoute: ActivatedRoute,
    private router :Router,
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
       this.member_id = params.get('id');
    });
    this.getUserDetails(this.member_id);
    
  }

  getUserDetails(id: any){
    this.spinner.show();
    this.ApiService.getUserAns(this.member_id).subscribe( (response:any) => {
      if (response.data.success) {
        this.baselineintakeData = response.data.data;
        console.log("this.questionAnsAnswer==>",this.baselineintakeData)
        if(  this.baselineintakeData){
          // for(let data of this.baselineintakeData){
            let item = JSON.parse(this.baselineintakeData?.answer)
            console.log("item==>",)
            this.questionAnsAnswer = item
            this.submitTime = this.baselineintakeData?.version
            Object.keys(item).forEach(key => {
              console.log("key, item[key]==>",key, item[key]);
            }); 
          // }
        }
        console.log("this.questionAnsAnswer==>",this.baselineintakeData)
        // this.editClient('OnbordingForm')
        
      } else {
        this.toastr.error(response.data.message);
      }
      this.spinner.hide();
    });
  }
  editClient( modalName: any) {
  
    // this.openCreateForm(modalName);
   
  
  }
  // openCreateForm(LoginFormContent) {
  //   console.log("LoginFormContent==>",LoginFormContent)
  //   this.modalService.open(LoginFormContent, { windowClass: 'animated FadeIn', backdrop: 'static', size: 'lg' });
    
  // }
}
