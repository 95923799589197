<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" [fullScreen]="true" type="ball-spin-clockwise">
    <p style="color: white">Loading... </p>
</ngx-spinner>

     
<section id="configuration" class="responsive-section">
    <div class=" bg-white" style="padding: 30px;">
        <div class="mb-1" style="text-align: center;border-bottom: 1px solid black;">
            <h2 style="font-family: inherit">Assessment Form</h2>
        </div>
      
        <form class="form-horizontal form-simple " #assessment_form="ngForm" (ngSubmit)="onSubmit(question_form)">
            <div *ngFor="let key of this.questionAnsAnswer; let i = index" class="form-group border-bottom">
                
                <div class="form-group mb-0">
                    <label class="text-style"  style="font-family: inherit">Q{{i + 1}}. {{ key.question }}</label> 
                </div>
                <span *ngFor="let ans of key.answer" class="answer-item"  style="font-family: inherit">
                    <label><span style="font-weight: bold;">A. </span> {{ ans }}</label><br>
                </span>
            </div>
            <br>
        </form>
    </div>
</section>
