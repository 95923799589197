<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" [fullScreen]="true" type="ball-spin-clockwise">
    <p style="color: white">Loading... </p>
  </ngx-spinner>
  <div class="app-content content">
      <div class="content-wrapper">
        <!-- <div class="content-header row">
        </div> -->
        <div class="content-body">
          <section class="row flexbox-container">
            <div class="col-12 d-flex align-items-center justify-content-center">
              <div class="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
                <div class="card border-grey border-lighten-3 m-0">
                  <div class="card-header border-0">
                    <div class="card-title text-center">
                      <div class="p-1">
                        <img [src]="logo_url"  style="width: 100px; height:100px" alt="branding logo">
                      </div>
                    </div>
                    <h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2"><span class="font-weight-bold">Motus9
                        </span></h6>
                  </div>
                  <div class="card-content">
                    <div class="card-body">
                      <form class="form-horizontal form-simple" #add_form="ngForm" (ngSubmit)="isTempToken()  ? verifyOtp(add_form.value) : sendOtp(add_form.value)">
                        <div class="form-group position-relative has-icon-left mb-0">
                          <input type="text" class="form-control" id="user-name" [(ngModel)]="mobile_no" name="mobile_no" placeholder="Enter Mobile No." required (keypress)="keyPress($event)" oncopy="return false" maxlength="12" [disabled]="isTempToken()">
                          <div class="form-control-position">
                            <i class="feather ft-phone"></i>
                          </div>
                        </div><br>
                        <span *ngIf="emailmsg != ''" class="text-danger" style="font-size:16px ;">
                          {{emailmsg}}
                      </span><br>
                        <div class="form-group position-relative has-icon-left mb-0" *ngIf="isTempToken()">
                          <input type="password" class="form-control" id="user-otp" name="otp" ngModel placeholder="Enter OTP" 
                            required (keypress)="keyPress($event)" maxlength="4">
                          <div class="form-control-position">
                            <i class="la la-key"></i>
                          </div>
                        </div>
                        <span *ngIf="passmsg != ''" class="text-danger" style="font-size:16px ;">
                          {{passmsg}}
                      </span>
                        <div class="form-group row" *ngIf="isTempToken()">
                          <div class="col-sm-6 col-12 text-center text-sm-left">
                            <div class="custom-control custom-checkbox mb-1">
                            
                            </div>
                          </div>
                        </div>
                        <button type="submit" class="btn btn-mtu btn-block"><i class="feather ft-unlock"></i>&nbsp;&nbsp;&nbsp;{{ isTempToken() ? 'Verify OTP' : 'Send OTP' }}</button>
                        <!-- <button type="button" (click)="sendOtp(add_form.value)" class="btn btn-mtu btn-block" *ngIf="isTempToken()" [disabled]="disable_resend_otp">
                          <i class="la la-refresh spinner" *ngIf="disable_resend_otp"></i>&nbsp;&nbsp;&nbsp;Resend OTP
                        </button> -->
                      </form>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="">
                      <p class="float-xl-left text-center m-0"><a [routerLink]="['/authentication/recoverPassword']" class="card-link">
                          </a></p>
                      <p class="float-xl-right text-center m-0"> <a [routerLink]="['/authentication/registerSimple']"
                          class="card-link"></a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
    
        </div>
      </div>
    </div>
    