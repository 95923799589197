import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../_services/api.service';
import { ChatService } from 'src/app/motus/_services/chat.service';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
  providers: [DatePipe]
})
export class ChatComponent {
  @ViewChild('scrollBottom') private scrollBottom: ElementRef;
  image: any;
  first_name: any;
  last_name: any;
  router: any;
  file: File | null;
  imageURL: string;
  base64_string: string = null;
  temp: any[];
  chatss: any = [];
  formattedDate: string;

  constructor(
    private datePipe: DatePipe,
    private ApiService: ApiService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private ChatService: ChatService
  ) { }

  message_text: string = '';
  admin_info: any = '';
  message_data: any;
  get_chat_data: any;
  chats: any = [];
  chat_msgs: any;
  conversation_id: any;
  chat_window_show: boolean = false;
  visibility = false;
  chatStarter = true;
  text_input_visibilty = true;
  pdf_link_prefix = environment.S3_URL + "motus9/chat/";
  ngOnInit(): void {
    this.admin_info = JSON.parse(localStorage.getItem('admin_info')!);
    this.spinner.show();
    this.getAllConversations();
    this.ChatService.newMsgReceiveSocket().subscribe((message: any) => { 
      this.spinner.show();
      // checking if new msg belongs to current opened chat
      if(message.conversation_id == this.conversation_id){
        this.spinner.hide();
        this.chat_msgs.push(message);
        this.processMsgs(this.conversation_id); 
      }
      // update unread count
      let new_msg_chat = this.chats.find( (c:any) => (( c.id == message.conversation_id) && (message.sender !== null) && (this.conversation_id != message.conversation_id) ));
      if(new_msg_chat){
        new_msg_chat.unseen_count = ++new_msg_chat.unseen_count;
        new_msg_chat.last_message = message;
        this.sortChats();
      }
      this.spinner.hide();
    }); 
  }
  ngAfterViewChecked() {
    this.scrollToBottom();
  }
  scrollToBottom(): void {
    try {
      // this.scrollBottom.nativeElement.scrollTop = this.scrollBottom.nativeElement.scrollHeight; //template code
      var container = document.querySelector(".chat-container");
      var scrollHeight = container.scrollHeight;
      container.scrollTop = scrollHeight;
    } catch (err) { }
  }
  getAllConversations() {
    this.spinner.show();
    this.ApiService.getChat().subscribe((data: any) => {
      const response = data.data;
      if (response.success == true) {
        // removing self
        this.chats = response.data.filter( (chat:any) => chat.user1_id != this.admin_info.id);

        this.chats.forEach((chat: any) => {
          if(chat.last_message){
            const chat_time = chat.last_message.msg_time;
            chat.last_message.day  = this.formatMsgTime(chat_time);
            // console.log("chat.last_message.day====>",chat.last_message.day)
          }
       
        });
      
      this.sortChats();
        this.spinner.hide();
      } else {
        this.toastr.error(`${response.message}`);
      }
    });
   
  }
  openFormModal(formName) {
    this.modalService.open(formName, { windowClass: 'animated FadeIn', backdrop: 'static'});
  }
  getMsgs(id: any, image: any, first_name: any, last_name: any) {
    this.clearInput();
    this.chat_msgs = [];
    this.message_text = null;
    this.chatStarter = false;
    this.visibility = true;
    this.chat_window_show = true;
    this.conversation_id = id;
    this.image = image;
    this.first_name = first_name;
    this.last_name = last_name;
    let obj = {
      conversation_id: id,
      limit: 1000,  // only latest 1000 msg will be shown 
      page_number: 1,
    };

    this.ApiService.fetchMsgs(obj).subscribe((data: any) => {
      const response = data.data;
      if (response.success == true && response.data && response.data.messages.length > 0) {
        this.chat_msgs = response.data.messages.reverse();
        this.processMsgs(id);
      }
      else {
        this.chat_msgs = [];
        this.toastr.error(`No messages found`);
      }
    });
  }
  sendMessage() {
    let msg_type = 'text';
    let msg = this.message_text;
    if (this.file && this.imageURL && this.imageURL !== '') {
      if(this.base64_string.startsWith('data:image/')){
        this.spinner.show();
        console.log('----------IMAGE')
        msg_type = 'image';
      }else if(this.base64_string.startsWith('data:application/pdf')){
        this.spinner.show();
        console.log('--------PDF')
        msg_type = 'pdf';
      }else{
        this.toastr.error('Invalid format')
      }
      msg = this.base64_string;
    }else if(this.message_text == "" || this.message_text.trim() == ""){
      return ;
    }
    const now = new Date();
    const utcTime = now.toISOString();
    const formattedUtcTime = utcTime.slice(0, 19).replace('T', ' ');

    let sent_msg = {
      "conversation_id": this.conversation_id,
      "msg": msg,
      "msg_type": msg_type,
      "msg_time": formattedUtcTime
    };
    // console.log('send message ==>', sent_msg)
    this.ChatService.sendMsgSocket(sent_msg);
    this.clearInput();
  }
  onFileSelected(event){
    let two_mb = 2097152;
    this.file = (event.target as HTMLInputElement).files[0];
    if( !(this.file.size <= two_mb)){
      this.toastr.error('Maximum allowed size is 2MB')
      return 
    }

    const reader = new FileReader();
    reader.onload = (e:any) => {
      this.imageURL = reader.result as string;
      // this.base64_string.startsWith('data:application/pdf')
      this.base64_string = this.imageURL;
      // console.log('base 64 string', this.base64_string);
    }
    reader.readAsDataURL(this.file)
    this.text_input_visibilty = false;
  }
  clearInput(){
    this.message_text = "";
    this.text_input_visibilty = true;
    this.imageURL = null;
    this.file = null;
  }
  processMsgs(id:any){
    //----- seen msg 
    let msg_status_obj = {
      conversation_id:id,
    }
    this.ChatService.updateMsgStatus(msg_status_obj);
    this.chats.find( (c:any) => ( c.id == id)).unseen_count = 0;
    //----- seen msg 
    
    // for showing dates in msgs
    for (let [index, single_msg] of this.chat_msgs.entries()) {
      if (this.chat_msgs[index - 1] != undefined) {
        let msg_date =  moment(single_msg.msg_time).local().format('D MMMM YYYY');
        // console.log("msg_date====>",msg_date)
        let pre_msg_date = moment(this.chat_msgs[index - 1].msg_time).local().format('D MMMM YYYY');

        single_msg.show_date = (msg_date != pre_msg_date) ? msg_date : null;
      }else{
        single_msg.show_date = moment(single_msg.msg_time).local().format('D MMMM YYYY');
      }
      if(single_msg.msg_type == 'pdf' ){
        single_msg.pdf_name = single_msg.msg.substring(this.pdf_link_prefix.length);
      }
      let URL_REGEXP = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator;
      if(single_msg.msg_type == 'text' && URL_REGEXP.test(single_msg.msg)){
        single_msg.is_link = true;
      }
    }
  }
  sortChats() {
    // console.log("this.chats ===>", this.chats);
  
    this.chats.sort((a, b) => {
      const aTime: number = new Date(a.last_message?.msg_time || 0).getTime();
      const bTime: number = new Date(b.last_message?.msg_time || 0).getTime();
      
      return bTime - aTime;
    });
  
    // console.log("this.chats ===>", this.chats);
  }

  getAllConversations1() {
    this.ApiService.getChat().subscribe((data: any) => {
      const response = data.data;
      if (response.success == true) {
        this.chats = response.data.filter( (chat:any) => chat.user1_id != this.admin_info.id);
        this.chats.forEach((chat: any) => {
          if(chat.last_message){
            const chat_time = chat.last_message.msg_time;
            chat.last_message.day  = this.formatMsgTime(chat_time);
          }
       
        });
        this.sortChats();
      } else {
        this.toastr.error(`${response.message}`);
      }
    });
  }
  updatedefaultFilter(event) {
    const val = event.target.value.toLowerCase();
    
    if (val) {
      const filteredChats = this.chats.filter(d =>
        (d.chat_first_name && d.chat_first_name.toLowerCase().includes(val)) ||
        (d.chat_last_name && d.chat_last_name.toLowerCase().includes(val)) ||
        (!val || val.trim() === "")
      );
  
      this.chatss = filteredChats.length === 0 ? this.chats : filteredChats;
    } else {
      this.chatss = [];
      this.getAllConversations1();
    }
  }
  
  // formatMsgTime(msgTime: string): string {
  //   const date = new Date(msgTime);
  //   return this.datePipe.transform(date, 'EEEE'); // 'EEEE' will display the day (e.g., Monday)
  // }

  formatMsgTime(msgTime: string): string {
    const date = new Date(msgTime);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    console.log("date=======>",date)
    if (this.isToday(date)) {
      return 'Today';
    } else if (this.isYesterday(date)) {
      return 'Yesterday';
    } else {
      
     const originalDate = new Date(date);
      return this.datePipe.transform(originalDate, 'dd/MM/YYYY'); // Display day, month, and year
    }
  }

  isToday(date: Date): boolean {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  isYesterday(date: Date): boolean {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    return (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    );
  }

  scrollDown() {
    window.scrollBy({
      top: window.innerHeight, // Adjust this value as needed for the desired scroll distance
      behavior: "smooth" // This creates a smooth scroll effect
    });
  }
// updatedefaultFilter(event) {
//   const val = event.target.value.toLowerCase();
//   if(val){
//     this.temp = [...this.chats];
//     // Filter the data
//     const filteredChats = this.temp.filter(d =>
//       (d.chat_first_name && d.chat_first_name.toLowerCase().includes(val)) ||
//       (d.chat_last_name && d.chat_last_name.toLowerCase().includes(val)) ||
//       (!val || val.trim() === "")
//     );
//     if (filteredChats.length === 0) {
//       // Reset to the original data (temp array)
//       this.chatss = this.temp;
//     } else {
//       // Update the original array with the filtered results
//       this.chatss = filteredChats;
//     }
//   }else{
//     this.chatss = [];
//     this.getAllConversations1();
//     this.chats = this.chats
//   }
// }

  
  

  // sortChats(){
  //   this.chats = this.chats.sort((a:any, b:any) => {
  //     if(a.last_message && b.last_message){
  //       let a_time = new Date(a.last_message.msg_time);
  //       let b_time = new Date(b.last_message.msg_time);
        
  //       return (b_time > a_time  )
  //     }else if(a.last_message){
  //       return false;
  //     }else{
  //       return true;
  //     }
  //   });
  // }

  // sendMessage_0() {
  //   let msg_type = 1;
  //   let msg = this.message_text;
  //   if (this.file && this.imageURL && this.imageURL !== '') {
  //     if(this.base64_string.startsWith('data:image/')){
  //       this.spinner.show();
  //       console.log('----------IMAGE')
  //       msg_type = 2;
  //     }else if(this.base64_string.startsWith('data:application/pdf')){
  //       this.spinner.show();
  //       console.log('--------PDF')
  //       msg_type = 3;
  //     }else{
  //       this.toastr.error('Invalid format')
  //     }
  //     msg = this.base64_string;
  //   }else if(this.message_text == "" || this.message_text.trim() == ""){
  //     return ;
  //   }
  //   const now = new Date();
  //   const utcTime = now.toISOString();
  //   const formattedUtcTime = utcTime.slice(0, 19).replace('T', ' ');

  //   // let current_local_time = moment().format(ConstUrl.date_format + ' HH:mm:ss');
  //   let sent_msg = {
  //     "type": msg_type,
  //     "conversation_id": this.conversation_id,
  //     "msg_user_type": "admin",
  //     "sent_by": this.admin_info.id,
  //     "msg": msg,
  //     "create_date": formattedUtcTime
  //   };
  //   // console.log('send message ==>', sent_msg)
  //   this.ChatService.sendMsgSocket(sent_msg);
  //   this.clearInput();
  // }
  // processMsgs_0(id:any){
  //   //----- seen msg 
  //   let msg_status_obj = {
  //     conversation_id:id,
  //     admin_id:this.admin_info.id, // admin's user id
  //   }
  //   this.ChatService.updateMsgStatus(msg_status_obj);
  //   this.chats.find( (c:any) => ( c.id == id)).unseen_count = 0;
  //   //----- seen msg 
    
  //   // for showing dates in msgs
  //   for (let [index, single_msg] of this.chat_msgs.entries()) {
  //     if (this.chat_msgs[index - 1] != undefined) {
  //       let [msg_date, msg_time] = single_msg.create_date.split(" ");
  //       let [pre_msg_date, pre_msg_time] = this.chat_msgs[index - 1].create_date.split(" ");
  //       single_msg.show_date = (msg_date != pre_msg_date) ? moment(msg_date).local().format('D MMMM YYYY') : null;
  //     }else{
  //       let [msg_date, msg_time] = single_msg.create_date.split(" ");
  //       single_msg.show_date = moment(msg_date).local().format('D MMMM YYYY');
  //     }
  //     if(single_msg.msg_type == 3 ){
  //       single_msg.pdf_name = single_msg.msg.substring(this.pdf_link_prefix.length);
  //     }
  //   }
  // }

}
