import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/motus/_services/api.service';
import { ConstUrl } from 'src/app/_constant';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.css']
})
export class SetNewPasswordComponent {
  logo_url = environment.S3_URL + 'motus9/logo.png';
  user_id:string;

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private ApiService: ApiService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.user_id = params['_id'];
    });
  }

  onSubmit(password_form: any) {
    let form_data = password_form.value;
    if (!form_data.password || form_data.password == '') {
      this.toastr.warning("Password field is required");
      return;
    }
    if(!form_data.confirm_password || form_data.confirm_password == '') {
      this.toastr.warning("Confrim Password field is required");
      return;
    }
    if(form_data.confirm_password != form_data.password ) {
      this.toastr.warning("Passwords not matched");
      return;
    }
    if(!this.user_id) {
      this.toastr.warning("Invalid link");
      return;
    }
    this.spinner.show();
    form_data.user_id = this.user_id;

    this.ApiService.setNewUserPassword(form_data).subscribe((response: any) => {
      if (response.data.success) {
        this.toastr.success(response.data.message);
        this.user_id = null;
        password_form.reset();
      } else {
        this.toastr.warning(response.data.message);
      }
      this.spinner.hide();

    }, (error) => {
      // localStorage.clear();
      // var error_msg:string;
      // if(error.response.data.data){
      //   error_msg = Object.values(error.response.data.data)[0] as string; 
      // }else{
      //   error_msg = error.response.data.message;
      // }
      this.toastr.error("Internal server error please try after sometime");
      this.spinner.hide();
    });
  }

}
