<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" [fullScreen]="true" type="ball-spin-clockwise">
    <p style="color: white">Loading... </p>
</ngx-spinner>
<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-body  ">
            <section class="row">
                <div class="d-flex align-items-center justify-content-center">
                    <div class="col-lg-6 col-lg-8 col-md-9 box-shadow-2 p-0 mobile_bottom">
                        <div class="card border-grey border-lighten-3 m-0 p-5 padding_remove ">
                            <div class="card-header border-0 container container-plpr">
                                <div class="card-title text-center ">
                                    <div style="font-size: 48px;">
                                        <img [src]="logo_url" style="width: 100px; height: 100px;" alt="branding logo">
                                    </div>
                                </div>
                                <h2 class="card-subtitle line-on-side text-color text-muted text-center  pt-2">
                                    <span
                                        class="font-weight-bold text-color" style="font-size: 17px;" >Baseline Intake Form
                                    </span>
                                </h2>
                            </div>
                            <div class="card-content container container-plpr">

                            <form *ngIf=" this.user_type == 'member'" class="form-horizontal form-simple" #question_form="ngForm" (ngSubmit)="onSubmit(question_form)">
                                <div *ngFor="let row of baselineintake; let i = index" class="form-group position-relative mb-3">
                                    <label class="text-style">{{ i + 1 }}. {{ row.question }}</label>
                                        <div *ngFor="let j of getRange(row.input_type)" class="mb-2">
                                            <textarea class="form-control" id="{{row.question}}" ngModel name="{{i + 1}}.{{j + 1}}.{{row.question}}" placeholder=""></textarea>
                                            <div *ngIf="row.scale == '0'" class="container-plpr">
                                                <div class="slider-labels text-style">
                                                    <span *ngFor="let stepValue of stepValues" [style.left.%]="getLeftPosition(stepValue)">
                                                        {{ stepValue }}
                                                    </span>
                                                </div>
                                                <input type="range" class="form-control-range example-margin custom-slider" 
                                                [max]="max" [min]="min" [step]="step" name="{{i + 1}}.{{j + 1}}..{{row.question}}" id="{{i + 1}}" ngModel="1" >
                                                <!-- <input type="range" class="form-control-range example-margin custom-slider" 
                                                [max]="max" [min]="min" [step]="step"  ngModel  name="{{i + 1}}.{{j + 1}}..{{row.question}}" id="{{i}}" value="i === 0 ? valueOne : i === 1 ? valueTwo : 
                                                i === 2 ? valueThree : i === 3 ? valueFour : i === 4 ? valueFive :i === 5 ? valueSix : i === 6 ? valueSeven :
                                                i === 7 ? valueEight : i === 8 ? valueNine :  'value'" (input)="onSliderChangeOne($event)"> -->
                                            </div>
                                        </div>
                                      
                                </div>
                                <br>
                                <div class="mobile_bottom" style="text-align: center">
                                    <button type="submit" class="btn  btn-mtu btn-center btn-width">Submit</button>
                                </div>
                            </form>
                            <form *ngIf=" this.user_type == 'owner'" class="form-horizontal form-simple" #question_form="ngForm" (ngSubmit)="onSubmit(question_form)">
                                <div *ngFor="let row of baselineintake; let i = index" class="form-group position-relative mb-3">
                                    <div *ngIf=" row.question_for == 'member'">
                                        <label  class="text-style">{{ i + 1 }}. {{ row.question }}</label>
                                        <div *ngFor="let j of getRange(row.input_type)" class="mb-2">
                                            <textarea class="form-control" id="{{row.question}}" ngModel name="{{i + 1}}.{{j + 1}}.{{row.question}}" placeholder=""></textarea>
                                            <div *ngIf="row.scale == '0'" class="container-plpr">
                                                <div class="slider-labels text-style">
                                                    <span *ngFor="let stepValue of stepValues" [style.left.%]="getLeftPosition(stepValue)">
                                                        {{ stepValue }}
                                                    </span>
                                                </div>
                                                <input type="range" class="form-control-range example-margin custom-slider" 
                                                [max]="max" [min]="min" [step]="step" name="{{i + 1}}.{{j + 1}}..{{row.question}}" id="{{i + 1}}" ngModel="1" >
                                                <!-- <input type="range" class="form-control-range example-margin custom-slider" 
                                                [max]="max" [min]="min" [step]="step"  ngModel  name="{{i + 1}}.{{j + 1}}..{{row.question}}" id="{{i}}" value="i === 0 ? valueOne : i === 1 ? valueTwo : 
                                                i === 2 ? valueThree : i === 3 ? valueFour : i === 4 ? valueFive :i === 5 ? valueSix : i === 6 ? valueSeven :
                                                i === 7 ? valueEight : i === 8 ? valueNine :  'value'" (input)="onSliderChangeOne($event)"> -->
                                            </div>
                                        </div>
                                    </div>

                                    <div  *ngIf=" row.question_for == 'owner'">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <label class="mt-1 text-style">{{ i + 1 }}. {{ row.question }}:</label>
                                            </div>
                                    
                                            <div class="col-md-8"> 
                                                <textarea type="text" class="form-control"  id="{{row.question}}" ngModel name="{{row.question}}" placeholder=""  ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="mobile_bottom" style="text-align: center">
                                    <button type="submit" class="btn  btn-mtu btn-center btn-width">Submit</button>
                                </div>
                            </form>


                            <!-- <form *ngIf=" this.user_type == 'member'" class="form-horizontal form-simple" #question_form="ngForm"
                                (ngSubmit)="onSubmit(question_form)">
                                  <div   class="form-group position-relative mb-0">
                                    <label class="text-style">1. How fulfilled are you in your daily routine?</label>
                                    <textarea type="text" class="form-control" id="1" ngModel name="1" placeholder=""  ></textarea>
                                </div>
                                <div   class="form-group position-relative mb-0">
                                    <label class="mt-1 text-style">2. How would you rate accountability in your organization?</label>
                                    <textarea type="text" class="form-control" id="2" ngModel name="2" placeholder=""  ></textarea>
                                </div>
                                  <div   class="form-group position-relative mb-0">
                                    <label class="mt-1 text-style" >3. How well are values integrated into your organization?</label>
                                    <textarea type="text" class="form-control" id="3" ngModel name="3" placeholder=""  ></textarea>
                                </div>
                                <div    class="form-group position-relative mb-0">
                                    <label class="mt-1 text-style">4. How would you rate your overall employee engagment?</label>
                                    <textarea type="text" class="form-control" id="4" ngModel name="4" placeholder=""  ></textarea>
                                </div>
                                  <div    class="form-group position-relative mb-0">
                                    <label class="mt-1 text-style">5. How would you rate your employee retention?</label>
                                    <textarea type="text" class="form-control" id="5" ngModel name="5" placeholder=""  ></textarea>
                                </div>
                                <div    class="form-group position-relative mb-0">
                                    <label class="mt-1 text-style">6. How would you rate alignment of senior leadership?</label>
                                    <textarea type="text" class="form-control" id="6" ngModel name="6" placeholder=""  ></textarea>
                                </div>
                                <div   class="form-group position-relative mb-0">
                                    <label class="mt-1 text-style">7. How well does your leadership team address tough issues?</label>
                                    <textarea type="text" class="form-control" id="7" ngModel name="7" placeholder=""  ></textarea>
                                </div>
                                  <div  class="form-group position-relative mb-0">
                                    <label class="mt-1 text-style">8. How well defined are your goals for the next 1, 5 & 10 years?</label>
                                    <textarea type="text" class="form-control" id="8" ngModel name="8" placeholder=""  ></textarea>
                                </div>
                                <div    class="form-group position-relative mb-0">
                                    <label class="mt-1 text-style">9. How expeditious are decisions made within your department?</label>
                                    <textarea type="text" class="form-control" id="9" ngModel name="9" placeholder=""  ></textarea>
                                </div>
                                <div  class="form-group position-relative mb-0">
                                    <label class="mt-1 text-style">10. Top 3 Company Objectives for the coming year:</label>
                                    <textarea type="text" class="form-control" id="10" ngModel name="10" placeholder="1." >1.</textarea>
                                    <textarea type="text" class="form-control" id="10.1" ngModel name="10.1" placeholder="2."  >2.</textarea>
                                    <textarea type="text" class="form-control" id="10.2" ngModel name="10.2" placeholder="3."  >3.</textarea>
                                </div>
                                <div    class="form-group position-relative mb-0">
                                    <label class="mt-1 text-style">11. Top 3 Personal Objectives for the coming year:</label>
                                    <textarea type="text" class="form-control" id="11" ngModel name="11" placeholder=""  ></textarea>
                                    <textarea type="text" class="form-control" id="11.1" ngModel name="11.1" placeholder=""  ></textarea>
                                    <textarea type="text" class="form-control" id="11.2" ngModel name="11.2" placeholder=""  ></textarea>
                                </div>
                                <div    class="form-group position-relative mb-0 mt-1">
                                    <label class="mt-1 text-style">12. What is keeping you up at night?</label>
                                    <textarea type="text" class="form-control" id="12" ngModel name="12" placeholder=""  ></textarea>
                                    <textarea type="text" class="form-control" id="12.1" ngModel name="12.1" placeholder=""  ></textarea>
                                    <textarea type="text" class="form-control" id="12.2" ngModel name="12.2" placeholder=""  ></textarea>
                                </div>
                                <br>
                                <div class="mobile_bottom" style="text-align: center">
                                    <button type="submit" class="btn  btn-mtu btn-center btn-width">Submit</button>
                                </div>
                            </form> -->

                            <!-- <form *ngIf=" this.user_type == 'owner'" class="form-horizontal form-simple" #question_form="ngForm"
                            (ngSubmit)="onSubmit(question_form)">
                             
                            <div   class="form-group position-relative mb-0  mt-1">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label class="mt-1 text-style">1. Number of years in Business:</label>
                                    </div>
                                    <div class="col-md-8"> 
                                        <textarea type="text" class="form-control" id="13" ngModel name="13" placeholder=""  ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div    class="form-group position-relative mb-0  mt-1">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label class="mt-1 text-style">2. Ownership Structure:</label>
                                    </div>
                                    <div class="col-md-8"> 
                                        <textarea type="text" class="form-control" id="14" ngModel name="14" placeholder=""  ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div    class="form-group position-relative mb-0 mt-1">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label class="mt-1 text-style">3. Current number of Employees</label>
                                    </div>
                                    <div class="col-md-8"> 
                                        <textarea type="text" class="form-control" id="15" ngModel name="15" placeholder=""  ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div    class="form-group position-relative mb-0 mt-1">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label class="mt-1 text-style">4. Leadership Structure: (roles/people)</label>
                                    </div>
                                    <div class="col-md-8"> 
                                        <textarea type="text" class="form-control" id="16" ngModel name="16" placeholder=""  ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div    class="form-group position-relative mb-0 mt-1">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label class="mt-1 text-style">5. Current Revenue:</label>
                                    </div>
                                    <div class="col-md-8"> 
                                        <textarea type="text" class="form-control" id="17" ngModel name="17" placeholder=""  ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div   class="form-group position-relative mb-0 mt-1">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label class="mt-1 text-style">6. Forecasted Revenue for new year:</label>
                                    </div>
                                    <div class="col-md-8"> 
                                        <textarea type="text" class="form-control" id="18" ngModel name="18" placeholder=""  ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div    class="form-group position-relative mb-0 text-center">
                                <label class="mt-1 text-style ">Additional Notes/Comments you'd like to share with us</label>
                                <textarea type="text" class="form-control" id="19" ngModel name="19" placeholder=""  ></textarea>
                            </div>
                            <br>
                            <div class="mobile_bottom" style="text-align: center">
                                <button type="submit" class="btn  btn-mtu btn-center btn-width">Submit</button>
                            </div>
                        </form> -->
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>



