<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" [fullScreen]="true" type="ball-spin-clockwise">
    <p style="color: white">Loading... </p>
</ngx-spinner>
<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-body  ">
            <section class="row">
                <div class="d-flex align-items-center justify-content-center">
                    <div class="col-lg-6 col-lg-8 col-md-9 box-shadow-2 p-0 mobile_bottom">
                        <div class="card border-grey border-lighten-3 m-0 p-5 padding_remove ">
                            <div class="card-header border-0 container container-plpr">
                                <div class="card-title text-center ">
                                    <div style="font-size: 48px;">
                                        <img [src]="logo_url" style="width: 100px; height: 100px;" alt="branding logo">
                                    </div>
                                </div>
                                <h2 class="card-subtitle line-on-side text-color text-muted text-center  pt-2">
                                    <span
                                        class="font-weight-bold text-color" style="font-size: 17px;" >Assessment Form
                                    </span>
                                </h2>
                            </div>
                            <div class="card-content container container-plpr">
                                <form class="form-horizontal form-simple" #question_form="ngForm" (ngSubmit)="onSubmit(question_form)">
                                    <div *ngFor="let row of onbording; let i = index" class="form-group position-relative mb-3">
                                        <label  class="text-style">{{ i + 1 }}. {{ row.question }}</label>
                                        <div *ngIf=" row.scale == '1'">
                                            <div  class="container-plpr">
                                                <div class="slider-labels text-style">
                                                    <span *ngFor="let stepValue of stepValues" [style.left.%]="getLeftPosition(stepValue)">
                                                        {{ stepValue }}
                                                    </span>
                                                </div>
                                                <input type="range" class="form-control-range example-margin custom-slider" 
                                                [max]="max" [min]="min" [step]="step" name="{{i + 1}}.{{row.question}}" id="{{i + 1}}" ngModel="1" >
                                            </div>
                                        </div>
                                        <div *ngIf=" row.scale == '0'">
                                                <input type="text" class="form-control" id="{{i + 1}}" ngModel name="{{i + 1}}.{{row.question}}" required>
                                                <div *ngIf="question_form.submitted" class="text-danger">
                                                   {{ this.required }}
                                                </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div style="text-align: center">
                                        <button type="submit" class="btn btn-mtu btn-center btn-width btn-bottom">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>