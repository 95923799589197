<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" [fullScreen]="true" type="ball-spin-clockwise">
    <p style="color: white">Loading... </p>
</ngx-spinner>
<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-body">
            <section class="row flexbox-container">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
                        <div class="card border-grey border-lighten-3 m-0">
                            <div class="card-header border-0">
                                <div class="card-title text-center">
                                    <div class="p-1">
                                        <img [src]="logo_url" style="width: 100px; height:100px" alt="branding logo">
                                    </div>
                                </div>
                                <h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                                    <span
                                        class="font-weight-bold">Motus9
                                    </span>
                                </h6>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                    <form class="form-horizontal form-simple" #password_form="ngForm"
                                        (ngSubmit)="onSubmit(password_form)">
                                        <div class="form-group position-relative has-icon-left mb-0">
                                            <input type="password" class="form-control" id="password" ngModel
                                                name="password" placeholder="Password" required
                                                 maxlength="16">
                                            <div class="form-control-position">
                                                <i class="la la-key"></i>
                                            </div>
                                        </div>
                                        <br>
                                        <span *ngIf="false" class="text-danger" style="font-size:16px ;">
                                            "msg"
                                        </span><br>
                                        <div class="form-group position-relative has-icon-left mb-0">
                                            <input type="password" class="form-control" id="confirm_password" name="confirm_password" ngModel
                                                placeholder="Confirm Password" maxlength="16" required>
                                            <div class="form-control-position">
                                                <i class="la la-key"></i>
                                            </div>
                                        </div>
                                      
                                        <div class="form-group row">
                                            <div class="col-sm-6 col-12 text-center text-sm-left">
                                                <div class="custom-control custom-checkbox mb-1">

                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" class="btn btn-mtu btn-block"><i
                                                class="feather ft-unlock"></i>&nbsp;&nbsp;&nbsp;Save</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>