import { Component } from '@angular/core';
import { ApiService } from '../../_services/api.service';
import {  FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-profile',            
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent {
  newPasswordFrom:FormGroup
  submitted:boolean;
  admin_info = JSON.parse(localStorage.getItem("admin_info"))
  image:string;
  mobile:string;
  email:string;

  constructor(
    private apiService:ApiService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
  ){
    this.mobile = this.admin_info.mobile
    this.image = this.admin_info.image
    this.email = this.admin_info.email
  }

  ngOnInit(){
    this.newPasswordFrom = this.formBuilder.group({
      old_password: ['', Validators.required],
      password: ['', Validators.required],
      password_confirmation: ['', Validators.required],
    });
  }
  get f() {
    return this.newPasswordFrom.controls;
  }
  setNewPassword(){
    this.submitted = true;
    if (this.newPasswordFrom.invalid) {
      return;
    }
    // if(this.newPasswordFrom.value.password.length < 8){
    //   this.toastr.error("Password must be greater than 8 characters long ");
    //   return;
    // }
    if(this.newPasswordFrom.value.password !== this.newPasswordFrom.value.password_confirmation){
      this.toastr.error("Password and confirm password not matched");
      return;
    }
    let submitData = new FormData()
    // submitData.append('token',this.admin_info.access_token)
    submitData.append('password',this.newPasswordFrom.value.password)
    submitData.append('old_password',this.newPasswordFrom.value.old_password)
    submitData.append('email',this.admin_info.email)
    
    this.apiService.setNewPassword(submitData).subscribe((data:any) => {
      let response = data.data
      if(response.success){
        this.newPasswordFrom.reset();
        this.submitted = false;
        this.toastr.success("Password changed successfully")
      }else{
        this.toastr.error(response.message)
      }
    },(error) => {
      var error_msg: string;
      if (error.response.data.data) {
        error_msg = Object.values(error.response.data.data)[0] as string;
      } else {
        error_msg = error.response.data.message;
      }
      this.toastr.error(error_msg);
    });
  }
}
