import { Component } from '@angular/core';
import { ApiService } from '../../_services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ConstUrl } from 'src/app/_constant';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-coachs-list',
  templateUrl: './coachs-list.component.html',
  styleUrls: ['./coachs-list.component.css']
})
export class CoachsListComponent {
  coachs:any;
  coach:any;
  init_coachs:any;
  submitted = false;
  userInfo: FormGroup;
  file:any;
  imageFile:any;
  today_date = moment().format(ConstUrl.date_format)
  imageURL: string;
  coaches_excel_sample:string;
  dic_path = 'motus9/sample_excels/';
  ALPHA_REGEX = /.*[a-zA-Z].*/ ;   // string atleast contain on alphabet

  constructor(
    private ApiService:ApiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
  ){
    this.coaches_excel_sample = environment.S3_URL + this.dic_path + 'coaches.xlsx';
  }
  public configScroll: PerfectScrollbarConfigInterface = { suppressScrollY : false, wheelPropagation: false };
  // changed for horizontal scroll problem old : "suppressScrollY : false
  public config: PerfectScrollbarConfigInterface = { suppressScrollX : true };
  ngOnInit(){
    this.userInfo = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required,Validators.minLength(10)]],
      as_admin: [false],
      // company_name: ['', Validators.required],
      // title: ['', Validators.required],
      // division: ['', Validators.required],
      // reportees: ['', Validators.required],
      // dob: ['', Validators.required],
      // work_anniversary: ['', Validators.required],
      // password: ['', Validators.required],
      // password_confirmation: ['', Validators.required],
      // image: [],
    });
    this.getCoachs();
    this.getClients(); // #temp
  }
  getClients() {
    this.ApiService.getClients().subscribe((response: any) => {
      //----
    });
  }
  get f() {
    return this.userInfo.controls;
  }
  formStatusChange(status:boolean = false){
    this.submitted = status;
  }
  emptyFile(){
    this.file = '';
    this.imageURL = null;
    this.imageFile = null;
  }
  getCoachs(){   
    this.coachs = [];
    this.spinner.show();
    this.ApiService.getCoachs().subscribe((response:any) => {
      if(response.data.success){
        this.coachs = response.data.data;
        this.init_coachs = this.coachs;
      }else{
        this.toastr.error(response.data.message);
      }
      this.spinner.hide();
    });
  }
  deleteUser(id: any) {
    let is_confirmed = confirm("Are you sure to delete this record?");
    if (is_confirmed) {
      this.spinner.show();
      this.ApiService.deleteUser(id).subscribe(response => {
        if (response.data.success) {
          this.toastr.success(response.data.message);
          this.coachs = this.coachs.filter((c: any) => { return c.user_id != id })
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.toastr.error(response.data.message);
        }
      })
    }
  }
  openCreateForm(LoginFormContent) {
    this.modalService.open(LoginFormContent, { windowClass: 'animated FadeIn', backdrop: 'static',size:'lg'});
  }
  updatedefaultFilter(event) {
    const val = event.target.value.toLowerCase();
    // this.temp = [...this.coachs];
    // filter our data
    const temp = this.init_coachs.filter(function (d:any) {
      return (d.first_name ? (d.first_name.toLowerCase().indexOf(val) !== -1) : false) || 
      (d.last_name ? (d.last_name.toLowerCase().indexOf(val) !== -1) : false) || 
      (d.email ? (d.email.toLowerCase().indexOf(val) !== -1) : false) || 
      (d.mobile ? (d.mobile.toLowerCase().indexOf(val) !== -1) : false) || 
      (!val) || (val === "") || (val === " ");
    });
    // update the rows
    this.coachs = temp;

    // Whenever the filter changes, always go back to the first page
    // this.table.offset = 0;

  }
  onFileSelected(event:any, file_type = 'image'){
    if(file_type == 'image'){
      this.imageFile = (event.target as HTMLInputElement).files[0];
      if(!(this.imageFile.size <= ConstUrl.max_image_size)){
        this.toastr.error('Maximum allowed size is 5MB');
        this.imageFile = null;
        (event.target as HTMLInputElement).value = '';
        return 
      }
      const reader = new FileReader();
      reader.onload = () => {
        this.imageURL = reader.result as string;
      }
      reader.readAsDataURL(this.imageFile)
    }else{
      this.file = (event.target as HTMLInputElement).files[0];
      if(!(this.file.size <= ConstUrl.max_image_size)){
        this.toastr.error('Maximum allowed size is 5MB');
        this.file = null;
        (event.target as HTMLInputElement).value = '';
        return 
      }
    }
  }
  onProjectInfoSubmit() {
    this.submitted = true;
    if (this.userInfo.invalid) {
      return;
    }
    
    if(!this.ALPHA_REGEX.test(this.userInfo.value.first_name)){
      this.toastr.error("The first name field is not valid");
      return;
    }
    if(!this.ALPHA_REGEX.test(this.userInfo.value.last_name)){
      this.toastr.error("The last name field is not valid");
      return;
    }
    this.spinner.show();
    let formData = new FormData();
    Object.keys(this.userInfo.value).forEach((itm) => {
      formData.append(itm,this.userInfo.value[itm]);
    })
    formData.append('user_type','coach');
    if(this.imageFile){
      formData.append('image',this.imageFile)
    }
    
    this.ApiService.createUser(formData).subscribe((data: any) => {
      const response = data.data;  
      if(response.success) {
        this.toastr.success(data.data.message);
        this.userInfo.reset()
        this.imageURL = null;
        this.imageFile = ''
        this.submitted = false;
        this.modalService.dismissAll();
        this.ngOnInit();
      }
      else{
        this.toastr.error(data.message);
      }
      this.spinner.hide();
    },(error) =>{
      var error_msg:string;
      if(error.response.data.data){
        error_msg = Object.values(error.response.data.data)[0] as string; 
      }else{
        error_msg = error.response.data.message;
      }
      this.spinner.hide();
      this.toastr.error(error_msg);
    });
  }
  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  editCoach(id: any, modalName: any) {
    let coach = this.coachs.find((c: any) => { return c.id == id });
    this.coach = { ...coach };
    this.coach.as_admin = JSON.parse(this.coach.as_admin);
    this.imageURL = this.coach.image;
    this.openCreateForm(modalName);
  }
  returnCorrectDateFormat(dt:any){
    return new DatePipe('en-US').transform(dt, 'yyyy-MM-dd');
  }
  updateCoach(data:any,id:any){
    if(!this.ALPHA_REGEX.test(data.first_name)){
      this.toastr.error("The first name field is not valid");
      return;
    }
    if(!this.ALPHA_REGEX.test(data.last_name)){
      this.toastr.error("The last name field is not valid");
      return;
    }
1
    this.spinner.show();
    let formData = new FormData();
    formData.append('user_type', 'coach');

    formData.append('email', data.email);
    formData.append('first_name', data.first_name);
    formData.append('last_name', data.last_name);
    formData.append('mobile', data.mobile);
    formData.append('status', data.status);
    formData.append('as_admin', data.as_admin);
    // formData.append('company_name',data.company_name);
    // formData.append('title',data.title);
    // formData.append('division',data.division);
    // formData.append('reportees',data.reportees);
    // formData.append('dob',this.returnCorrectDateFormat(data.dob));
    // formData.append('work_anniversary',this.returnCorrectDateFormat(data.work_anniversary));

    // Object.keys(data).forEach((itm) => {
    //   if(data[itm] === 'null'){
    //     formData.append(itm,null);
    //   }else{
    //     formData.append(itm,data[itm]);
    //   }
    // })
    if(this.imageFile){
      formData.append('image',this.imageFile)
    }else{
      delete data.image;
    }

    this.ApiService.updateUser(formData,id).subscribe((data: any) => {
      const response = data.data;  
      if(response.success) {
        this.toastr.success(response.message);
        this.coach = {};
        this.imageURL = null;
        this.imageFile = '';
        this.modalService.dismissAll();
        this.ngOnInit();
      }
      else{
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    },(error) =>{
      var error_msg:string;

      if(error.response.data.data){
        error_msg = Object.values(error.response.data.data)[0] as string; 
      }else{
        error_msg = error.response.data.message;
      }
      this.toastr.error(error_msg);
      this.spinner.hide();
    });
  }
  updateUserStatus(id:any) {
    this.spinner.show();
    this.ApiService.updateUserStatus(id).subscribe((response: any) => {
      if (response.data.success) {
        this.spinner.hide();
        this.toastr.success(response.data.message);
      } else {
        this.spinner.hide();
        this.toastr.error(response.data.message);
      }
    });
  }
  importCoaches(){
    this.spinner.show();

    let api_data = new FormData()
    api_data.append('csv_file', this.file);
    api_data.append('user_type', 'coach');

    this.ApiService.importUsers(api_data).subscribe( async (data:any) => {
      let response =  data.data;
      if (response.success) {
        this.file = null;
        this.getCoachs();
        this.toastr.success(data.data.message);
      }
      else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (error) => {
      this.toastr.error("Invalid csv file");
      this.spinner.hide();
    })
    
  }
}
