import { Injectable } from '@angular/core';
import Axios from 'axios-observable';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from '../_models/user.model';


// if(localStorage.getItem('admin_info') != null  && localStorage.getItem('admin_info') != ''){
// const token = JSON.parse(localStorage.getItem('admin_info')).access_token;
// console.log('header set ', token)
// Axios.defaults.headers.common['Authorization'] = "Bearer " + token;
// }


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  base_url: string;

  private userSubject = new BehaviorSubject<User | null>(null);
  user$ = this.userSubject.asObservable();

  setUser(user: User) {
    this.userSubject.next(user);
  }

  clearUser() {
    this.userSubject.next(null);
  }

  isUserLoggedIn:Subject<boolean> = new Subject<boolean>();
  constructor(
    private router :Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,

  ) {
    this.base_url = environment.admin_server;
    if (localStorage.getItem('admin_info') != null && localStorage.getItem('admin_info') != '') {
      const token = JSON.parse(localStorage.getItem('admin_info')).access_token;
      Axios.defaults.headers.common['Authorization'] = "Bearer " + token;
    }
  }

  checkUnauthorized(error: any) {
    var error_obj:any = error.response.data;
    if(error.response.status === 401 && error_obj.success == false && error_obj.message == 'Unauthorized' && error_obj.data == null){
      // if a user login to a other device or inactive
      localStorage.clear();
      this.router.navigate(['/']);
      this.toastr.error(error_obj.message);
      this.spinner.hide();
    }
  }

  //------ api  
  createUser(form_submit_data: any) {
    return Axios.post(this.base_url + 'admin/user', form_submit_data)
      .pipe(map(response => {
        return response;
      }),
        catchError(error => {
          this.checkUnauthorized(error);
          return throwError(error);
        }));
  }

  createBaselineIntakeFormAns(id:any,data: any) {
    return Axios.post(this.base_url + `baselineintake_form/${id}`, data)
    .pipe(map(response => {
      return response;
    }),
      catchError(error => {
        // this.checkUnauthorized(error);
        return throwError(error);
      }));
  }

  createOnboardingFormAns(id:any,data: any) {
    return Axios.post(this.base_url + `onboarding_form/${id}`, data)
    .pipe(map(response => {
      return response;
    }),
      catchError(error => {
        // this.checkUnauthorized(error);
        return throwError(error);
      }));
  }

  makeACoach(form_submit_data: any) {
    return Axios.post(this.base_url + 'admin/make_a_coach', form_submit_data)
      .pipe(map(response => {
        return response;
      }),
        catchError(error => {
          this.checkUnauthorized(error);
          return throwError(error);
        }));
  }

  getUserAns(data:any){
    return Axios.get(this.base_url + `onbording/${data}`)
    .pipe(map(response => {
      return response;
    }),
      catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }
  // adminLogin(form_submit_data: any) {
  //   return Axios.post(this.base_url + 'admin/login', form_submit_data, { headers: { Authorization: null } })
  //     .pipe(map(response => {
  //       if (response.data.success == true) {
  //         if (response.data.data != null) {
  //           Axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.data.access_token;
  //         }
  //       }
  //       return response;
  //     }),
  //       catchError(error => {
  //         return throwError(error);
  //       }));
  // }
  adminSendOtp(form_submit_data: any) {
    return Axios.post(this.base_url + 'admin/send_otp', form_submit_data, { headers: { Authorization: null } })
      .pipe(map(response => {
        return response;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }
  adminVerifyOtp(form_submit_data: any) {
    return Axios.post(this.base_url + 'admin/verify_otp', form_submit_data, { headers: { Authorization: null } })
      .pipe(map(response => {
        if (response.data.success == true) {
          if (response.data.data != null) {
            Axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.data.access_token;
          }
        }
        return response;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }
  sendBaselineFormLink(formData:any){
    return Axios.post(this.base_url + 'admin/sendEmailBaselineForm', formData)
    .pipe(map(response => {
      return response;
    }), catchError(error => {
      this.checkUnauthorized(error);
      return throwError(error);
    }));
  }
  adminLogout(token: any) {
    return Axios.post(this.base_url + 'admin/logout', {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).pipe(map(response => {
      return response;
    }),
      catchError(error => {
        return throwError(error);
      }));
  }

  getOrganization(){
    return Axios.get(this.base_url + 'organization')
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }
  getOnbordingQuestion(){
    return Axios.get(this.base_url + 'get_onbording_question')
    .pipe(map(response => {
      return response;
    }), catchError(error => {
      this.checkUnauthorized(error);
      return throwError(error);
    }));
  }

  getQuestion(user_type :any){
    return Axios.get(this.base_url + `question/${user_type}`)
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }
  checkId(id:any){
    return Axios.post(this.base_url + `check_id/${id}`)
    .pipe(map(response => {
      return response;
    }), catchError(error => {
      this.checkUnauthorized(error);
      return throwError(error);
    }));
  }

  // getOnbordingAns(id:any){
  //   return Axios.get(this.base_url + `onbording/${id}`)
  //     .pipe(map(response => {
  //       return response;
  //     }), catchError(error => {
  //       this.checkUnauthorized(error);
  //       return throwError(error);
  //     }));
  // }

  getOrganizationReport(formData: any){
    return Axios.post(this.base_url + `organization_report`,formData)
    .pipe(map(response => {
      return response;
    }), catchError(error => {
      this.checkUnauthorized(error);
      return throwError(error);
    }));
  }

  getClients(filter = {}) {
    return Axios.get(this.base_url + 'admin/clients', { params: filter })
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }
  getInvalidClients(filter = {}) {
    return Axios.get(this.base_url + 'admin/invalid_clients')
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }
  deleteUser(id: any) {
    return Axios.delete(this.base_url + `admin/user/${id}`)
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }
  getCoachs() {
    let filter = { type: 'all' };
    return Axios.post(this.base_url + 'get_coach', {}, { params: filter })
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }
  updateUser(data: any, id: any) {
    return Axios.put(this.base_url + `admin/user/${id}`, data)
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }
  updateUserStatus(id: any) {
    return Axios.put(this.base_url + `admin/user/${id}/status`)
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }
  getOrganizations(type: string = null) {
    return Axios.get(this.base_url + 'organization/?type=' + type)
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }
  createOrganization(form_submit_data: any) {
    return Axios.post(this.base_url + 'admin/organization', form_submit_data)
      .pipe(map(response => {
        return response;
      }),
        catchError(error => {
          this.checkUnauthorized(error);
          return throwError(error);
        }));
  }
  deleteOrganization(id: any) {
    return Axios.delete(this.base_url + `admin/organization/${id}`)
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }
  updateOrganization(data: any, id: any) {
    return Axios.put(this.base_url + `admin/organization/${id}`, data)
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }
  updateOrganizationStatus(id: any) {
    return Axios.put(this.base_url + `admin/organization/${id}/status`)
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }
  importUsers(form_submit_data: any) {
    return Axios.post(this.base_url + 'admin/csv/user', form_submit_data)
      .pipe(map(response => {
        return response;
      }),
        catchError(error => {
          this.checkUnauthorized(error);
          return throwError(error);
        }));
  }
  setNewPassword(form_submit_data: any) {
    return Axios.post(this.base_url + 'set_new_password', form_submit_data)
      .pipe(map(response => {
        return response;
      }),
        catchError(error => {
          this.checkUnauthorized(error);
          return throwError(error);
        }));
  }
  getAdmins() {
    return Axios.get(this.base_url + 'admin/admins')
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }
  getOrgFfds(id: string) {
    return Axios.get(this.base_url + `admin/organization/${id}/ffd`)
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }
  getClientFfds(id: string) {
    return Axios.get(this.base_url + `admin/client/${id}/ffd`)
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }
  createBanner(form_submit_data: any) {
    return Axios.post(this.base_url + 'admin/banners', form_submit_data)
      .pipe(map(response => {
        return response;
      }),
        catchError(error => {
          this.checkUnauthorized(error);
          return throwError(error);
        }));
  }
  getBanner() {
    return Axios.get(this.base_url + 'admin/banners')
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }
  updateBanner(data: any, id: any) {
    return Axios.post(this.base_url + `admin/banners/${id}`, data)
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }
  deleteBanner(id: any) {
    return Axios.delete(this.base_url + `admin/banners/${id}`)
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }
  createAdmin(form_submit_data: any) {
    return Axios.post(this.base_url + 'admin/admins', form_submit_data)
      .pipe(map(response => {
        return response;
      }),
        catchError(error => {
          this.checkUnauthorized(error);
          return throwError(error);
        }));
  }
  updateAdmin(data: any, id: any) {
    return Axios.put(this.base_url + `admin/admins/${id}`, data)
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }
  deleteAdmin(id: any) {
    return Axios.delete(this.base_url + `admin/admins/${id}`)
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }
  getChat() {
     // here we are sending an extra key which can only provided by web so we can separate coach and coach as admin
    let obj = {for_group:'true'};
    return Axios.post(this.base_url + 'get_chat',obj)
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }
  fetchMsgs(id: any) {
    return Axios.post(this.base_url + 'fetch_msgs', id)
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }
  getClientSession(params: any = {}) {
    return Axios.get(this.base_url + `admin/get_session_by_admin_by_orgid`,{params:params})
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }

  get_session_by_admin_by_user_id(params: any = {}) {
    return Axios.get(this.base_url + `admin/get_session_by_admin_by_user_id`,{params:params})
      .pipe(map(response => {
        return response;
      }), catchError(error => {
        this.checkUnauthorized(error);
        return throwError(error);
      }));
  }

  setNewUserPassword(form_data: any) {
    return Axios.post(this.base_url + 'set_first_user_password', form_data)
      .pipe(map(response => {
        return response;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }


  deleteUserSinup(id: any){
    return Axios.post(this.base_url+ `admin/delete_user/${id}`)
    .pipe(map(response => {
      return response;
    }), catchError(error => {
      this.checkUnauthorized(error);
      return throwError(error);
    }));
  }
 

}