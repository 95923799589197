import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, NgForm } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import {ToastrService} from 'ngx-toastr';
import { ApiService } from 'src/app/motus/_services/api.service';
import { environment } from 'src/environments/environment';
import * as Papa from 'papaparse';
import { json2csv } from 'json-2-csv';
// import * as converter from 'json-2-csv';
import { saveAs } from 'file-saver'; 
import { DatePipe } from '@angular/common';
let converter = require('json-2-csv');
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
  providers: [DatePipe]
})
export class ReportComponent {
  selectedOption: string;
  orgination: any = ['General','Past session log','Client Status Log','Shared Resources'];
  org_id: string;
  csv_data: any;
  org_report: string;

  constructor( 
    private datePipe: DatePipe,
    private router: Router,
    private toastr:ToastrService,
    private spinner: NgxSpinnerService,
    private ApiService: ApiService,
    ){}
  ngOnInit() {
    // this.get_orgination();
  }
  //  get_orgination(){
  //   this.spinner.show();
  //   this.ApiService.getOrganization().subscribe((data: any) => {
  //     const response = data.data;
  //     if (response.success == true) {
  //     //  this.orgination = response.data
  //       this.spinner.hide();
  //     } else {
  //       this.toastr.error(`${response.message}`);
  //     }
  //   });
  //  }
   
   onSubmit() {
    // Create a FormData object
    const formData = new FormData();
    formData.append('select_report', this.selectedOption);
    this.org_report = this.selectedOption
    this.spinner.show();
    this.ApiService.getOrganizationReport(formData).subscribe((data: any) => {
      const response = data.data;
      if (response.success == true) {
       this.csv_data = response.data;
          if(this.selectedOption == "Shared Resources"){
            this.downloadSharedResourcesCSV()
          }
          if(this.selectedOption == "Past session log"){
              this.downloadPastCSV()
          }
          if(this.selectedOption == "General"){
              this.downloadGeneralCSV()
          }
          if(this.selectedOption == "Client Status Log"){
            this.downloadClientStatusLogCSV()
          }
        this.spinner.hide();
      } else {
        this.toastr.error(`${response.message}`);
      }
    });
  }

  // Start  Shared Resources
  async downloadSharedResourcesCSV() {
    try {
      const csvData = await this.convertSharedResourcesToCSV(this.csv_data);
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const dwldLink = document.createElement("a");

      if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
          dwldLink.setAttribute("target", "_blank");
      }

      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", "Shared Resources.csv");
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    } catch (error) {
        console.error('Error generating CSV:', error);
    }
  }
  async convertSharedResourcesToCSV(data: any){
    let csv = 'Organization,Coach ,Client ,Shared resource,Session,Date\n';
    for (const orgData of data) {
      const orgName = orgData.Organization;
      const Coach_name = orgData.Coach;
      const Client_Name = orgData.Client;
      const Shared_resource = orgData.Shared_resource;
      const Session = orgData.Session;
      const Date = orgData.Date;
      
      csv += `"${orgName}","${Coach_name}","${Client_Name}","${Shared_resource}","${Session}","${Date}"\n`;
    }

    return csv;
  }

  // Start General and Shared Resources
  async downloadGeneralCSV() {
  try {
    const csvData = await this.convertGeneralToCSV(this.csv_data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const dwldLink = document.createElement("a");

    if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
        dwldLink.setAttribute("target", "_blank");
    }

    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", "General.csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  } catch (error) {
      console.error('Error generating CSV:', error);
  }
  }
  async convertGeneralToCSV(data: any){
    let csv = 'Organization,When org put on app ,Client ,Upcoming Sessions,Completed Sessions,Missed Sessions,Elephants,Blindspots,Action Items,Action Items Completed,Takeaways,Targets\n';
    for (const orgData of data) {
      const orgName = orgData.Organization;
      const When_org_put_on_app = orgData.When_org_put_on_app;
      const Clients = orgData.Clients;
      const Upcoming_session = orgData.Upcoming_session;
      const Completed_session = orgData.Completed_session;
      const Missed_session = orgData.Missed_session;
      const Elephant = orgData.Elephant;
      const Blindspots = orgData.Blindspots;
      const Action_items = orgData.Action_items;
      const Action_items_completed = orgData.Action_items_completed;
      const Takeaway = orgData.Takeaway;
      const Target = orgData.Target;
      
      csv += `"${orgName}","${When_org_put_on_app}","${Clients}","${Upcoming_session}","${Completed_session}","${Missed_session}","${Elephant}","${Blindspots}","${Action_items}","${Action_items_completed}","${Takeaway}","${Target}"\n`;
    }

    return csv;
  }

  //this is client status Log csv file onverter 
  async downloadClientStatusLogCSV() {
    try {
        const csvData = await this.convertClientStatusLogToCSV(this.csv_data);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const dwldLink = document.createElement("a");

        if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
            dwldLink.setAttribute("target", "_blank");
        }

        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", "Client Status Log.csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    } catch (error) {
        console.error('Error generating CSV:', error);
    }
  }
  async convertClientStatusLogToCSV(data: any) {
    let csv = 'Organization,Client Name,Action Items,Action Items Completed,Action Items Completed Date,Files Shared,Targets ,Targets Completed %,Total Session,Past Session,Missed Session,Avg Rating Given,Avg Rating Received\n';
    
      // Assuming csv is initialized somewhere before this code block
    for (const orgData of this.csv_data) {
      const orgName = orgData.organization;

      for (const clientData of orgData.clients) {
          const clientName = clientData.client_name;
          const actionItems = clientData.action.map(action => ({
              item: action.action_items,
              completed: action.action_items_completed,
              completedDate: action.action_items_completed_date
          }));
          
          const targets = clientData.targets.map(target => ({
              name: target.targets_name,
              completed: target.targets_completed
          }));
          
          const filesShared = clientData.files_shared.flatMap(status => status.files_shared.map(share => ({ share })));
          
          const avgRatingGiven = clientData.avg_rating_given;
          const avgRatingReceived = clientData.avg_rating_received;
          const totalSession = clientData.total_session;
          const pastSession = clientData.past_session;
          const missedSession = clientData.missed_session;

          const maxLength = Math.max(actionItems.length, targets.length, filesShared.length);

          for (let i = 0; i < maxLength; i++) {
              const actionItem = actionItems[i] || {};
              const target = targets[i] || {};
              const fileShare = filesShared[i] || {};
              // if(fileShare.share){
                 csv += `"${orgName}","${clientName}","${actionItem.item || ''}","${actionItem.completed || ''}","${actionItem.completedDate || ''}","${fileShare.share || ''}","${target.name || ''}","${target.completed || ''}","${totalSession}","${pastSession}","${missedSession}","${avgRatingGiven}","${avgRatingReceived}"\n`;
              // }
             
          }
      }
    }

    return csv;
  }

  //this is Past Session Log csv file onverter 
  async downloadPastCSV() {
    try {
        const csvData = await this.convertToPastCSV(this.csv_data);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const dwldLink = document.createElement("a");

        if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
            dwldLink.setAttribute("target", "_blank");
        }

        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", "Past Session Log.csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    } catch (error) {
        console.error('Error generating CSV:', error);
    }
  }
  async convertToPastCSV(data: any) {
    let csv = 'Organization,Client Name,Session Date,Coach,Takeaways,Elephant,Elephant Status,Blindspot,Blindspot Status,Notes,Files Shared,Marked Completed,Client Rating,Coach Rating\n';

    for (const orgData of data) {
        const orgName = orgData.Organization;
        const ClientName = orgData.Client_name;
        const SessionDate = orgData.Session_date;
        const Coach = orgData.Coach;
        const Takeaways = orgData.Takeaways;
        const Notes = orgData.Notes;
        const Marked_completed = orgData.Marked_completed;
        const Client_rating = orgData.Client_rating;
        const Coach_rating = orgData.Coach_rating;

        const elephants = [];
        const elephantsStatus = [];
        const blindspots = [];
        const blindspotsStatus = [];
        const filesShared = [];
        // Push data from Elephants array to corresponding arrays
        if (orgData.Elephants.length === 0) {
          elephants.push('No');
        } else {
            for (const elephant of orgData.Elephants) {
                if (elephant.name) {
                    elephants.push('Yes');
                } else {
                    elephants.push('No');
                }
            }
        }
        // Push data from Elephants_status array to corresponding arrays
        if (orgData.Elephants_status.length === 0) {
          elephantsStatus.push('');
        } else {
            for (const status of orgData.Elephants_status) {
                if (status.status) {
                    elephantsStatus.push('Completed');
                } else {
                    elephantsStatus.push('');
                }
            }
        }
      
        // Push data from Blindspots array to corresponding arrays
        if (orgData.Blindspots.length === 0) {
          blindspots.push('No');
        } else {
            for (const blindspot of orgData.Elephants) {
                if (blindspot.name) {
                  blindspots.push('Yes');
                } else {
                  blindspots.push('No');
                }
            }
        }

        // Push data from Blindspots_status array to corresponding arrays
        if (orgData.Blindspots_status.length === 0) {
          blindspotsStatus.push('');
        } else {
            for (const status of orgData.Blindspots_status) {
                if (status.status) {
                    blindspotsStatus.push('Completed');
                } else {
                    blindspotsStatus.push('');
                }
            }
        }

        // Join share values from Files_shared array
        // const filesShared = orgData.Files_shared.map(file => file.share).join(', ');
        for (const status of orgData.Files_shared) {
          filesShared.push(status.share);
        }
        // Create new row for each element in Elephants and Blindspots arrays
        const maxLength = Math.max(elephants.length, blindspots.length);
        for (let i = 0; i < maxLength; i++) {
          csv += `"${orgName}","${ClientName}","${SessionDate}","${Coach}","${Takeaways}","${elephants[i] || ''}","${elephantsStatus[i] || ''}","${blindspots[i] || ''}","${blindspotsStatus[i] || ''}","${Notes}","${filesShared[i] || ''}","${Marked_completed}","${Client_rating}","${Coach_rating}"\n`;
        }
    }

    return csv;
  }

}


