import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { io, Socket } from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  socket: Socket;
  constructor() {
    this.socket = io(environment.SOCKET_ENDPOINT,
      {
        transports: ['websocket'],
        path: environment.SOCKET_NAMESPACE,        
        // path: '/socket.io/',         
        // path: '/motus.io/',         
        upgrade: false,
        reconnectionAttempts: 10,
        reconnectionDelayMax: 10000,
        reconnectionDelay: 5000,
        closeOnBeforeunload: false,
      }
    );
  }
  sendMsgSocket(sent_msg: any) {
    // console.log("service send_message")
    this.socket.emit('send_message', sent_msg);
  }
  updateMsgStatus(obj: any) {
    // console.log("service update_msg_status");
    this.socket.emit('update_message_status', obj);
  }
  public newMsgReceiveSocket() {
    return new Observable((observer) => {
      this.socket.on('new_message', (message: any) => {
        observer.next(message);
      })
    })
  }
  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}
