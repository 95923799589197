<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css" rel="stylesheet">
<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <!-- <app-breadcrumb class="col-12" [breadcrumb]="breadcrumb"></app-breadcrumb> -->
        </div>
        <div class="content-body">
            <section id="configuration">
                <div class="row">
                    <div class="col-12" *blockUI="'zeroConfiguration'; message: 'Loading'">
                        <m-card [options]="options" (reloadFunction)="reloadZeroConfiguration($event)">
                            <ng-container mCardHeaderTitle>
                                <div class="text-end">
                                    <button type="button" class="btn btn-outline-dark btn-min-width box-shadow-1 mr-1 mb-1" (click)="openForm(Add)">Add Admin</button>
                                </div>

                            </ng-container>
                            <ng-container mCardBody>
                                <div class="card-dashboard  box-shadow-1 mb-1">
                                    <div class="table table-striped table-bordered base-style">
                                        <!-- <p class="card-text">DataTables has sorting and most features enabled by default.</p> -->
                                        <ngx-datatable class="bootstrap ps" [limit]="50" [rows]="admins" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="60" fxFlex="auto" [perfectScrollbar]="config">

                                            <ngx-datatable-column name="S.no" [flexGrow]="1" [minWidth]="90">
                                                <ng-template ngx-datatable-cell-template let-value="value" let-rowIndex="rowIndex" let-row="row">
                                                    {{rowIndex+1}}
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Image" [flexGrow]="1" [minWidth]="100">
                                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                                    <img *ngIf="row.image != null && row.image != 'null'" class="rounded-circle" src="{{row.image}}" alt="image" style="width: 30px; height: 30px;" />
                                                    <img *ngIf="row.image==null || row.image=='null' " class="rounded-circle" src="assets/images/no-image.png" alt="image" style="width: 30px; height: 30px;" />
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Name" [flexGrow]="1" [minWidth]="100">
                                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                                    {{row.first_name}} {{row.last_name|titlecase}}
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Email" [flexGrow]="1" [minWidth]="100">
                                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                                    {{row.email}}
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Mobile" [flexGrow]="1" [minWidth]="100">
                                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                                    {{row.mobile}}
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Status" [flexGrow]="1" [minWidth]="100">
                                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                                    <!-- <div class="badge badge-success" *ngIf="row.status == 'active'">Active</div>
                                                <div class="badge badge-danger" *ngIf="row.status == 'inactive'">Inactive</div> -->
                                                    <select [value]="row.status" (change)="updateUserStatus(row.user_id)">
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Action" [flexGrow]="1" [minWidth]="80" [maxWidth]="80">
                                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                                    <i (click)="editAdmin(row.id,Edit)" class="la la-pencil text-success hoverclass" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;
                                                    <i (click)="deleteUser(row.user_id)" class="la la-trash text-danger hoverclass" aria-hidden="true"></i>
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <!-- <ngx-datatable-column name="Change" [flexGrow]="1" [minWidth]="100" [maxWidth]="150">
                            <ng-template ngx-datatable-cell-template let-value="value" let-row="row" class="p-2" >
                                <select class='select-option form-control-md btn-success' name="admin_type" style="font-size: 15px; padding: 2px;" [(ngModel)]="row.admin_type" (change)='onOptionsSelected($event,row.id)'>
                                          
                                    <option class='option text-black ' *ngFor='let option of array'  value="{{option}}">{{option}}</option>
                                </select>
                            </ng-template>
                        </ngx-datatable-column> -->

                                        </ngx-datatable>
                                    </div>
                                </div>
                            </ng-container>
                        </m-card>
                    </div>
                </div>
            </section>

            <section id="form-components">
                <ng-template class="modal text-left" #Add let-d="dismiss">
                    <div class="modal-content">
                        <div class="modal-header">
                            <label class="modal-title text-text-bold-600" id="myModalLabel33">
                <h3>Add Admin</h3>
              </label>
                            <button type="button" class="close" aria-label="Close" (click)="d('Cross click');formStatusChange()">
                <span aria-hidden="true">&times;</span>
              </button>
                        </div>
                        <form [formGroup]="admin_info" (ngSubmit)="adminInfoSubmit()">
                            <div class="modal-body">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="projectinput1">First Name *</label>
                                                <input type="text" id="projectinput1" class="form-control" formControlName="first_name" placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" maxlength="50" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                          <div *ngIf=" f.first_name.errors.required">
                            First Name is required</div>
                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="projectinput2">Last Name *</label>
                                                <input type="text" id="projectinput2" class="form-control" formControlName="last_name" placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" maxlength="50" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                          <div *ngIf="f.last_name.errors.required">
                            Last Name is required</div>
                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="projectinput6">E-mail *</label>
                                                <input type="text" id="projectinput6" class="form-control" formControlName="email" placeholder="E-mail" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.email.errors" class="invalid-feedback">
                          <div *ngIf="f.email.errors.required">Email
                            is required</div>
                          <div *ngIf="f.email.errors.email">Email must
                            be a valid email address</div>
                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="projectinput4">Mobile No. *</label>
                                                <input type="text" id="projectinput4" class="form-control" formControlName="mobile" placeholder="Mobile No." [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                          <div *ngIf="f.mobile.errors.required">Mobile No.
                            is required</div>
                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <!-- <label for="projectinput3">Password *</label> -->
                                                <input type="hidden" id="projectinput3" class="form-control" formControlName="password" value="1234" placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.password.errors" class="invalid-feedback">
                          <div *ngIf="f.password.errors.required">Password
                            is required</div>
                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <!-- <label for="projectinput31">Confirm Password *</label> -->
                                                <input type="hidden" id="projectinput31" class="form-control" formControlName="password_confirmation" value="1234" placeholder="Confirm Password " [ngClass]="{ 'is-invalid': submitted && f.password_confirmation.errors }" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.password_confirmation.errors" class="invalid-feedback">
                          <div *ngIf="f.password_confirmation.errors.required">Password Confirmation
                            is required</div>
                        </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Upload Image</label>
                                            <label id="projectinput7" class="file center-block">
                        <input type="file" id="file" formControlName="image"
                          [ngClass]="{ 'is-invalid': submitted && f.image.errors }" style="margin-left: 3px;"
                          (change)="onFileSelected($event)" accept="image/*">
                        <span class="file-custom"></span>
                      </label>
                                            <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.image.errors" class="invalid-feedback">
                                       <div *ngIf="f.image.errors.required">Image
                                         is required</div>
                                     </small> -->
                                            <div class="col-4" *ngIf="imageURL && imageURL !== ''">
                                                <img [src]="imageURL" alt="admin" class="img-fluid mb-2 rounded">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal');emptyFile();formStatusChange()">Close</button>
                                <button type="submit" class="btn btn-outline-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </ng-template>
            </section>
            <section id="form-components">
                <ng-template class="modal text-left" #Edit let-d="dismiss">
                    <div class="modal-content">
                        <div class="modal-header">
                            <label class="modal-title text-text-bold-600" id="myModalLabel33">
                <h3>Edit Admin</h3>
              </label>
                            <button type="button" class="close" aria-label="Close" (click)="d('Cross click');formStatusChange()">
                <span aria-hidden="true">&times;</span>
              </button>
                        </div>
                        <form #edit_admin_form="ngForm" (ngSubmit)="updateAdmin(edit_admin_form.value,admin.user_id)">
                            <div class="modal-body">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="projectinput1">First Name *</label>
                                                <input type="text" id="projectinput1" class="form-control" name="first_name" placeholder="First Name" [(ngModel)]="admin.first_name" maxlength="50" />
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.first_name.errors"
                          class="invalid-feedback">
                          <div *ngIf=" f.first_name.errors.required">
                            First Name is required</div>
                        </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="projectinput2">Last Name *</label>
                                                <input type="text" id="projectinput2" class="form-control" name="last_name" placeholder="Last Name" [(ngModel)]="admin.last_name" maxlength="50" />
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.last_name.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.last_name.errors.required">
                            Last Name is required</div>
                        </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="projectinput6">E-mail *</label>
                                                <input type="text" id="projectinput6" class="form-control" name="email" placeholder="E-mail" [(ngModel)]="admin.email" />
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.email.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.email.errors.required">Email
                            is required</div>
                          <div *ngIf="f.email.errors.email">Email must
                            be a valid email address</div>
                        </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="projectinput4">Mobile No. *</label>
                                                <input type="text" id="projectinput4" class="form-control" name="mobile" placeholder="Mobile No." (keypress)="keyPress($event)" [(ngModel)]="admin.mobile" />
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.mobile.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.mobile.errors.required">Mobile No.
                            is required</div>
                        </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group ">
                                                <label for="projectinput5">Status*</label>
                                                <select id="projectinput5" class="form-control" name="status" [(ngModel)]="admin.status">
                          <option disabled>--Select--</option>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </select>
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.status.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.status.errors.required">
                            Status is required</div>
                        </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label>Upload Image</label>
                                            <label id="projectinput7" class="file center-block">
                        <input type="file" id="file" name="image"
                           style="margin-left: 3px;"
                          (change)="onFileSelected($event)" accept="image/*" >
                        <span class="file-custom"></span>
                      </label>
                                            <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.image.errors" class="invalid-feedback">
                                       <div *ngIf="f.image.errors.required">Image
                                         is required</div>
                                     </small> -->
                                            <div class="col-4" *ngIf="imageURL && imageURL !== ''">
                                                <img [src]="imageURL" alt="admin" class="img-fluid mb-2 rounded">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal');emptyFile();formStatusChange()">Close</button>
                                <button type="submit" class="btn btn-outline-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </ng-template>
            </section>
        </div>
    </div>
</div>