import { Component, ViewChild } from '@angular/core';
import { ApiService } from '../../_services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { PerfectScrollbarComponent, PerfectScrollbarDirective, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { ConstUrl } from 'src/app/_constant';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-invalid-clients',
  templateUrl: './invalid-clients.component.html',
  styleUrls: ['./invalid-clients.component.css']
})
export class InvalidClientsComponent {
  [x: string]: any;
  @BlockUI('modalThemes') blockUIModalThemes: NgBlockUI;
  @BlockUI('zeroConfiguration') blockUIZeroConfiguration: NgBlockUI;
  @BlockUI('defaultOrdering') blockUIDefaultOrdering: NgBlockUI;

  public configScroll: PerfectScrollbarConfigInterface = { suppressScrollY: false, wheelPropagation: false };
  public config: PerfectScrollbarConfigInterface = { suppressScrollX: true };

  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: true }) directiveRef?: PerfectScrollbarDirective;
  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
 
  submitted = false;
  file: any;
  imageFile: any;
  clients: any;
  client: any;
  organizations: any[];
  today_date = moment().format(ConstUrl.date_format)
  eighteen_y_before = moment().subtract(18, 'years').format(ConstUrl.date_format);
  imageURL: string;
  ALPHA_REGEX = /.*[a-zA-Z].*/;  // string atleast contain on alphabet
  userInfo: FormGroup;
  onbording_data :any[];
  filteredClients:any [];
  currentPage = 1;
  itemsPerPage = 50;
  totalItems:any; // Total number of items in your table, you should adjust this accordingly
  data = [];
  showTable:any;

  constructor(
    private ApiService:ApiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private clipboardService: ClipboardService
  
  
  ) {}

  ngOnInit() {
    this.userInfo = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required,Validators.minLength(10)]],
      as_admin: [false],
      // company_name: ['', Validators.required],
      // title: ['', Validators.required],
      // division: ['', Validators.required],
      // reportees: ['', Validators.required],
      // dob: ['', Validators.required],
      // work_anniversary: ['', Validators.required],
      // password: ['', Validators.required],
      // password_confirmation: ['', Validators.required],
      // image: [],
    });
    this.getOrganizations();
    this.getInvalidClients();
    this.loadData();

  }
  ngOnDestroy() {
    localStorage.removeItem('org_id')
  }
  formStatusChange(status: boolean = false) {
    this.submitted = status;
  }
  getInvalidClients() {
    this.keyValueArray = []; 
    this.clients = [];
    this.spinner.show();
    this.ApiService.getInvalidClients().subscribe((response: any) => {
      if (response.data.success) {
        this.showTable =true
        this.clients = response.data.data;
        for (let obj of this.clients) {
          let utcDate = new Date(obj.createdAt);
          let localDateString = utcDate.toLocaleString();
          if(localDateString){
              obj.sinup_details = localDateString;
              
          }else{
              obj.sinup_details = null;
          }
        }
        this.totalItems = this.clients.length;
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.toastr.success("No invalid members");
      }
      }, (error) => {
      var error_msg: string;
      if (error.response.data.data) {
        error_msg = Object.values(error.response.data.data)[0] as string;
      } else {
        error_msg = error.response.data.message;
      }
      this.toastr.error(error_msg);
    });
  }
  getOrganizations() {
    this.spinner.show();
    this.ApiService.getOrganizations('all').subscribe((response: any) => {
      if (response.data.success) {
        this.organizations = response.data.data;
        this.organizations = this.organizations.filter((o: any) => ((o.status == 'active') && (o.id != ConstUrl.motus_org_id)));
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.toastr.error(response.data.message);
      }
    });
  }
  returnCorrectDateFormat(dt: any) {
    return new DatePipe('en-US').transform(dt, 'yyyy-MM-dd');
  }

  emptyFile() {
    this.file = '';
    this.imageURL = null;
    this.imageFile = null;
  }

  openCreateForm(LoginFormContent) {
    this.modalService.open(LoginFormContent, { windowClass: 'animated FadeIn', backdrop: 'static', size: 'lg' });
  }
  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  editClient(id: any, modalName: any) {
    let user_id = id
    this.user = user_id
    let baselineintake = this.clients.find((c: any) => { return c.id == user_id });
    this.baselineintake_data = baselineintake.onboarding_data
    this.baselineintakeData = baselineintake.onboarding_data
    if(  this.baselineintakeData){
      for(let data of this.baselineintakeData){
        let item = JSON.parse(data?.answer)
        this.questionAnsAnswer = item
        this.submitTime = data.version
        Object.keys(item).forEach(key => {
          // console.log("key, item[key]==>",key, item[key]);
        }); 
      }
    }
    let client = this.clients.find((c: any) => { return c.id == id });
    this.client = { ...client };
    this.client.org_id = this.organizations[0].id;
    this.imageURL = this.client.image;
    this.openCreateForm(modalName);
    
  
  }
  
  moveToValid(data:any, id: any) {
    if (!this.ALPHA_REGEX.test(data.first_name)) {
      this.toastr.error("The first name field is not valid");
      return;
    }
    if (!this.ALPHA_REGEX.test(data.last_name)) {
      this.toastr.error("The last name field is not valid");
      return;
    }
    if (!data.org_id) {
      this.toastr.error("The organization field is required");
      return;
    }
    

    let formData = new FormData();
    formData.append('email', data.email);
    formData.append('user_type', 'client');
    formData.append('first_name', data.first_name);
    formData.append('last_name', data.last_name);
    formData.append('mobile', data.mobile);
    formData.append('client_type', data.client_type);
    formData.append('org_id', data.org_id);
    formData.append('status', data.status);
    formData.append('is_valid', "1");
    
    if (data.company_name && data.company_name != "null") {
      formData.append('company_name', data.company_name);
    }
    if (data.title && data.title != "null") {
      formData.append('title', data.title);
    }
    if (data.division && data.division != "null") {
      formData.append('division', data.division);
    }
    if (data.reportees && data.reportees != "null") {
      formData.append('reportees', data.reportees);
    }
    if (data.dob && data.dob != "null") {
      formData.append('dob', this.returnCorrectDateFormat(data.dob));
    } 
    if (data.work_anniversary && data.work_anniversary != "null") {
      formData.append('work_anniversary', this.returnCorrectDateFormat(data.work_anniversary));
    } 

    if (this.imageFile) {
      formData.append('image', this.imageFile)
    } else {
      delete data.image;
    }
    this.spinner.show();
    this.ApiService.updateUser(formData, id).subscribe((data: any) => {
      const response = data.data;
      if (response.success) {
        this.toastr.success("User successfully moved to member");
        this.client = {};
        this.imageFile = ''
        this.imageURL = null;

        this.modalService.dismissAll();
        this.ngOnInit();
      }else {
        this.toastr.error(data.message);
      }
      this.spinner.hide();
    }, (error) => {
      var error_msg: string;
      if (error.response.data.data) {
        error_msg = Object.values(error.response.data.data)[0] as string;
      } else {
        error_msg = error.response.data.message;
      }
      this.toastr.error(error_msg);
      this.spinner.hide();

    });
  }

  onFileSelected(event: any, file_type = 'image') {
    if (file_type == 'image') {
      this.imageFile = (event.target as HTMLInputElement).files[0];
      if (!(this.imageFile.size <= ConstUrl.max_image_size)) {
        this.toastr.error('Maximum allowed size is 5MB');
        this.imageFile = null;
        (event.target as HTMLInputElement).value = '';
        return
      }
      // File Preview
      const reader = new FileReader();
      reader.onload = () => {
        this.imageURL = reader.result as string;
      }
      reader.readAsDataURL(this.imageFile)
    } else {
      this.file = (event.target as HTMLInputElement).files[0];
      if (!(this.file.size <= ConstUrl.max_image_size)) {
        this.toastr.error('Maximum allowed size is 5MB');
        this.file = null;
        (event.target as HTMLInputElement).value = '';
        return
      }
    }
  }
  importClients() {
    this.spinner.show();

    let api_data = new FormData()
    api_data.append('csv_file', this.file);
    api_data.append('user_type', 'client');

    this.ApiService.importUsers(api_data).subscribe((data: any) => {
      let response = data.data;
      if (response.success) {
        this.file = null;
        this.ngOnInit();
        this.toastr.success(data.data.message);
      }
      else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (error) => {
      this.toastr.error("Invalid csv file");
      this.spinner.hide();
    })

  }

  deleteUser(id: any) {
    let user_id = id;
    if (user_id) {
      this.spinner.show();
      this.ApiService.deleteUserSinup(user_id).subscribe(response => {
        if (response.data.success) {
          this.toastr.success(response.data.message);
          this.getOrganizations();
          this.getInvalidClients();
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.toastr.error(response.data.message);
        }
      })
    }
  }
  //-----------------------MK--------------------------
  get f() {
    return this.userInfo.controls;
  }

  onProjectInfoSubmit(user_id:any) {
    this.submitted = true;
    if (this.userInfo.invalid) {
      return;
    }
    
    if(!this.ALPHA_REGEX.test(this.userInfo.value.first_name)){
      this.toastr.error("The first name field is not valid");
      return;
    }
    if(!this.ALPHA_REGEX.test(this.userInfo.value.last_name)){
      this.toastr.error("The last name field is not valid");
      return;
    }
    this.spinner.show();
    let formData = new FormData();
    Object.keys(this.userInfo.value).forEach((itm) => {
      formData.append(itm,this.userInfo.value[itm]);
    })
    formData.append('user_type','coach');
    formData.append('user_id',user_id);
    if(this.imageFile){
      formData.append('image',this.imageFile)
    }
    
    this.ApiService.makeACoach(formData).subscribe((data: any) => {
      const response = data.data;  
      if(response.success) {
        this.toastr.success(data.data.message);
        this.userInfo.reset()
        this.imageURL = null;
        this.imageFile = ''
        this.submitted = false;
        this.modalService.dismissAll();
        this.ngOnInit();
      }
      else{
        this.toastr.error(data.message);
      }
      this.spinner.hide();
    },(error) =>{
      var error_msg:string;
      if(error.response.data.data){
        error_msg = Object.values(error.response.data.data)[0] as string; 
      }else{
        error_msg = error.response.data.message;
      }
      this.spinner.hide();
      this.toastr.error(error_msg);
    });
  }


  loadData() {
    // Implement logic to load data into this.data array
    // This is just a placeholder, replace it with your actual data loading logic
    for (let i = 1; i <= this.totalItems; i++) {
      this.data.push(`Item ${i}`);
    }
  }

  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
  }

  // Start  Shared Resources
  async downloadSharedResourcesCSV() {
    try {
        const csvData = await this.convertSharedResourcesToCSV(this.clients);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const dwldLink = document.createElement("a");

        if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
            // Safari browser
            dwldLink.setAttribute("target", "_blank");
        }

        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", "Signed Up From Mobile.csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    } catch (error) {
        console.error('Error generating CSV:', error);
    }
}

async convertSharedResourcesToCSV(data: any) {
  let csv = 'Name,Email,Mobile,Sign up,Question,Answer\n';
  for (const clientData of data) {
      const Name = clientData.first_name + clientData.last_name ?  clientData.first_name + clientData.last_name : '';
      const Email = clientData.email ? clientData.email : '';
      const Mobile = clientData.mobile ? clientData.mobile : '';
      const Sign_up = clientData.sinup_details ?  clientData.sinup_details : '';

      // Check if onboarding_data exists and is iterable
      if (clientData.onboarding_data && typeof clientData.onboarding_data[Symbol.iterator] === 'function') {
          for (const item of clientData.onboarding_data) {
              const question = item.question;
              const answer = item.answer;
              csv += `"${Name}","${Email}","${Mobile}","${Sign_up}","${question}","${answer}"\n`;
          }
      } else {
        const question = '';
        const answer = '';
        csv += `"${Name}","${Email}","${Mobile}","${Sign_up}","${question}","${answer}"\n`;
      }
  }
  return csv;
}


  searchQuery: string = '';

  updateFilteredClients() {
    this.showTable = false; // Hide the table when there's a search query
    if (!this.searchQuery) {
        this.filteredClients = [];
        this.showTable = true; // Show the table when there's no search query
    } else {
       
        this.filteredClients = this.clients.filter(client => {
            // Check if any of the required fields are null before performing operations
            const firstName = client.first_name ? client.first_name.toLowerCase() : '';
            const lastName = client.last_name ? client.last_name.toLowerCase() : '';
            const mobile = client.mobile ? client.mobile : '';
            const email = client.email ? client.email.toLowerCase() : '';

            return (
                firstName.includes(this.searchQuery.toLowerCase()) ||
                lastName.includes(this.searchQuery.toLowerCase()) ||
                mobile.includes(this.searchQuery) ||
                email.includes(this.searchQuery.toLowerCase())
            );
        });
    }
}

// copyToClipboard(text: string) {
//   this.clipboardService.copy(text);
//   console.log('Text copied to clipboard');
// }
copyToClipboard(data: string | null): void {
  if (data === null) {
    return;  // Do nothing if data is null
  }
  let user_id = data
  this.user = user_id
  let baselineintake = this.clients.find((c: any) => { return c.id == user_id });
  this.baselineintakeData = baselineintake.onboarding_data
  let datas = this.getLatestEntry(this.baselineintakeData)
  let copyData = datas.link;
  console.log("copyData==>",copyData)
  this.clipboardService.copy(copyData);
  // Use the Clipboard API to copy the data
  // if (navigator.clipboard) {
  //     navigator.clipboard.writeText(copyData).then(() => {
  //           console.log('Text copied to clipboard');
  //       }).catch(err => {
  //           console.error('Failed to copy text: ', err);
  //       });
  //   } else {
  //       console.error('Clipboard API is not supported');
  //   }
}
getLatestEntry(baselineintakeData: any[]): any {
  if (baselineintakeData.length === 0) {
    return null; // Handle empty array case
  }

  // Sort array by date in descending order to get the latest entry first
  const sortedData = [...baselineintakeData].sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  // Return the first entry which is the latest
  return sortedData[0];
}

  // Method to update filteredClients when searchQuery changes
//   updateFilteredClients() {
//     if(!this.searchQuery){
//       this.filteredClients= [];
//       if(this.filteredClients.length == 0){
//         this.showTable = true
//       }
//       this.clients = this.clients
//     }else{
//       this.showTable = false
//       this.filteredClients = this.clients.filter(client => {
//         // Check if any of the required fields are null before performing operations
//         const firstName = client.first_name ? client.first_name.toLowerCase() : '';
//         const lastName = client.last_name ? client.last_name.toLowerCase() : '';
//         const mobile = client.mobile ? client.mobile : '';
//         const email = client.email ? client.email.toLowerCase() : '';

//         return (
//             firstName.includes(this.searchQuery.toLowerCase()) ||
//             lastName.includes(this.searchQuery.toLowerCase()) ||
//             mobile.includes(this.searchQuery) ||
//             email.includes(this.searchQuery.toLowerCase())
//         );
//     });
//     }
    
   
// }
}


