import { Pipe, PipeTransform } from '@angular/core';
import { ConstUrl } from 'src/app/_constant';
import moment from 'moment';


@Pipe({
  name: 'datetime'
})
export class DatetimePipe implements PipeTransform {

  transform(value: string,format = ' hh:mm a'): string {
    if (!value) return ''; 
    //utc to loacl time
    const formattedTime = moment.utc(value).local().format(format);
    return formattedTime;
  }

}
