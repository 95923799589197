import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';

import { LoginComponent } from './motus/components/login/login.component';
import { AuthGuard } from './motus/_guards/auth.guard';
import { CoachsListComponent } from './motus/components/coachs-list/coachs-list.component';
import { ClientsListComponent } from './motus/components/clients-list/clients-list.component';
import { OrganizationComponent } from './motus/components/organization/organization.component';
import { ProfileComponent } from './motus/components/profile/profile.component';
import { AdminComponent } from './motus/components/admin/admin.component';
import { BannerComponent } from './motus/components/banner/banner.component';
import { IsAdminGuard } from './motus/_guards/is-admin.guard';
import { ChatComponent } from './motus/components/chat/chat.component';
import { SyncComponent } from './motus/components/sync/sync.component';
import { SetNewPasswordComponent } from './motus/components/set-new-password/set-new-password.component';
import { InvalidClientsComponent } from './motus/components/invalid-clients/invalid-clients.component';
import { ReportComponent } from './motus/components/report/report.component';
import { QuestionsComponent } from './motus/components/questions/questions.component';
import { QuestionsFiveComponent } from './motus/components/questions-five/questions-five.component';
import { SuccessComponent } from './motus/components/success/success.component';
import { PrivacyComponent } from './motus/components/privacy/privacy.component';
import { MobileAppComponent } from './motus/components/mobile-app/mobile-app.component';
import { ShowAssessmentFormOutputComponent } from './motus/components/show-assessment-form-output/show-assessment-form-output.component';
const routes: Routes = [
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: '', component: LoginComponent },
      { path: 'sync', component: SyncComponent },
      { path: 'set-new-password', component:SetNewPasswordComponent },
      // { path: 'BaselineIntakeForm/:id', component:QuestionsComponent},
      { path: 'baselineintakeform/:id', component:QuestionsComponent},
      // { path: 'question/five', component:QuestionsFiveComponent},Onboarding Form
      // { path: 'OnboardingForm/:id', component:QuestionsFiveComponent},
      { path: 'onboardingform/:id', component:QuestionsFiveComponent},
      { path: 'Success', component: SuccessComponent},
      { path: 'mobileapp-download', component: MobileAppComponent},
      { path: 'assessment-form-output/:id', component:ShowAssessmentFormOutputComponent},
      { path: 'privacy_policy', component: PrivacyComponent},

      
    ]
  },
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      { path: 'profile', component: ProfileComponent},
      { path: 'coach', component: CoachsListComponent},
      { path: 'clients', component: ClientsListComponent},
      { path: 'invalid-clients', component: InvalidClientsComponent},
      { path: 'organizations', component: OrganizationComponent},
      // { path: 'admin', component: AdminComponent,canActivate:[IsAdminGuard]},
      { path: 'setting/banner', component: BannerComponent},
      { path: 'chat', component:ChatComponent },
      { path: 'report',component:ReportComponent},
     
      // { path: 'client-setting/coupon', component: CouponComponent, canActivate: [AuthnewGuard] },
      // {
      //   path: 'dashboard', loadChildren: () => import('../app/content/dashboard/dashboard.module').then(m => m.DashboardModule)
      //   ,canActivate:[AuthnewGuard]
      // },
    ],
    canActivate:[AuthGuard]
  },
  { path: '**', redirectTo: ''},
];

export const routing = RouterModule.forRoot(routes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top' });
