import { Component, ViewChild } from '@angular/core';
import { ApiService } from '../../_services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { PerfectScrollbarComponent, PerfectScrollbarDirective, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { ConstUrl } from 'src/app/_constant';
import { Router,ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.css']
})
export class ClientsListComponent {
  @BlockUI('modalThemes') blockUIModalThemes: NgBlockUI;
  @BlockUI('zeroConfiguration') blockUIZeroConfiguration: NgBlockUI;
  @BlockUI('defaultOrdering') blockUIDefaultOrdering: NgBlockUI;

  public configScroll: PerfectScrollbarConfigInterface = { suppressScrollY : false, wheelPropagation: false };
  // changed for horizontal scroll problem old : "suppressScrollY : false
  public config: PerfectScrollbarConfigInterface = { suppressScrollX : true };

  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: true }) directiveRef?: PerfectScrollbarDirective;
  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

  submitted = false;
  userInfo: FormGroup;
  file: any;
  imageFile: any;
  clients: any;
  init_clients: any;
  all_org_clients: any;
  client: any;
  organizations: any[];
  // inactive also
  all_organizations: any[];
  base_url:any;
  today_date = moment().format(ConstUrl.date_format) 
  eighteen_y_before = moment().subtract(18,'years').format(ConstUrl.date_format);
  imageURL: string;
  temp:any;
  queryParams:any = null;
  filter_org:string= '0';
  clients_excel_sample:string;
  dic_path = 'motus9/sample_excels/';
  // ---- ffd ----
  blindspots: any = [];
  elephants: any = [];
  action_items: any = [];
  take_aways: any = [];
  current_ffd: string;
  current_ffd_data: any = null;
  sessions: number = 0;
  client_id: string = '1';
  target: any = [];
  getsessions: any = null;
  completed: any = 0;
  user_id:any;
  total_sessions: any = 0;
  percent_sess: number;
  motus_org_id:string = ConstUrl.motus_org_id
  user_exists:boolean = false;
  existing_user:any = [];
  ALPHA_REGEX = /.*[a-zA-Z].*/ ;  // string atleast contain on alphabet

  // new
  filteredClients:any [];
  currentPage = 1;
  itemsPerPage = 50;
  totalItems:any; // Total number of items in your table, you should adjust this accordingly
  data = [];
  showTable:any;
  baselineintakeData :any[];
  ans1: any;
  ans2: any;
  ans3: any;
  ans4: any;
  ans5: any;
  ans6: any;
  ans7: any;
  ans8: any;
  ans9: any;
  ans10: any;
  ans11: any;
  ans12: any;
  ans13: any;
  ans14: any;
  ans15: any;
  ans16: any;
  ans17: any;
  ans18: any;
  ans19: any;
  questionAnsAnswer: any;
  submitTime: any;
  constructor(
    private ApiService: ApiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private router : Router,
    private activatedRoute : ActivatedRoute,
    
  ) {
    this.clients_excel_sample = environment.S3_URL + this.dic_path + 'clients.xlsx';
    // ----- ffd -------
    this.current_ffd = 'Elephants';
    this.current_ffd_data = this.elephants;

   }

  ngOnInit() {
    this.userInfo = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', Validators.required],
      // company_name: [''],
      title: [''],
      division: [''],
      reportees: [''],
      dob: [''],
      work_anniversary: [''],
      client_type: ['member', Validators.required],
      image: [''],
      org_id:['1',Validators.required],
      ex_client_id:[null],
      // password: ['', Validators.required], 
      // password_confirmation: ['', Validators.required],
    });
    this.getOrganizations();

    this.ApiService.user$.subscribe(user => {
    });

    // this.activatedRoute.queryParams.subscribe(params => {
    //     if(params && params.org_id !== null && params.org_id !== 'null'){
    //       this.filter_org = params.org_id;
    //       this.filterClient();
    //     }
    //   }
    // );
    let filter_org_id = localStorage.getItem('org_id');
    if(filter_org_id != null && filter_org_id != '' && filter_org_id != "null"){
      this.filter_org = filter_org_id;
      this.filterClient();
      // localStorage.setItem('org_id',null)
    }else{
      this.getClients();
    }
  }
  ngOnDestroy(){
    localStorage.removeItem('org_id')
  }
  formStatusChange(status:boolean = false){
    this.submitted = status;
  }
  getClients() {
    this.clients = [];
    this.spinner.show();
    this.ApiService.getClients().subscribe((response: any) => {
      if (response.data.success) {
        this.showTable =true
       
        this.clients = response.data.data;
        this.totalItems = this.clients.length
        this.init_clients = this.clients
        this.all_org_clients = this.clients
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.toastr.error(response.data.message);
      }
    }, (error) =>{
      var error_msg:string;
      if(error.response.data.data){
        error_msg = Object.values(error.response.data.data)[0] as string; 
      }else{
        error_msg = error.response.data.message;
      }
      this.toastr.error(error_msg);
    });
  }
  deleteUser(id: any) {
    let is_confirmed = confirm("Are you sure to delete this record?");
    if (is_confirmed) {
      this.spinner.show();
      this.ApiService.deleteUser(id).subscribe(response => {
        if (response.data.success) {
          this.toastr.success(response.data.message);
          // this.clients = this.clients.filter((c: any) => { return c.user_id != id })
          // this.all_org_clients = this.all_org_clients.filter((c: any) => { return c.user_id != id })
          this.getClients();
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.toastr.error(response.data.message);
        }
      })
    }
  }
  getOrganizations() {
    this.spinner.show();
    this.ApiService.getOrganizations('all').subscribe((response: any) => {
      if (response.data.success) {
        this.all_organizations = response.data.data;
        this.organizations = this.all_organizations.filter( (o:any) => ((o.status == 'active') && (o.id != '1')))
        this.userInfo.get('org_id').setValue(this.organizations[0].id);
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.toastr.error(response.data.message);
      }
    });
  }
  updateUserStatus(id:any) {
    this.spinner.show();
    this.ApiService.updateUserStatus(id).subscribe((response: any) => {
      if (response.data.success) {
        this.spinner.hide();
        this.toastr.success(response.data.message);
      } else {
        this.spinner.hide();
        this.toastr.error(response.data.message);
      }
    });
  }
  redirectFfd(user_data){
    // localStorage.setItem('ffd_clients_id',org_id);
    // this.router.navigate(['/clients/ffd']);

    this.client_id = user_data.id;
    this.user_id = user_data.user_id;
    this.getFfds();
    this.getSessions();
   
  }
  updatedefaultFilter(event) {
    const val = event.target.value.toLowerCase();
    // this.temp = [...this.clients];
    // filter our data
    const temp = this.init_clients.filter(function (d:any) {
      return (d.first_name ? (d.first_name.toLowerCase().indexOf(val) !== -1) : false) || 
       (d.last_name ? (d.last_name.toLowerCase().indexOf(val) !== -1) : false) || 
       (d.email ? (d.email.toLowerCase().indexOf(val) !== -1) : false) || 
       (d.mobile ? (d.mobile.toLowerCase().indexOf(val) !== -1) : false) || 
       (!val) || (val === "") || (val === " ");
    });
    // update the rows
    this.clients = temp;

    // Whenever the filter changes, always go back to the first page
    // this.table.offset = 0;

  }
  filterClient() {
    let data = { "org_id": this.filter_org };
    localStorage.setItem('org_id',this.filter_org)
    this.spinner.show();
    if (this.filter_org == "0") {
      // this.init_clients = this.all_org_clients;
      // this.clients = this.all_org_clients;
      this.getClients();
      this.spinner.hide();
    } else {
      this.ApiService.getClients(data).subscribe((response: any) => {
        if (response.data.success) {
          this.clients = response.data.data;
          this.init_clients = this.clients
        } else {
          this.clients = [];
        }
        this.spinner.hide();
      });
    }
  }
  returnCorrectDateFormat(dt:any){
    return new DatePipe('en-US').transform(dt, 'yyyy-MM-dd');
  }
  get f() {
    return this.userInfo.controls;
  }
  emptyFile(){
    this.file = '';
    this.imageURL = null;
    this.imageFile = null;
  }
  // use this for multi org work 
  // onProjectInfoSubmit() {

  //   this.submitted = true;
  //   if (this.userInfo.invalid) {
  //     return;
  //   }
  //   // if(this.userInfo.value.password !== this.userInfo.value.password_confirmation){
  //   //   this.toastr.error("Password and confirm password not matched");
  //   //   return;
  //   // }
  //   this.spinner.show();
  //   let formData = new FormData();
  //   Object.keys(this.userInfo.value).forEach((itm) => {
  //     formData.append(itm, this.userInfo.value[itm]);
  //   })
  //   formData.append('user_type','client');
  //   if (this.file) {
  //     formData.append('image', this.file)
  //   }
  //   if(this.userInfo.value['ex_client_id']){
  //     formData.append('uniqueness_checked', 'true');
  //     // formData.append('client_id', this.ex_client_id);
  //   }
    
  //   this.ApiService.createUser(formData).subscribe((data: any) => {
  //     const response = data.data;
  //     if (response.success) {
  //       this.toastr.success(data.data.message);
  //       this.userInfo.reset()
  //       this.file = ''
  //       this.imageURL = null;
  //       this.submitted = false;
  //       this.existing_user = [];
  //       this.modalService.dismissAll();
  //       this.ngOnInit();
  //     }
  //     else {
  //       // this.toastr.success(response.message);
  //       if (response.data) {
  //         this.user_exists = true;
  //         this.existing_user = response.data;
  //         this.userInfo.get('ex_client_id').setValue(this.existing_user[0].client_id);

  //       } else {
  //         this.toastr.error(response.message);
  //       }
  //     }
  //     this.spinner.hide();
  //   }, (error) => {
  //     var error_msg: string;
  //     if (error.response.data.data) {
  //       error_msg = Object.values(error.response.data.data)[0] as string;
  //     } else {
  //       error_msg = error.response.data.message;
  //     }
  //     this.toastr.error(error_msg);
  //     this.spinner.hide();
  //   });
  // }
  onProjectInfoSubmit() {
    this.submitted = true;
    if (this.userInfo.invalid) {
      return;
    }
    if(!this.ALPHA_REGEX.test(this.userInfo.value.first_name)){
      this.toastr.error("The first name field is not valid");
      return;
    }
    if(!this.ALPHA_REGEX.test(this.userInfo.value.last_name)){
      this.toastr.error("The last name field is not valid");
      return;
    }
    this.spinner.show();
    let formData = new FormData();
    Object.keys(this.userInfo.value).forEach((itm) => {
      formData.append(itm, this.userInfo.value[itm]);
    })
    formData.append('user_type','client');
    if (this.imageFile) {
      formData.append('image', this.imageFile)
    }
    
    this.ApiService.createUser(formData).subscribe((data: any) => {
      const response = data.data;
      if (response.success) {
        this.toastr.success(data.data.message);
        this.userInfo.reset()
        this.imageFile = ''
        this.imageURL = null;
        this.submitted = false;
        this.modalService.dismissAll();
        this.ngOnInit();
      }
      else {
        this.toastr.error(data.message);
      }
      this.spinner.hide();
    }, (error) => {
      var error_msg: string;
      if (error.response.data.data) {
        error_msg = Object.values(error.response.data.data)[0] as string;
      } else {
        error_msg = error.response.data.message;
      }
      this.toastr.error(error_msg);
      this.spinner.hide();
    });
  }

  openCreateForm(LoginFormContent) {
    this.modalService.open(LoginFormContent, { windowClass: 'animated FadeIn', backdrop: 'static',size:'lg'});
  }
  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  editClient(id: any, modalName: any) {
   
   
    let user_id = id
    // this.user = user_id
    let baselineintake = this.clients.find((c: any) => { return c.id == user_id });
    this.baselineintakeData = baselineintake.baselineIntakeForm
    if(  this.baselineintakeData){
      for(let data of this.baselineintakeData){
        let item = JSON.parse(data?.answer)
        this.questionAnsAnswer = item
        this.submitTime = data.version
        console.log("data==>",data.version)
        console.log("item==>",item)
        Object.keys(item).forEach(key => {
         console.log("key, item[key]==>",key, item[key]);
       });
        
     }
    }
   


    // if(this.baselineintakeData){
    //   for(let baselineObj of this.baselineintakeData){
    //     if(baselineObj.question_id == 1){
    //        this.ans1 = baselineObj.answer
    //     }
    //     if(baselineObj.question_id == 2){
    //        this.ans2 = baselineObj.answer
    //     } if(baselineObj.question_id == 3){
    //        this.ans3 = baselineObj.answer
    //     }
    //     if(baselineObj.question_id == 4){
    //        this.ans4 = baselineObj.answer
    //     }
    //     if(baselineObj.question_id == 5){
    //        this.ans5 = baselineObj.answer
    //     }
    //     if(baselineObj.question_id == 6){
    //        this.ans6 = baselineObj.answer
    //     }
    //     if(baselineObj.question_id == 7){
    //        this.ans7 = baselineObj.answer
    //     }
    //     if(baselineObj.question_id == 8){
    //        this.ans8 = baselineObj.answer
    //     } if(baselineObj.question_id == 9){
    //        this.ans9 = baselineObj.answer
    //     }
    //     if(baselineObj.question_id == 10){
    //        this.ans10 = baselineObj.answer
    //     }
    //     if(baselineObj.question_id == 11){
    //        this.ans11 = baselineObj.answer
    //     }
    //     if(baselineObj.question_id == 12){
    //        this.ans12 = baselineObj.answer
    //     }
    //     if(baselineObj.question_id == 13){
    //        this.ans13 = baselineObj.answer
    //     }
    //     if(baselineObj.question_id == 14){
    //        this.ans14 = baselineObj.answer
    //     } if(baselineObj.question_id == 15){
    //        this.ans15 = baselineObj.answer
    //     }
    //     if(baselineObj.question_id == 16){
    //        this.ans16 = baselineObj.answer
    //     }
    //     if(baselineObj.question_id == 17){
    //        this.ans17 = baselineObj.answer
    //     }
    //     if(baselineObj.question_id == 18){
    //        this.ans18 = baselineObj.answer
    //     }
    //     if(baselineObj.question_id == 19){
    //        this.ans19 = baselineObj.answer
    //     }
    // }
    // }
    

    let client = this.clients.find((c: any) => { return c.id == id });
    this.client = { ...client };
    this.imageURL = this.client.image ;
    this.openCreateForm(modalName);
  }
  getObjectKeys() {
    return Object.keys(this.questionAnsAnswer);
  }
  updateClient(data: any, id: any) { 
    if(!this.ALPHA_REGEX.test(data.first_name)){
      this.toastr.error("The first name field is not valid");
      return;
    }
    if(!this.ALPHA_REGEX.test(data.last_name)){
      this.toastr.error("The last name field is not valid");
      return;
    }
    this.spinner.show();
   
    let formData = new FormData();
        formData.append('email',data.email);
        formData.append('user_type','client');
        formData.append('first_name',data.first_name);
        formData.append('last_name',data.last_name);
        formData.append('mobile',data.mobile);
        formData.append('client_type',data.client_type);
        formData.append('org_id',data.org_id);
        formData.append('company_name',data.company_name);
        formData.append('title',data.title);
        formData.append('status',data.status);
        formData.append('division',data.division);
        formData.append('reportees',data.reportees);
        if(data.dob != "null"){ 
          formData.append('dob',this.returnCorrectDateFormat(data.dob));
        }else{
          formData.append('dob',data.dob);
        }
        if(data.work_anniversary != "null"){
          formData.append('work_anniversary',this.returnCorrectDateFormat(data.work_anniversary));
        }else{
          formData.append('work_anniversary',data.work_anniversary);
        }
        
       
        if(this.imageFile){
          formData.append('image',this.imageFile)
        }else{
          delete data.image;
        }
    this.ApiService.updateUser(formData, id).subscribe((data: any) => {
      const response = data.data;
      if (response.success) {
        this.toastr.success(data.data.message);
        this.client = {};
        this.imageFile = ''
        this.imageURL = null;

        this.modalService.dismissAll();
        this.ngOnInit();
      }
      else {
        this.toastr.error(data.message);
      }
      this.spinner.hide();
    }, (error) => {
      var error_msg: string;
      if (error.response.data.data) {
        error_msg = Object.values(error.response.data.data)[0] as string;
      } else {
        error_msg = error.response.data.message;
      }
      this.toastr.error(error_msg);
      this.spinner.hide();

    });
  }
  onFileSelected(event: any, file_type = 'image') {
    if (file_type == 'image') {
      this.imageFile = (event.target as HTMLInputElement).files[0];
      if (!(this.imageFile.size <= ConstUrl.max_image_size)) {
        this.toastr.error('Maximum allowed size is 5MB');
        this.imageFile = null;
        (event.target as HTMLInputElement).value = '';
        return
      }
      // this.file = e.target.files[0];
      // const file = (event.target as HTMLInputElement).files[0];
      // this.uploadForm.patchValue({
      //   avatar: file
      // });
      // this.uploadForm.get('avatar').updateValueAndValidity()
      // File Preview
      const reader = new FileReader();
      reader.onload = () => {
        this.imageURL = reader.result as string;
      }
      reader.readAsDataURL(this.imageFile)
    } else {
      this.file = (event.target as HTMLInputElement).files[0];
      if (!(this.file.size <= ConstUrl.max_image_size)) {
        this.toastr.error('Maximum allowed size is 5MB');
        this.file = null;
        (event.target as HTMLInputElement).value = '';
        return
      }
      
    }

  }
  importClients(){
    this.spinner.show();

    let api_data = new FormData()
    api_data.append('csv_file', this.file);
    api_data.append('user_type', 'client');

    this.ApiService.importUsers(api_data).subscribe( (data:any) => {
      let response = data.data;
      if (response.success) {
        this.file = null;
        this.ngOnInit();
        this.toastr.success(data.data.message);
      }
      else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (error) => {
      this.toastr.error("Invalid csv file");
      this.spinner.hide();
    })
    
  }
  //------- FFd -----------
  get targetCount() {
    let total: number = this.target.length || 0;
    let achieved: number = this.target.filter((itm: any) => itm.completed == 100).length || 0; 
    return {
      total: total ,
      achieved: achieved ,
      in_progress: (total - achieved) || 0,
      progress:(achieved/total)*100 || 0,
    }
  }
  get elephantCount() {
    let total: number = this.elephants.length;
    let is_done: number = this.elephants.filter((itm: any) => itm.is_done === '1').length;

    return {
      total: total,
      is_done: is_done,
      percent: (is_done / total) * 100,
    }
  }
  get blindspotCount() {
    let total: number = this.blindspots.length;
    let is_done: number = this.blindspots.filter((itm: any) => itm.is_done === '1').length;
    return {
      total: total,
      is_done: is_done,
      percent: (is_done / total) * 100,
    }
  }
  get takeAwayCount() {
    let total: number = this.take_aways.length;
    let is_done: number = this.take_aways.filter((itm: any) => itm.is_done === '1').length;
    return {
      total: total,
      is_done: is_done,
      percent: (is_done / total) * 100,
    }
  }
  get actionItemCount() {
    let total: number = this.action_items.length;
    let is_done: number = this.action_items.filter((itm: any) => itm.is_done === '1').length;
    return {
      total: total,
      is_done: is_done,
      percent: (is_done / total) * 100,
    }
  }
  getFfds() {
    this.spinner.show();
    this.ApiService.getClientFfds(this.client_id).subscribe((response: any) => {
      let data = response.data.data;
      if (response.data.success && response.data.data != null) {
        if(data.session){
          this.sessions = data.session;
        }
        if (data.ffd) {
          this.elephants = data.ffd.filter((itm: any) => itm.ffd_type === 'elephant');
          this.blindspots = data.ffd.filter((itm: any) => itm.ffd_type === 'blindspot');
        }
        if (data.actionItems) {
          this.action_items = data.actionItems;
        }
        if (data.takeaways) {
          this.take_aways = data.takeaways;
        }
        if (data.target) {
          this.target = data.target;
        }
      } else {
        // this.toastr.error(response.data.message);
      }
      this.spinner.hide();
    });
  }
  getSessions() {
    let params_obj = {userid:this.user_id};
    this.ApiService.get_session_by_admin_by_user_id(params_obj).subscribe((response: any) => {
      let data = response.data.data;
      if (response.data.success && response.data.data != null) {
        data.sort((a, b) => {
          const dateA = new Date(a.session_date);
          const dateB = new Date(b.session_date);
          
          return dateB.getTime() - dateA.getTime();
      });
       this.getsessions = data;
      // sorting by dateTime | Desc
      //  this.getsessions.sort((a:any,b:any)=>{
      //   const datetimeA:any = new Date(a.session_date + 'T' + a.session_time + 'Z');
      //   const datetimeB:any = new Date(b.session_date + 'T' + b.session_time + 'Z');
      //   return datetimeB - datetimeA;
      //  })


       this.total_sessions = this.getsessions.length;
       this.completed= this.getsessions.filter((itm: any) => itm.status === 'completed').length;
       this.percent_sess = (this.completed  /  this.total_sessions) * 100;
      } else {
        this.getsessions = null;
        this.total_sessions = 0;
        this.completed = 0 ;
        this.percent_sess = 0;
      }
      // this.spinner.hide();
    });
  
  }
  clearFfd(){
    this.blindspots = [];
    this.elephants = [];
    this.action_items = [];
    this.take_aways = [];
    this.sessions = 0;
    this.client_id = null;
    this.target = [];
    this.current_ffd = 'Elephants';
    this.current_ffd_data = this.elephants;
  }
  clearData(){
    this.existing_user = [];
    this.user_exists = false;
  }


  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
  }
  searchQuery: string = '';

  updateFilteredClients() {
    this.showTable = false; // Hide the table when there's a search query
    if (!this.searchQuery) {
        this.filteredClients = [];
        this.showTable = true; // Show the table when there's no search query
    } else {
       
        this.filteredClients = this.clients.filter(client => {
            // Check if any of the required fields are null before performing operations
            const firstName = client.first_name ? client.first_name.toLowerCase() : '';
            const lastName = client.last_name ? client.last_name.toLowerCase() : '';
            const mobile = client.mobile ? client.mobile : '';
            const email = client.email ? client.email.toLowerCase() : '';

            return (
                firstName.includes(this.searchQuery.toLowerCase()) ||
                lastName.includes(this.searchQuery.toLowerCase()) ||
                mobile.includes(this.searchQuery) ||
                email.includes(this.searchQuery.toLowerCase())
            );
        });
    }
}

sendMail(id:any,email:any,mobile:any){
  let formData = new FormData();
  formData.append('user_id',id);
  formData.append('email',email);
  formData.append('mobile',mobile);
  this.ApiService.sendBaselineFormLink(formData).subscribe((data: any) => {
    const response = data.data;
    if (response.success) {
      this.toastr.success(data.data.message);
      this.modalService.dismissAll();
    }
    else {
      this.toastr.error(response.message);
    }
    this.spinner.hide();
  }, (error) => {
    var error_msg: string;
    if (error.response.data.data) {
      error_msg = Object.values(error.response.data.data)[0] as string;
    } else {
      error_msg = error.response.data.message;
    }
    this.toastr.error(error_msg);
    this.spinner.hide();

  });
}
}
