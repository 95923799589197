import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ApiService } from '../../_services/api.service';
import { NgxSpinnerComponent, NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { ConstUrl } from 'src/app/_constant';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css']
})
export class OrganizationComponent {
  orgInfo: any;
  client_details: FormGroup;
  orgs: any;
  orgs_for_clients: any;
  init_orgs: any;
  org: any;
  submitted: any;
  image: any;
  imageURL:string;
  today_date = moment().format(ConstUrl.date_format)
  eighteen_y_before = moment().subtract(18,'years').format(ConstUrl.date_format);

  // ffd 
  blindspots:any=[];
  elephants:any=[];
  action_items:any=[];
  take_aways:any=[];
  current_ffd:string;
  current_ffd_data:any = null;
  sessions:number;
  org_id:string = '1';
  eCounts:any = {total:0,is_done:0,percent:0};
  org_sessions:any = [];
  org_sessions_all_clt:any = [];
  total_sessions:number = 0;
  percent_sess:number = 0;
  completed:number = 0;
  clients:any = [];
  current_org_clients:any = [];
  motus_org_id:string = ConstUrl.motus_org_id;
  delete_org_confirmation = "Deleting this organization entails the permanent removal of associated clients and their data (sessions). Confirm deletion?";
  activate_org_confirmation = "Changing status to 'Active' will regain organization access. Confirm this status change";
  deactivate_org_confirmation = "Changing status to 'Inactive' will restrict this organization's clients' access. Confirm this status change";

  constructor(
    private ApiService: ApiService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private router :Router,
    private activatedRoute :ActivatedRoute,

  ) { 
    //ffd 
    this.current_ffd = 'Elephants';
    this.current_ffd_data = this.elephants;
  }
  public configScroll: PerfectScrollbarConfigInterface = { suppressScrollY: false, wheelPropagation: false };
  public config: PerfectScrollbarConfigInterface = { suppressScrollX : true };

  ngOnInit() {
    this._initCreateOrgForm();
    this._initCreateClientForm();
    this.getClients();
    this.spinner.show();
    // getting all orgs ( here not using a function , directly subscribe the service )
    this.ApiService.getOrganizations('all').subscribe((res) => {
      this.orgs = res.data.data;
      this.orgs_for_clients = this.orgs.filter( (o:any) => ((o.id != this.motus_org_id) && (o.status == 'active')));
      this.init_orgs = this.orgs;
      this.spinner.hide();
    });
   
  }
  get f() {
    return this.orgInfo.controls;
  }
  get client_control() {
    return this.client_details.controls;
  }
  formStatusChange(status:boolean = false){
    this.submitted = status;
  }
  openFormModal(formName) {
    this.modalService.open(formName, { windowClass: 'animated FadeIn', backdrop: 'static'});
  }
  openCreateClientFormModal(formName) {
    this.modalService.open(formName, { windowClass: 'animated FadeIn', backdrop: 'static',size:'lg'});
  }
  openFfdModal(formName) {
    this.modalService.open(formName, { windowClass: 'animated FadeIn', backdrop: 'static',size:'lg'});
  }
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  onFileSelected(e){
    // this.image = e.target.files[0];
    this.image = (event.target as HTMLInputElement).files[0];
    if(!(this.image.size <= ConstUrl.max_image_size)){
      this.toastr.error('Maximum allowed size is 5MB');
      this.image = null;
      (event.target as HTMLInputElement).value = '';
      return ;
    }
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
    }
    reader.readAsDataURL(this.image)
  }
  emptyImage() {
    this.image = '';
    this.imageURL = null;
  }
  returnCorrectDateFormat(dt:any){
    return new DatePipe('en-US').transform(dt, 'yyyy-MM-dd');
  }
  _initCreateOrgForm(){
    this.orgInfo = this.formBuilder.group({
      name:['',Validators.required]
    });
  }
  _initCreateClientForm(){
    this.client_details = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', Validators.required],
      org_id:['1',Validators.required],
      client_type: ['member', Validators.required],
      company_name: [''],
      title: [''],
      division: [''],
      reportees: [''],
      dob: [''],
      work_anniversary: [''],
    });
  }
  redirectOrg(org_id:string){
    localStorage.setItem('org_id',org_id);
    this.router.navigate(['/clients']);
    // this.router.navigate(['/clients'], {
    //                       relativeTo: this.activatedRoute,
    //                       queryParams: {"org_id":org_id} ,
    //                       queryParamsHandling: 'merge', 
    //                     });
  }
  getSessions(org_id:string) {
    // this.spinner.show();
    let params_obj = { org_id: org_id };
    this.ApiService.getClientSession(params_obj).subscribe((response: any) => {
      let data = response.data;
      if (data.success && data.data != null) {
        this.org_sessions = data.data;
        
        // sorting by dateTime | Desc
        // this.org_sessions.sort((a: any, b: any) => {
        //   const datetimeA: any = new Date(a.session_date + 'T' + a.session_time + 'Z');
        //   const datetimeB: any = new Date(b.session_date + 'T' + b.session_time + 'Z');
        //   return datetimeB - datetimeA;
        // })
        // preserving if filtered by client
        this.org_sessions_all_clt = data.data;

        this.total_sessions = this.org_sessions.length;
        this.completed = this.org_sessions.filter((itm: any) => itm.status === 'completed').length;
        this.percent_sess = (this.completed / this.total_sessions) * 100;
      }
    });
  
  }
  getClients() {
    this.spinner.show();
    this.ApiService.getClients().subscribe((response: any) => {
      if (response.data.success) {
        this.clients = response.data.data;
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.toastr.error(response.data.message);
      }
    }, (error) =>{
      var error_msg:string;
      if(error.response.data.data){
        error_msg = Object.values(error.response.data.data)[0] as string; 
      }else{
        error_msg = error.response.data.message;
      }
      this.toastr.error(error_msg);
    });
  }
  filterSessions(event:any){
    let client_id = event.target.value
    // filter session by select client from dropdown 
    if(client_id == 0){
      this.org_sessions = this.org_sessions_all_clt;
    }else{
      this.org_sessions = this.org_sessions_all_clt.filter( (s:any) => (s.client_id == client_id));
    }
  }
  updatedefaultFilter(event) {
    const val = event.target.value.toLowerCase();
    // this.temp = [...this.coachs];
    // filter our data
    const temp = this.init_orgs.filter(function (d:any) {
      return (d.name.toLowerCase().indexOf(val) !== -1) || (!val) || (val === "") || (val === " ");
    });
    // update the rows
    this.orgs = temp;

    // Whenever the filter changes, always go back to the first page
    // this.table.offset = 0;

  }
  redirectFfd(org_id:string){
    // localStorage.setItem('ffd_org_id',org_id);
    // this.router.navigate(['/organizations/ffd']);
    this.clearFfd();
    this.org_id = org_id;
    this.current_org_clients = this.clients.filter( c => c.org_id == org_id);
    this.getSessions(org_id);
    this.getFfds();
  }
  onSubmit() {
    this.spinner.show();
    this.submitted = true;
    if (this.orgInfo.invalid) {
    this.spinner.hide();
      return;
    }
    let formData = new FormData();
    Object.keys(this.orgInfo.value).forEach((itm) => {
      formData.append(itm,this.orgInfo.value[itm]);
    })
    if(this.image){
      formData.append('image',this.image)
    }
    this.ApiService.createOrganization(formData).subscribe((data: any) => {
      const response = data.data;  
      if(response.success) {
        this.toastr.success(data.data.message);
        this.orgInfo.reset()
        this.emptyImage();
        this.submitted = false;
        this.modalService.dismissAll();
        this.ngOnInit();
      }
      else{
        this.toastr.error(data.message);
      }
      this.spinner.hide();


    },(error) =>{
      var error_msg:string;
      if(error.response.data.data){
        error_msg = Object.values(error.response.data.data)[0] as string; 
      }else{
        error_msg = error.response.data.message;
      }
      this.toastr.error(error_msg);
      this.spinner.hide();
    });
  }
  deleteOrg(id: any) {
    let is_confirmed = confirm(this.delete_org_confirmation);
    if (is_confirmed) {
      this.spinner.show();
      this.ApiService.deleteOrganization(id).subscribe(response => {
        if (response.data.success) {
          this.toastr.success(response.data.message);
          this.orgs = this.orgs.filter((c: any) => { return c.id != id })
          this.orgs_for_clients = this.orgs.filter((c: any) => { return c.id != id })
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.toastr.error(response.data.message);
        }
      })
    }
  }
  editOrg(id:any,modalName:any){
  let org = this.orgs.find( (c:any) => { return c.id == id});
  this.org = {...org};
  this.imageURL = this.org.image;
  this.openFormModal(modalName);
  }
  updateOrganization(data:any,id:any){
    this.spinner.show();

    let formData = new FormData();
    formData.append('name',data.name)
    formData.append('status',data.status)

    // Object.keys(data).forEach((itm) => {
    //   formData.append(itm,data[itm]);
    //   console.log('check')
    // })
    if(this.image && this.image !=='null' && this.image !== null){
      formData.append('image',this.image)
    }
   
    this.ApiService.updateOrganization(formData,id).subscribe((data:any) => {
      const response = data.data;  
      if(response.success) {
        this.toastr.success(data.data.message);
        this.org = {};
        this.modalService.dismissAll();
        this.ngOnInit();
        this.image = null;
        this.spinner.hide();
      }
      else{
        this.toastr.error(data.message);
        this.spinner.hide();
      }
    },(error) =>{
      var error_msg:string;
      if(error.response.data.data){
        error_msg = Object.values(error.response.data.data)[0] as string; 
      }else{
        error_msg = error.response.data.message;
      }
      this.toastr.error(error_msg);
      this.spinner.hide();

    });
  }
  updateOrganizationStatus(id: any,event:any) {
    
    let current_org = this.orgs.find((o:any) => o.id === id);
    let is_confirmed:any;

    if(current_org.status == 'inactive'){
      is_confirmed = confirm(this.activate_org_confirmation);
    }else{
      is_confirmed = confirm(this.deactivate_org_confirmation);
    }
    if (is_confirmed) {
      this.spinner.show();
      this.ApiService.updateOrganizationStatus(id).subscribe((response: any) => {
        if (response.data.success) {
          this.spinner.hide();
          current_org.status = (current_org.status == 'active') ? 'inactive' : 'active';
          this.toastr.success(response.data.message);
        } else {
          this.spinner.hide();
          this.toastr.error(response.data.message);
        }
      });
    }else{
      event.target.value = current_org.status;
    }
  }
  createClient() {
    this.submitted = true;
    if (this.client_details.invalid) {
      return;
    }
    if(this.client_details.value.password !== this.client_details.value.password_confirmation){
      this.toastr.error("Password and confirm password not matched");
      return;
    }
    this.spinner.show();
    let formData = new FormData();
    Object.keys(this.client_details.value).forEach((itm) => {
      formData.append(itm, this.client_details.value[itm]);
    })
    formData.append('user_type','client');
    if (this.image && this.image != '') {
      formData.append('image', this.image)
    }
    
    this.ApiService.createUser(formData).subscribe((data: any) => {
      const response = data.data;
      if (response.success) {
        this.toastr.success(data.data.message);
        this.client_details.reset()
        this.image = ''
        this.imageURL = null;
        this.submitted = false;
        this.modalService.dismissAll();
        this.ngOnInit();
      }
      else {
        this.toastr.error(data.message);
      }
      this.spinner.hide();
    }, (error) => {
      var error_msg: string;
      if (error.response.data.data) {
        error_msg = Object.values(error.response.data.data)[0] as string;
      } else {
        error_msg = error.response.data.message;
      }
      this.toastr.error(error_msg);
      this.spinner.hide();
    });
  }
  //----------------ffd
  get blindspotCount(){
    let total:number = this.blindspots.length;
    let is_done:number = this.blindspots.filter( (itm:any) => itm.is_done === '1').length;
    return {
      total:total,
      is_done:is_done,
      percent:(is_done/total)*100,
    }
  }
  get takeAwayCount(){
    let total:number = this.take_aways.length;
    let is_done:number = this.take_aways.filter( (itm:any) => itm.is_done === '1').length;
    return {
      total:total,
      is_done:is_done,
      percent:(is_done/total)*100,
    }
  }
  get actionItemCount(){
    let total:number = this.action_items.length;
    let is_done:number = this.action_items.filter( (itm:any) => itm.is_done === '1').length;
    return {
      total:total,
      is_done:is_done,
      percent:(is_done/total)*100,
    }
  }
  getFfds(){   
    this.spinner.show();
    this.ApiService.getOrgFfds(this.org_id).subscribe((response:any) => {
      let data = response.data.data;
      if(response.data.success && response.data.data != null){
        
        this.sessions = data.session;
        if(data.ffd){
          this.elephants = data.ffd.filter( (itm:any) => itm.ffd_type === 'elephant' );
          this.blindspots = data.ffd.filter( (itm:any) => itm.ffd_type === 'blindspot' );
        }
        if(data.actionItems){
          this.action_items = data.actionItems;
        }
        if(data.takeaways){
          this.take_aways = data.takeaways;
        }
        
        this.eCounts.total = this.elephants.length;
        this.eCounts.is_done = this.elephants.filter( (itm:any) => itm.is_done === '1').length;
        this.eCounts.percent = (this.eCounts.is_done * this.eCounts.total)/100;

      }else{
        this.toastr.error(response.data.message);
      }
      this.spinner.hide();
    });
  }
  clearFfd() {
    this.org_sessions = [];
    this.org_sessions_all_clt = [];
    this.total_sessions = 0;
    this.percent_sess = 0;
    this.completed = 0;
    this.blindspots = [];
    this.elephants = [];
    this.action_items = [];
    this.take_aways = [];
    this.sessions = 0;
    this.org_id = null;
    this.eCounts = { total: 0, is_done: 0, percent: 0 };

    this.current_ffd = 'Elephants';
    this.current_ffd_data = this.elephants;
  }
}
