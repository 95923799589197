import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ApiService } from '../../_services/api.service';
import { NgxSpinnerComponent, NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent {
  admins:object[] = [];
  admin:any;
  admin_info:FormGroup;
  file:any;
  imageURL:string = null;
  submitted = false;

  constructor(
    private ApiService: ApiService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private router :Router,

  ) { }
  public configScroll: PerfectScrollbarConfigInterface = { suppressScrollY: false, wheelPropagation: false };
  public config: PerfectScrollbarConfigInterface = { suppressScrollX : true };
 
  ngOnInit(){
    this.admin_info = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', Validators.required],
      image: [],
      // password: ['', Validators.required],
      // password_confirmation: ['', Validators.required],
    });
    this.getAdmins();
  }
  formStatusChange(status:boolean = false){
    this.submitted = status;
  }
  openForm(popmodel) {
    this.modalService.open(popmodel, { windowClass: 'animated FadeIn', backdrop: 'static'});
  }
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  get f() {
    return this.admin_info.controls;
  }
  onFileSelected(event){
    this.file = (event.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
    }
    reader.readAsDataURL(this.file)
  }
  emptyFile(){
    this.file = '';
    this.imageURL = null;
  }
  getAdmins(){
    this.spinner.show();
    this.ApiService.getAdmins().subscribe( (response:any) => {
      if (response.data.success) {
        this.admins = response.data.data;
      } else {
        this.toastr.error(response.data.message);
      }
      this.spinner.hide();
    })
  }
  adminInfoSubmit() {
    this.submitted = true;
    if (this.admin_info.invalid) {
      return;
    }
    if(this.admin_info.value.password !== this.admin_info.value.password_confirmation){
      this.toastr.error("Password and confirm password not matched");
      return;
    }
    this.spinner.show();
    let formData = new FormData();
    formData.append('email',this.admin_info.value.email);
    formData.append('mobile',this.admin_info.value.mobile);
    formData.append('first_name',this.admin_info.value.first_name);
    formData.append('last_name',this.admin_info.value.last_name);
    formData.append('password',"1234");
    // formData.append('user_type','admin');
    if (this.file) {
      formData.append('image', this.file)
    }
    this.ApiService.createAdmin(formData).subscribe((data: any) => {
      const response = data.data;
      if (response.success) {
        this.toastr.success(data.data.message);
        this.admin_info.reset()
        this.file = ''
        this.submitted = false;
        this.imageURL = null;
        this.modalService.dismissAll();
        this.ngOnInit();
      }
      else {
        this.toastr.error(data.message);
      }
      this.spinner.hide();
    }, (error) => {
      var error_msg: string;
      if (error.response.data.data) {
        error_msg = Object.values(error.response.data.data)[0] as string;
      } else {
        error_msg = error.response.data.message;
      }
      this.toastr.error(error_msg);
      this.spinner.hide();
    });
  }
  editAdmin(id: any, modalName: any) {
    let admin = this.admins.find((c: any) => { return c.id == id });
    this.admin = { ...admin };
    this.imageURL = this.admin.image ;
    this.openForm(modalName);
  }
  updateAdmin(data: any, id: any) {
    this.spinner.show();
    let formData = new FormData();
        formData.append('email',data.email);
        // formData.append('user_type','admin');
        formData.append('first_name',data.first_name);
        formData.append('last_name',data.last_name);
        formData.append('mobile',data.mobile);
        formData.append('status',data.status);
        if(this.file){
          formData.append('image',this.file)
        }else{
          delete data.image;
        }
    this.ApiService.updateAdmin(formData, id).subscribe((data: any) => {
      const response = data.data;
      if (response.success) {
        this.toastr.success(data.data.message);
        this.admin = {};
        this.file = ''
        this.imageURL = null;

        this.modalService.dismissAll();
        this.ngOnInit();
      }
      else {
        this.toastr.error(data.message);
      }
      this.spinner.hide();
    }, (error) => {
      var error_msg: string;
      if (error.response.data.data) {
        error_msg = Object.values(error.response.data.data)[0] as string;
      } else {
        error_msg = error.response.data.message;
      }
      this.toastr.error(error_msg);
      this.spinner.hide();

    });
  }
  updateUserStatus(id:any) {
    this.spinner.show();
    this.ApiService.updateUserStatus(id).subscribe((response: any) => {
      if (response.data.success) {
        this.spinner.hide();
        this.toastr.success(response.data.message);
      } else {
        this.spinner.hide();
        this.toastr.error(response.data.message);
      }
    });
  }
  deleteUser(id:any){
    this.spinner.show();
    this.ApiService.deleteAdmin(id).subscribe( response => {
      if (response.data.success) {
        this.toastr.success(response.data.message);
        this.admins = this.admins.filter( (c:any) => {return c.user_id != id })
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.toastr.error(response.data.message);
      }
    })
  }

}
