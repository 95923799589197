import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import {ToastrService} from 'ngx-toastr';
import { ApiService } from 'src/app/motus/_services/api.service';
import { environment } from 'src/environments/environment';




@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  logo_url = environment.S3_URL + 'motus9/logo.png';
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  isPageLoaded = false;
  mobile_no:any;
  emailmsg:any= ''
  passmsg:any= ''
  output:any=''
  temp_token:string;
  disable_resend_otp:boolean = true;

  constructor( 
    private router: Router,
    private toastr:ToastrService,
    private spinner: NgxSpinnerService,
    private ApiService: ApiService,
    ){}
  
    ngOnInit() {
      if((localStorage.getItem("admin_info")=='') || (localStorage.getItem("admin_info")==null))
      {
        // localStorage.setItem("admin_info",'')
        // this.router.navigateByUrl('/')
      }
      else{
        this.router.navigateByUrl('/coach')
      }
    }
    sendOtp(form_submit_data:any) {

      this.spinner.show();
      if(this.temp_token){
        this.disable_resend_otp = true;
      }
      this.enableResendOtp();
     
      this.ApiService.adminSendOtp({mobile_no:this.mobile_no}).subscribe((response: any) => {
        if(response.data.success){
          this.toastr.success(response.data.message);
          this.temp_token = response.data.data.token;
          
        }else{
          var error_msg:string;
          if(response.data.data){
            error_msg = Object.values(response.data.data)[0] as string; 
          }else{
            error_msg = response.data.message;
          }
          localStorage.clear();
          this.toastr.warning(error_msg);
  
        }

      },(error) => {
        localStorage.clear();
        var error_msg:string;
        if(error.response.data.data){
          error_msg = Object.values(error.response.data.data)[0] as string; 
        }else{
          error_msg = error.response.data.message;
        }
        this.toastr.error(error_msg);
      });
      this.spinner.hide();
    }
    verifyOtp(form_submit_data:any){
      this.spinner.show();
      if(!this.temp_token){
        this.toastr.warning("Please re-send OTP");
        return;
      }
     
      this.ApiService.adminVerifyOtp({token:this.temp_token,otp:form_submit_data.otp}).subscribe((response: any) => {
        if(response.data.success){
          this.toastr.success(response.data.message);
          
          let user = {
            'id':response.data.data.id,
            'name':response.data.data.name,
            'email': response.data.data.email,
            'image': response.data.data.image,
            'mobile': response.data.data.mobile,
            'access_token': response.data.data.access_token,
            'access_level': response.data.data.access_level,
          }
          this.ApiService.setUser(user);
          this.temp_token = null;
          localStorage.setItem('admin_info', JSON.stringify(user));
          this.router.navigateByUrl('/coach');
  
        }else{
          var error_msg:string;
          if(response.data.data){
            error_msg = Object.values(response.data.data)[0] as string; 
          }else{
            error_msg = response.data.message;
          }
          localStorage.clear();
          this.toastr.warning(error_msg);
  
        }
      },(error) => {
        localStorage.clear();
        var error_msg:string;
        if(error.response.data.data){
          error_msg = Object.values(error.response.data.data)[0] as string; 
        }else{
          error_msg = error.response.data.message;
        }
        this.toastr.error(error_msg);
      });
      this.spinner.hide();
    }
  // onSubmit(form_submit_data:any) {
  //   this.spinner.show();
  //   //  #todo - apply frontend validation

  //   this.login_data = {
  //     mobile_no: form_submit_data.mobile_no,
  //     otp: form_submit_data.otp,
  //   };
   
  //   this.ApiService.adminLogin(this.login_data).subscribe((response: any) => {
  //     if(response.data.success){
  //       this.toastr.success(response.data.message);
        
  //       let user = {
  //         'id':response.data.data.id,
  //         'name':response.data.data.name,
  //         'email': response.data.data.email,
  //         'image': response.data.data.image,
  //         'mobile': response.data.data.mobile,
  //         'access_token': response.data.data.access_token,
  //         'access_level': response.data.data.access_level,
  //       }
  //       this.ApiService.setUser(user);

  //       localStorage.setItem('admin_info', JSON.stringify(user));
  //       this.router.navigateByUrl('/coach');

  //     }else{
  //       var error_msg:string;
  //       if(response.data.data){
  //         error_msg = Object.values(response.data.data)[0] as string; 
  //       }else{
  //         error_msg = response.data.message;
  //       }
  //       localStorage.clear();
  //       this.toastr.warning(error_msg);

  //     }
  //   },(error) => {
  //     localStorage.clear();
  //     var error_msg:string;
  //     if(error.response.data.data){
  //       error_msg = Object.values(error.response.data.data)[0] as string; 
  //     }else{
  //       error_msg = error.response.data.message;
  //     }
  //     this.toastr.error(error_msg);
  //   });
  //   this.spinner.hide();
  // }
  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  isTempToken(){
    return (this.temp_token ? true : false);
  }
  enableResendOtp(){
    setTimeout( () => {
      this.disable_resend_otp = false;
    },30000)
  }

}