<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" [fullScreen]="true" type="ball-spin-clockwise">
    <p style="color: white">Loading... </p>
</ngx-spinner>
<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.4.1/css/all.css" integrity="sha384-5sAR7xN1Nv6T6+dT2mhtzEpVJvfS3NScPQTrOxhwjIuvcA67KV2R5Jz6kr4abQsz" crossorigin="anonymous">
<script src='https://kit.fontawesome.com/a076d05399.js' crossorigin='anonymous'></script>
<div class="app-content content">
    <div class="content-wrapper p-0 p-1">
        <div class="content-body">
     
            <section id="configuration" class=" set1408">
                <div class="container p-0 col-12">
                    <div class="card py-1 px-2">
                        <ul class="d-flex flex-wrap align-items-center mb-0 pl-0" style="list-style: none;">
                            <li class="mr-1">
                                <button (click)="downloadSharedResourcesCSV()" type="button" class="btn btn-outline-dark btn-sm">
                                    <i class="la la-cloud-download"></i> Download CSV
                                </button>
                            </li>
                            <li class="">
                                <input style="height: 33px;" type="text" [(ngModel)]="searchQuery" (input)="updateFilteredClients()" placeholder="Search" class="form-control form-control-sm">
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="container custom-container p-0 col-12">
                    <table class="table table-striped custom-table ">
                        <thead>
                            <tr>
                                <!-- Your table headers -->
                                <th>Id</th>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Mobile</th>
                                <th class="email-width" >Email</th>
                                <th>Sign up</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf=(this.filteredClients)>
                            <!-- Iterate over your filtered data -->
                            <tr *ngFor="let row of filteredClients; let i = index">
                                <td>{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
                                <td class="pt-1 pb-1">
                                    <img *ngIf="row.image != null && row.image != 'null'" class="rounded-circle" style="width: 30px; height: 30px;" [src]="row.image" alt="Image">
                                    <img *ngIf="row.image==null || row.image=='null' " class="rounded-circle" src="assets/images/no-image.png" alt="image" style="width: 30px; height: 30px;" />
                                </td>
                                <td>{{ row.first_name }} {{row.last_name}}</td>
                                <td>{{ row.mobile }}</td>
                                <td class="email-width">{{ row.email }}</td>
                                <td>{{ row.sinup_details }}</td>
                                <td>
                                    <button type="button"
                                    [disabled]="row.onboarding_data == null"
                                    (click)="editClient(row.id, OnbordingForm)"
                                    class="btn btn-sm "
                                    style="border: 0px !important; position: relative;" >
                                    <span *ngIf="row.total_sum_value != 0" class="position-absolute  start-0  translate-middle badge bg-info rounded-pill" style="width: 25px;padding: 3px;" >{{row.total_sum_value | number: '1.0-1'}}</span>
                                    <span [style.cursor]="row.onboarding_data == null ? 'not-allowed' : 'pointer'" style="margin-left: 5px;">
                                        <i *ngIf="row.onboarding_data == null" class="la la-file-text text-light font-weight-bold newclass"></i>
                                        <i *ngIf="row.onboarding_data != null" class="la la-file-text font-weight-bold newclass icon-success"></i>
                                        
                                    </span>
                                    <!-- <span *ngIf="row.total_sum_value != 0"  class="position-absolute   translate-middle badge  bg-info">
                                        {{row.total_sum_value | number: '1.0-1'}}
                                        </span> -->
                                </button>
                                    <button type="button"  (click)="copyToClipboard(row.id)" [disabled]="row.onboarding_data == null" class="btn btn-sm "
                                        style="border: 0px !important; position: relative;" > 
                                        <span
                                            [style.cursor]="row.onboarding_data == null ? 'not-allowed' : 'pointer'"
                                        
                                            style="margin-left: 5px;">
                                            <i *ngIf="row.onboarding_data == null" class="la la-copy text-light font-weight-bold newclass"></i>
                                            <i *ngIf="row.onboarding_data != null" class="la la-copy font-weight-bold newclass icon-success"></i>
                                        </span>
                                    </button>
                                    <button type="button" (click)="editClient(row.id,Edit)" class="btn btn-sm"> <i class="feather ft-users font-weight-bold newclass icon-success"> </i> </button>
                                    <button type="button" (click)="editClient(row.id,Add)" class="btn btn-sm"> <i class="feather ft-user font-weight-bold newclass icon-success"> </i> </button>
                                    <button type="button" (click)="deleteUser(row.user_id)" class="btn btn-sm"> <i class="la la-trash text-danger hoverclass"></i>{{row.id}} </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="this.showTable == true" >
                            <!-- Iterate over your data for the current page -->
                            <tr *ngFor="let row of this.clients | slice: (currentPage - 1) * itemsPerPage : currentPage * itemsPerPage; let i = index">
                                <td>{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
                                <td class="pt-1 pb-1">
                                    <img *ngIf="row.image != null && row.image != 'null'" class="rounded-circle" style="width: 30px; height: 30px;" [src]="row.image" alt="Image">
                                    <img *ngIf="row.image==null || row.image=='null' " class="rounded-circle" src="assets/images/no-image.png" alt="image" style="width: 30px; height: 30px;" />
                                </td>
                                <td>{{ row.first_name }} {{row.last_name}}</td>
                                <td>{{ row.mobile }}</td>
                                <td class="email-width">{{ row.email }}</td>
                                <td>{{ row.sinup_details }} </td>
                                <td>
                                    <button type="button"
                                        [disabled]="row.onboarding_data == null"
                                        (click)="editClient(row.id, OnbordingForm)"
                                        class="btn btn-sm "
                                        style="border: 0px !important; position: relative;" >
                                        <span *ngIf="row.total_sum_value != 0" class="position-absolute  start-0  translate-middle badge bg-info rounded-pill" style="width: 25px;padding: 3px;" >{{row.total_sum_value | number: '1.0-1'}}</span>
                                        <span [style.cursor]="row.onboarding_data == null ? 'not-allowed' : 'pointer'" style="margin-left: 5px;">
                                            <i *ngIf="row.onboarding_data == null" class="la la-file-text text-light font-weight-bold newclass"></i>
                                            <i *ngIf="row.onboarding_data != null" class="la la-file-text font-weight-bold newclass icon-success"></i>
                                            
                                        </span>
                                    </button>
                                    <button type="button"  (click)="copyToClipboard(row.id)" [disabled]="row.onboarding_data == null" class="btn btn-sm "
                                    style="border: 0px !important; position: relative;" > 
                                        <span
                                            [style.cursor]="row.onboarding_data == null ? 'not-allowed' : 'pointer'"
                                           
                                            style="margin-left: 5px;">
                                            <i *ngIf="row.onboarding_data == null" class="la la-copy text-light font-weight-bold newclass"></i>
                                            <i *ngIf="row.onboarding_data != null" class="la la-copy font-weight-bold newclass icon-success"></i>
                                        </span>
                                    </button>
                                    
                                    <button type="button" (click)="editClient(row.id,Edit)" class="btn btn-sm"> <i class="feather ft-users font-weight-bold newclass icon-success"> </i> </button>
                                    <button type="button" (click)="editClient(row.id,Add)" class="btn btn-sm"> <i class="feather ft-user font-weight-bold newclass icon-success"> </i> </button>
                                    <button type="button" (click)="deleteUser(row.user_id)" class="btn btn-sm" > <i class="la la-trash text-danger hoverclass"></i> </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- Pagination -->
                     <div  class="row custom-table" style="background: #727e8e;color: #ededed; margin: 0px !important;">
                        <div class="col-6">
                            <h6 class="total-entries text-style" style="color: #ededed">Total Entries: {{ totalItems }}</h6>
                        </div>
                        <div class="col-6" style="justify-content: end;display: grid;">
                            <ngb-pagination
                            [(page)]="currentPage"
                            [pageSize]="itemsPerPage"
                            [collectionSize]="totalItems"
                            (pageChange)="onPageChange($event)"
                            aria-label="Default pagination"
                            class="custom-pagination"
                          ></ngb-pagination>
                        </div>
                       
                       
                    </div>
                    
                 
                  </div>
            </section>
            <section id="form-components">
                <!-- Modal -->
                <ng-template class="modal text-left" #Add let-c="close" let-d="dismiss">
                    <div class="modal-lg">
                        <div class="modal-header">
                            <h6 id="myModalLabel23">Add Coach</h6>
                            <button type="button" class="close" aria-label="Close" (click)="d('Cross click');emptyFile();formStatusChange()">
                          <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="userInfo" (ngSubmit)="onProjectInfoSubmit(client.user_id)" class="m-2">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput1">First Name *</label>
                                                <input type="text" id="projectinput1" class="form-control" formControlName="first_name" placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" maxlength="50" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                                                    <div *ngIf=" f.first_name.errors.required">
                                                        First Name is required</div>
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput2">Last Name *</label>
                                                <input type="text" id="projectinput2" class="form-control" formControlName="last_name" placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" maxlength="50" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                                                    <div *ngIf="f.last_name.errors.required"> Last Name is required</div>
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput6">E-mail *</label>
                                                <input type="text" id="projectinput6" [(ngModel)]="client.email" class="form-control" formControlName="email" placeholder="E-mail" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                    <div *ngIf="f.email.errors.required">Email is required</div>
                                                    <div *ngIf="f.email.errors.email">Email must  be a valid email address</div>
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput4">Mobile No. *</label>
                                                <input type="text" id="projectinput4" [(ngModel)]="client.mobile" class="form-control" formControlName="mobile" placeholder="Mobile No." [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" (keypress)="keyPress($event)" maxlength="12" minlength="10" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                                    <div *ngIf="f.mobile.errors.required">Mobile No. is required</div>
                                                    <div *ngIf="f.mobile.errors.minlength">Mobile No. must be 10 digits long</div>
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4 d-flex  align-items-center">
                                            <div>
                                                <input type="checkbox" id="as_admin" formControlName="as_admin" /> &nbsp;
                                                <label for="as_admin">Coach as admin</label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <label>Upload Image</label>
                                            <label id="projectinput7" class="file center-block">
                                                <input type="file" id="file" style="margin-left: 3px;"  (change)="onFileSelected($event)" accept="image/*">
                                                <span class="file-custom"></span>
                                            </label>
                                            <div class="col-4" *ngIf="imageURL && imageURL !== ''">
                                                <img [src]="imageURL" [alt]="userInfo.value.name" class="img-fluid mb-2 rounded">
                                            </div>
                                            <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.image.errors" class="invalid-feedback">
                                                <div *ngIf="f.image.errors.required">Image
                                                is required</div>
                                            </small> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal');emptyFile();formStatusChange()">Close</button>
                                    <button type="submit" class="btn btn-outline-primary">Submit</button>
                                </div>
                                <!-- <button type="submit" class="btn btn-primary">
                                <i class="la la-check"></i> Submit
                              </button> -->
                            </form>
                        </div>
                    </div>
                </ng-template>
                <ng-template class="modal text-left" #Edit let-c="close" let-d="dismiss">
                    <div class="modal-lg">
                        <div class="modal-header">
                            <h6 id="myModalLabel23">Client</h6>
                            <button type="button" class="close" aria-label="Close" (click)="d('Cross click');emptyFile();formStatusChange()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form #edit_client_form="ngForm" (ngSubmit)="moveToValid(client,client.user_id)" class="m-2">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput1">First Name *</label>
                                                <input type="text" id="projectinput1" class="form-control" placeholder="First Name" [(ngModel)]="client.first_name" name="first_name" maxlength="50" />
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput2">Last Name *</label>
                                                <input type="text" id="projectinput2" class="form-control" placeholder="Last Name" [(ngModel)]="client.last_name" name="last_name" maxlength="50" />
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput6">E-mail *</label>
                                                <input type="text" id="projectinput6" class="form-control" placeholder="E-mail" [(ngModel)]="client.email" name="email" />
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput4">Mobile No. *</label>
                                                <input type="text" id="projectinput4" class="form-control" placeholder="Mobile No." (keypress)="keyPress($event)" [(ngModel)]="client.mobile" name="mobile" maxlength="12" />
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group ">
                                                <label for="projectinput5">Client Type*</label>
                                                <select id="projectinput5" class="form-control" name="client_type" [ngClass]="{ 'is-invalid': submitted && f.client_type.errors }" [(ngModel)]="client.client_type">
                                                    <option disabled>--Select--</option>
                                                    <option value="member">Member</option>
                                                    <option value="owner" selected>Owner</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group ">
                                                <label for="projectinput5">Organization*</label>
                                                <select id="projectinput5" class="form-control" name="org_id" [(ngModel)]="client.org_id">
                                                    <option disabled>--Select--</option>
                                                    <option *ngFor="let o of organizations" [value]="o.id">{{o.name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="title">Title</label>
                                                <input type="text" id="title" class="form-control" placeholder="Title" [(ngModel)]="client.title" name="title" maxlength="50" />
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="division">Department</label>
                                                <input type="text" id="division" class="form-control" name="division" placeholder="Department" [(ngModel)]="client.division" maxlength="50" />
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="reportees">Reportees</label>
                                                <input type="text" id="reportees" class="form-control" placeholder="Reportees" [(ngModel)]="client.reportees" name="reportees" maxlength="50" />
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="dob">Date of birth</label>
                                                <input type="date" id="dob" class="form-control" placeholder="Date of birth" [(ngModel)]="client.dob" name="dob" [max]="eighteen_y_before" />
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="work_anniversary">Work anniversary</label>
                                                <input type="date" id="work_anniversary" class="form-control" placeholder="Work anniversary" [(ngModel)]="client.work_anniversary" name="work_anniversary" [min]="today_date" />

                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group ">
                                                <label for="projectinput5">Status*</label>
                                                <select id="projectinput5" class="form-control" name="status" [(ngModel)]="client.status" [disabled]="client.access_level == 'admin' ">
                                                    <option disabled>--Select--</option>
                                                    <option value="active">Active</option>
                                                    <option value="inactive">Inactive</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <label>Upload Image</label>
                                            <label id="projectinput7" class="file center-block">
                                                <input type="file" id="file" style="margin-left: 3px;"  (change)="onFileSelected($event)" accept="image/*">
                                                <span class="file-custom"></span>
                                            </label>
                                            <div class="col-4" *ngIf="imageURL && (imageURL !== '') && (imageURL !== 'null')">
                                                <img [src]="imageURL" alt="client" class="img-fluid mb-2 rounded">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal');emptyFile();formStatusChange()">Close</button>
                                    <button type="submit" class="btn btn-outline-primary">Move</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>
                <ng-template class="modal text-left" #OnbordingForm let-c="close" let-d="dismiss">
                    <div class="modal-lg">
                        <div class="modal-header">
                            <h2  style="margin-left: 45px;" id="myModalLabel23">Assessment Form</h2>
                            <button style="background-color: white; border: 0px;cursor: pointer; outline: none;" aria-label="Close" 
                            (click)="d('Cross click');emptyFile();formStatusChange()">
                                <span style="font-size: 35px;" aria-hidden="true">&times;</span>
                            </button>
                            <!-- <button type="button" class="close" aria-label="Close" (click)="d('Cross click');emptyFile();formStatusChange()">
                                <span  style="font-size: 30px;"  aria-hidden="true">&times;</span>
                            </button> -->
                        </div>
                        <div class="modal-body mr-3 ml-3">
                            <div class="modal-body mr-3 ml-3">
                                <form class="form-horizontal form-simple" #question_form="ngForm" (ngSubmit)="onSubmit(question_form)">
                                    <div *ngFor="let key of this.questionAnsAnswer;let i = index" class="form-group position-relative  border-bottom ">
                                        <div  class="form-group position-relative mb-0">
                                            <label class="text-style">Q{{i + 1}}. {{ key.question }}</label> 
                                        </div>
                                         <span *ngFor="let ans of key.answer">
                                            <label ><span style="font-weight: bold;">A. </span>{{ans }}</label><br>
                                        </span>
                                    </div>
                                    <br>
                                </form>
                            </div>
                            <!-- <form class="form-horizontal form-simple" #question_form="ngForm">
                                <div class="form-group position-relative mb-0 border-bottom mt-1 ">
                                    <label class="text-style">1. {{this.onbording_data[0].question}}</label>
                                    <br><label class="">  {{this.onbording_data[0].answer}}</label>
                                </div>
                                <div class="form-group position-relative mb-0 border-bottom mt-1">
                                    <label class="text-style">2. {{this.onbording_data[1].question}}</label>
                                    <br><label class="">  {{this.onbording_data[1].answer}}</label>
                                </div>
                                <div class="form-group position-relative mb-0 border-bottom mt-1">
                                    <label class="text-style">3.  {{this.onbording_data[2].question}}</label>
                                    <br><label class="">  {{this.onbording_data[2].answer}}</label>
                                </div>
                                <div class="form-group position-relative mb-0 border-bottom mt-1">
                                    <label class="text-style">4.  {{this.onbording_data[3].question}}</label>
                                    <br><label class="">  {{this.onbording_data[3].answer}}</label>
                                </div>
                                <div class="form-group position-relative mb-0 mt-1">
                                    <label class="text-style">5. {{this.onbording_data[4].question}}</label>
                                    <br><label class="">  {{this.onbording_data[4].answer}}</label>
                                </div>
                            </form> -->
                            
                        </div>
                    </div>
                </ng-template>

            </section>
        </div>
    </div>
</div>