import { Component, Input, } from '@angular/core';
import { ApiService } from '../../_services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import {  Renderer2, ElementRef, ViewChild } from '@angular/core';
// import { mobile_image } from '../../../../assets/iphone.png';
@Component({
  selector: 'app-mobile-app',
  templateUrl: './mobile-app.component.html',
  styleUrls: ['./mobile-app.component.css']
})
export class MobileAppComponent {
  logo_url = environment.S3_URL + 'motus9/logo.png';
  apple = 'assets/apple_store.png';
  isMac: boolean;
  isWindows: boolean;
  isAndroid: boolean;
  isIOS: boolean;
  ios: boolean;

  constructor(
    private renderer: Renderer2,
    private ApiService: ApiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute, 
    private activatedRoute: ActivatedRoute,
    private router :Router,
  ) {}

  ngOnInit() {
    this.spinner.hide();
    // this.spinner.show();
    var userAgent = window.navigator.userAgent;
   
    if(userAgent){
      // this.ios = /(iPhone|iPad|iPod).* OS 9_\d/.test(userAgent) && !/Version\/9\./.test(userAgent);
      this.ios = /IOS/.test(userAgent);
      this.isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      this.isMac = /Macintosh/.test(userAgent);
      this.isWindows = /Windows/.test(userAgent);
      this.isAndroid = /Android/.test(userAgent);

      if (this.ios || this.isIOS || this.isMac) {
        window.location.href = 'https://apps.apple.com/in/app/motus9/id6470996494';
      } else if (this.isWindows || this.isAndroid) {
        // Redirect to Windows Store URL or appropriate link
        window.location.href = 'https://play.google.com/store/apps/details?id=com.motus.android';
      }else{
        this.spinner.hide();
      }
    }else{
      this.spinner.hide();
    }
    
  }

}
