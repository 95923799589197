import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent {
  
  logo_url = environment.S3_URL + 'motus9/logo.png';
 
  constructor(
  ) {}

  ngOnInit() {
    
  }
  

}
