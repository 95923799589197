
<section class="remove-p" style="height: 100vh;display: flex;align-items: center;">
    <!-- <div class="container"> -->
        <div class="row" style="display: flex; justify-content: center;">
            <div class="col-md-6" style="display: flex; justify-content: center;">
                <img class="witth-set" [src]="logo_url" style="width: 40%;border-radius: 12px;" alt="branding logo">
            </div>
            <div class="col-md-6" style="margin: auto; color: white;">
                <h1  style="color: white;    font-family: inherit;  font-weight: bold; margin-bottom: 0px !important;">Welcome To Motus9</h1>
               
                <h3  style="color: white; font-family: inherit;margin-bottom: 0px !important;">Download The App</h3>
                <a href="https://apps.apple.com/in/app/motus9/id6470996494"><img src="assets/apple.png" style="width: 43%; height: 50%;margin: auto;" alt="apple"></a>
                <a href="https://play.google.com/store/apps/details?id=com.motus.android"><img src="assets/google_play.png"  style="width: 50%; height: 50%;margin: auto;" alt="android"></a>
            </div>
        </div>
    <!-- </div> -->
    
</section>