<div class="app-content content">
  <div class="content-wrapper">
    <div class="content-header row mb-1">
    </div>
    <div class="content-body">
      <section class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-head">
              <div class="card-header">
                <h4 class="card-title">Invoice List</h4>
              </div>
            </div>
            <div class="card-content">
              <div class="card-body">
                <!-- Invoices List table -->
                <div class="table-responsive">
                  <table id="invoices-list"
                    class="table table-white-space table-bordered row-grouping display no-wrap icheck table-middle">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Invoice #</th>
                        <th>Order No</th>
                        <th>Booking Date</th>
                        <th>Client Name</th>
                        <th>Booking For</th>
                        <th>Student Name</th>
                        <th>Booking Type</th>
                        <th>Session Date</th>
                        <th>Session Time</th>
                        <th>Amount</th>
                        <th>Gross Amount</th>
                        <th>Discount Amount</th>
                        <th>Net Amount</th>
                        <th>GST</th>
                        <th>Balance Due</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let invoice of INVOICE; index as i">
                        <td>{{ invoice.date }} </td>
                        <td>
                          <button type="button" class="btn mr-1 mb-1 btn-secondary btn-sm" [routerLink]="['/invoice/invoice-view',1]">
                              <i class="feather ft-eye"></i>&nbsp; View</button>
                        </td>
                        <td>{{ invoice.invoice }}</td>
                        <td>{{ invoice.orderno }}</td>
                        <td>{{ invoice.customername }}</td>
                        <td>{{ invoice.due }}</td>
                        <td>{{ invoice.amount }}</td>
                        <td>{{ invoice.balancedue }}</td>
                        <td>{{ invoice.date }} </td>
                        <td>{{ invoice.invoice }}</td>
                        <td>{{ invoice.orderno }}</td>
                        <td>{{ invoice.customername }}</td>
                        <td>{{ invoice.due }}</td>
                        <td>{{ invoice.amount }}</td>
                        <td>{{ invoice.balancedue }}</td>
                        <td>{{ invoice.amount }}</td>
                        <td>{{ invoice.balancedue }}</td>
                        
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--/ Invoices table -->
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>


<!-- <div class="app-content content">
    <div class="content-wrapper">
      <div class="content-header row mb-1">
        
      </div>
      <div class="content-body">
        <section class="row" id="printMe">
          <div class="col-12 printMe" *blockUI="'printButtons'; message: 'Loading'">
            <m-card [options]="options" (reloadFunction)="reloadPrintButtons($event)">
            <div class="card">
              <div class="card-head">
                <div class="card-header">
                  <h4 class="card-title">Invoices List</h4>
                </div>
              </div>
              <div class="card-content">
                <div class="card-body">
                  <div class="table-responsive">
                    <table id="invoices-list"
                      class="table table-white-space table-bordered row-grouping display no-wrap icheck table-middle">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Invoice #</th>
                          <th>Order No</th>
                          <th>Booking Date</th>
                          <th>Client Name</th>
                          <th>Booking For</th>
                          <th>Student Name</th>
                          <th>Booking Type</th>
                          <th>Session Date</th>
                          <th>Session Time</th>
                          <th>Amount</th>
                          <th>Gross Amount</th>
                          <th>Discount Amount</th>
                          <th>Net Amount</th>
                          <th>GST</th>
                          <th>Balance Due</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let invoice of INVOICE; index as i">
                          <td>{{ invoice.date }} </td>
                          <td>{{ invoice.invoice }}</td>
                          <td>{{ invoice.orderno }}</td>
                          <td>{{ invoice.customername }}</td>
                          <td>{{ invoice.due }}</td>
                          <td>{{ invoice.amount }}</td>
                          <td>{{ invoice.balancedue }}</td>
                          <td>{{ invoice.date }} </td>
                          <td>{{ invoice.invoice }}</td>
                          <td>{{ invoice.orderno }}</td>
                          <td>{{ invoice.customername }}</td>
                          <td>{{ invoice.due }}</td>
                          <td>{{ invoice.amount }}</td>
                          <td>{{ invoice.balancedue }}</td>
                          <td>{{ invoice.amount }}</td>
                          <td>{{ invoice.balancedue }}</td>
                          <td>
                            <button type="button" class="btn mr-1 mb-1 btn-secondary btn-sm" [routerLink]="['/invoice/invoice-view',1]">
                                <i class="feather ft-eye"></i>&nbsp; View</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
        </m-card>
          </div>
        </section>
      </div>
    </div>
  </div>
   -->