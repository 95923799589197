import { Component } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent {
  @BlockUI('printButtons') blockUIPrintButtons: NgBlockUI;
  data: any;
  INVOICE: any;
  INVOICE1: any;
  INVOICE2: any;
  INVOICE3: any;
  INVOICE4: any;
  getTabledata() {
   this.INVOICE = this.data['invoice'];
   this.INVOICE1 = this.data['invoice1'];
  this.INVOICE2 = this.data['invoice2'];
  this.INVOICE3 = this.data['invoice3'];
  this.INVOICE4 = this.data['invoice4'];
  }
  getPrint(printME) {
    // const printContents = document.getElementById(printME).innerHTML;
    // const originalContents = document.body.innerHTML;
    // document.body.innerHTML = printContents;
    // window.print();
    // document.body.innerHTML = originalContents;
  }

  ngOnInit() {
   
    {
      this.INVOICE=[
        { "date": "06/05/2017",
          "invoice": "INV-001001",
          "orderno": "PO-005201",
          "customername": "Elizabeth Washington",
          "due": "10/05/2017",
          "amount": "$ 1200.00",
          "balancedue": "$ 200.00",
          "actions": ""
        },
        {
          "date": "16/07/2017",
          "invoice": "INV-001012",
          "orderno": "PO- 001201",
          "customername": "Andrew Davis",
          "due": "20/07/2017",
          "amount": "$ 152.00",
          "balancedue": "$ 100.00",
          "actions": ""
        },
        {
          "date": "12/11/2017",
          "invoice": "INV-001401",
          "orderno": "PO-14561",
          "customername": "Megan Stephens",
          "due": "16/11/2017",
          "amount": "$ 1450.00",
          "balancedue": "$ 50.00",
          "actions": ""
        },
        {
          "date": "15/05/2017",
          "invoice": "INV-001201",
          "orderno": "PO-015201",
          "customername": "Judith Carlson",
          "due": "20/05/2017",
          "amount": "$ 1340.00",
          "balancedue": "$ 150.00",
          "actions": ""
        },
        {
          "date": "18/06/2017",
          "invoice": "INV-005801",
          "orderno": "PO-002201",
          "customername": "Harry Banks",
          "due": "23/06/2017",
          "amount": "$ 560.00",
          "balancedue": "$20.00",
          "actions": ""
        },
        {
          "date": "18/06/2017",
          "invoice": "INV-005801",
          "orderno": "PO-002201",
          "customername": "Harry Banks",
          "due": "23/06/2017",
          "amount": "$ 560.00",
          "balancedue": "$20.00",
          "actions": ""
        }
      ],
      this.INVOICE3=[
        { "date": "06/05/2017",
          "invoice": "INV-001001",
          "orderno": "PO-005201",
          "customername": "Elizabeth Washington",
          "due": "10/05/2017",
          "amount": "$ 1200.00",
          "balancedue": "$ 200.00",
          "actions": ""
        },
        {
          "date": "16/07/2017",
          "invoice": "INV-001012",
          "orderno": "PO- 001201",
          "customername": "Andrew Davis",
          "due": "20/07/2017",
          "amount": "$ 152.00",
          "balancedue": "$ 100.00",
          "actions": ""
        },
        {
          "date": "12/11/2017",
          "invoice": "INV-001401",
          "orderno": "PO-14561",
          "customername": "Megan Stephens",
          "due": "16/11/2017",
          "amount": "$ 1450.00",
          "balancedue": "$ 50.00",
          "actions": ""
        },
        {
          "date": "15/05/2017",
          "invoice": "INV-001201",
          "orderno": "PO-015201",
          "customername": "Judith Carlson",
          "due": "20/05/2017",
          "amount": "$ 1340.00",
          "balancedue": "$ 150.00",
          "actions": ""
        },
        {
          "date": "18/06/2017",
          "invoice": "INV-005801",
          "orderno": "PO-002201",
          "customername": "Harry Banks",
          "due": "23/06/2017",
          "amount": "$ 560.00",
          "balancedue": "$20.00",
          "actions": ""
        }
       
      ],
      this.INVOICE4=[
       
       
        {
          "date": "16/07/2017",
          "invoice": "INV-001012",
          "orderno": "PO- 001201",
          "customername": "Andrew Davis",
          "due": "20/07/2017",
          "amount": "$ 152.00",
          "balancedue": "$ 100.00",
          "actions": ""
        },
        {
          "date": "15/05/2017",
          "invoice": "INV-001201",
          "orderno": "PO-015201",
          "customername": "Judith Carlson",
          "due": "20/05/2017",
          "amount": "$ 1340.00",
          "balancedue": "$ 150.00",
          "actions": ""
        },
        {
          "date": "18/06/2017",
          "invoice": "INV-005801",
          "orderno": "PO-002201",
          "customername": "Harry Banks",
          "due": "23/06/2017",
          "amount": "$ 560.00",
          "balancedue": "$20.00",
          "actions": ""
        } 
      ]
      }
  }
  reloadPrintButtons() {
    this.blockUIPrintButtons.start('Loading..');

    setTimeout(() => {
      this.blockUIPrintButtons.stop();
    }, 2500);
  }
}
