<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" [fullScreen]="true" type="ball-spin-clockwise">
    <p style="color: white">Loading... </p>
</ngx-spinner>
<div class="app-content content">
    <div class="content-wrapper p-1">
        <div class="content-header">
        </div>
        <div class="content-body m-0 p-0">
            <div class="row">
                <div class="col-xl-5">
                    <section  id="configuration">
                        <!-- <div class="p-0"> -->
                            <div class="card profile-card-with-cover">
                                <div class="card-img-top img-fluid bg-cover navbar-dark">
                                    <h2 class="text-center mt-1 " style="color:white;"> Reports</h2>
                                </div>
                                <div class="profile-card-with-cover-content p-0">
                                    <div class="card-body p-0">
                                        <!-- <ul> -->
                                        <form class="form" (ngSubmit)="onSubmit()">
                                            <div class="modal-body">
                                                <div class="form-group ">
                                                    <label style="font-size: 16px;" for="dropdown">Select a report:  </label>
                                                    <select style="width:100%;" id="dropdown" class="form-control" name="dropdown" [(ngModel)]="selectedOption">
                                                            <option  disabled >--Select--</option>
                                                            <option  *ngFor="let option of orgination" [value]="option">{{ option }}</option>
                                                        </select>
                                                </div>
                                            </div>
                                            <!-- other form fields go here -->
                                            <div class="modal-footer">
                                                <button type="submit" class="btn btn-outline-dark btn-min-width box-shadow-1 mr-1">Export</button>
                                            </div>
                                            <!-- <button type="submit">Submit</button> -->
                                        </form>

                                        <!-- </ul> -->
                                    </div>
                                </div>
                            </div>
                        <!-- </div> -->
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="app-content content">
    <div class="content-wrapper p-0 pt-1">

        <div class="content-body p-2">
            <section id="configuration">
                <div class="row">
                    <div class="col-4" *blockUI="'zeroConfiguration'; message: 'Loading'">
                        <m-card [options]="options" (reloadFunction)="reloadZeroConfiguration($event)">
                            <ng-container mCardHeaderTitle>
                            </ng-container>
                            <ng-container mCardBody>
                                <div class="card-dashboard">
                                    <p class="card-text">Reports</p>
                                    <div style="overflow-x: scroll !important; scroll-behavior: smooth !important; width: 50%; ">
                                    </div>
                                    
                                </div>
                            </ng-container>
                        </m-card>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div> -->