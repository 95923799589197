<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" [fullScreen]="true" type="ball-spin-clockwise">
    <p style="color: white">Loading... </p>
</ngx-spinner>
<div class="app-content content">
    <div class="sidebar-left " id="sidebar-left">
        <div class="sidebar" style="width: auto !important;">
            <!-- app chat user profile left sidebar start -->
            <!-- <div class="chat-user-profile" id="user-profile">
                <header class="chat-user-profile-header text-center border-bottom">
                    <span class="chat-profile-close" (click)="showProfile($event)">
                        <i class="ficon feather ft-x"></i>
                    </span>
                    <div class="my-2">

                        <img src="../../../assets/images/portrait/small/avatar-s-19.png" class="round mb-1"
                            alt="user_avatar" height="100" width="100">

                        <h5 class="mb-0">John Doe</h5>
                        <span>Designer</span>
                    </div>
                </header>
                <div class="chat-user-profile-content">
                    <div class="chat-user-profile-scroll" fxFlex="auto" [perfectScrollbar]="config">
                        <h6 class="text-uppercase mb-1">ABOUT</h6>
                        <p class="mb-2">It is a long established fact that a reader will be distracted by the readable
                            content .</p>
                        <h6>PERSONAL INFORAMTION</h6>
                        <ul class="list-unstyled mb-2">
                            <li class="mb-25">email@gmail.com</li>
                            <li>+1(789) 950 -7654</li>
                        </ul>
                        <h6 class="text-uppercase mb-1">CHANNELS</h6>
                        <ul class="list-unstyled mb-2">
                            <li><a href=""># Devlopers</a></li>
                            <li><a href=""># Designers</a></li>
                        </ul>
                        <h6 class="text-uppercase mb-1">SETTINGS</h6>
                        <ul class="list-unstyled">
                            <li class="mb-50 "><a href="" class="d-flex align-items-center"><i
                                        class="ficon feather ft-tag mr-50"></i>
                                    Add
                                    Tag</a></li>
                            <li class="mb-50 "><a href="" class="d-flex align-items-center"><i
                                        class="ficon feather ft-star mr-50"></i>
                                    Important Contact</a>
                            </li>
                            <li class="mb-50 "><a href="" class="d-flex align-items-center"><i
                                        class="ficon feather ft-image mr-50"></i>
                                    Shared
                                    Documents</a></li>
                            <li class="mb-50 "><a href="" class="d-flex align-items-center"><i
                                        class="ficon feather ft-trash-2 mr-50"></i>
                                    Deleted
                                    Documents</a></li>
                            <li><a href="" class="d-flex align-items-center"><i
                                        class="ficon feather ft-x-circle mr-50"></i> Blocked
                                    Contact</a></li>
                        </ul>
                    </div>
                </div>
            </div> -->
            <!-- app chat user profile left sidebar ends -->
            <!-- app chat sidebar start -->
            <div class="chat-sidebar card chat-sidebad-display-hide" style="transform: none !important; transition: all 0.3s ease-in-out; z-index: 5;margin: 0px !important;width: 365px !important;  left: 0px !important; ">
                <!-- <span class="chat-sidebar-close" (click)="Sidebar($event)">
                    <i class="ficon feather ft-x"></i>
                </span> -->
                <div class="chat-sidebar-search">
                    <div class="d-flex align-items-center">
                        <div class="chat-sidebar-profile-toggle">
                            <div class="col-sm-3">
                                <div class="dataTables_filter">
                                    <input type='text' style=' width: 316px; height: 34px;border-radius: .22rem; border: 1px solid #BABFC7;' placeholder='Search' (keyup)='updatedefaultFilter($event)' class="search-input-small" />
                                </div>
                            </div>
                            <!-- <div class="avatar">
                                <h3 class="">User List</h3>
                            </div> -->
                        </div>
                        <!-- <fieldset class="form-group position-relative has-icon-left mx-75 mb-0">
                            <input type="text" class="form-control round" id="chat-search" style="height:32px;"
                                (keyup)="updateFilter($event)" (keyup)="updateFilter1($event)" placeholder="Search">
                            <div class="form-control-position" style="margin-top:-6px;">
                                <i class="ficon feather ft-search text-dark"></i>
                            </div>
                        </fieldset> -->
                    </div>
                </div>
                <div class="chat-sidebar-list-wrapper"  style="top: 0px; height: 988px; margin-top: 50px;border-top: 1px solid #E4E5EC;width: auto !important;">
                    <ul *ngIf="this.chatss.length == 0" class="chat-sidebar-list" #scrollBottom>
                        <li class="chatSidebar" *ngFor="let chat of chats" (click)="getMsgs(chat.id,chat.chat_image,chat.chat_first_name,chat.chat_last_name);scrollDown()" [ngClass]="(chat.id === conversation_id) ? 'active': ''">

                            <div class="d-flex align-items-center">
                                <div class="avatar m-0 mr-50">
                                    <img *ngIf="chat.chat_image" class="rounded-circle" src="{{chat.chat_image}}" alt="image" style="width: 40px;height: 40px;" />
                                    <!-- <img *ngIf="!chat.chat_image" src="assets/images/no-image.png" alt="avatar" style="width: 40px;height: 40px;"> -->
                                    <img *ngIf="!chat.chat_image" src="assets/images/no-image1.png" alt="avatar" style="width: 40px;height: 40px;">

                                    <span class="avatar-status-busy"></span>

                                </div>
                                <div class="chat-sidebar-name">
                                    <h6 class="mb-0">
                                        {{chat.chat_first_name|titlecase}} {{chat.chat_last_name|titlecase}}
                                    </h6>
                                    <!-- <span class="text-muted">6</span> -->
                                </div>
                                <span class="badge badge-pill  float-right ml-auto" style="color: black;" *ngIf="chat.last_message && chat.unseen_count == 0">{{chat.last_message.day}}</span>
                                <span class="badge badge-pill bg-danger float-right ml  ml-auto" *ngIf="chat.unseen_count != 0">{{chat.unseen_count}}</span>

                                <!-- <div class="chat-sidebar-name">
                                    <h6 class="mb-0">{{chats.name}}</h6><span
                                        class="text-muted">{{chats.showMessage}}</span>
                                </div> -->
                            </div>

                        </li>

                    </ul>
                    <ul *ngIf="this.chatss" class="chat-sidebar-list" #scrollBottom>
                        <li class="chatSidebar" *ngFor="let chat of chatss" (click)="getMsgs(chat.id,chat.chat_image,chat.chat_first_name,chat.chat_last_name)" [ngClass]="(chat.id === conversation_id) ? 'active': ''">

                            <div class="d-flex align-items-center">
                                <div class="avatar m-0 mr-50">
                                    <img *ngIf="chat.chat_image" class="rounded-circle" src="{{chat.chat_image}}" alt="image" style="width: 40px;height: 40px;" />
                                    <!-- <img *ngIf="!chat.chat_image" src="assets/images/no-image.png" alt="avatar" style="width: 40px;height: 40px;"> -->
                                    <img *ngIf="!chat.chat_image" src="assets/images/no-image1.png" alt="avatar" style="width: 40px;height: 40px;">

                                    <span class="avatar-status-busy"></span>

                                </div>
                                <div class="chat-sidebar-name">
                                    <h6 class="mb-0">
                                        {{chat.chat_first_name|titlecase}} {{chat.chat_last_name|titlecase}}
                                    </h6>
                                    <!-- <span class="text-muted">6</span> -->
                                </div>
                                <span class="badge badge-pill  float-right ml-auto" style="color: black;" *ngIf="chat.last_message && chat.unseen_count == 0">{{chat.last_message.day}}</span>
                                <span class="badge badge-pill bg-danger float-right ml  ml-auto" *ngIf="chat.unseen_count != 0">{{chat.unseen_count}}</span>
                                <!-- <span class="badge badge-pill float-right ml-auto" style="color: black;" *ngIf="chat.last_message" >{{chat.last_message.day}}</span>
                                 <span class="badge badge-pill bg-danger float-right ml-auto" *ngIf="chat.unseen_count != 0">{{chat.unseen_count}}</span> -->
                                <!-- <span class="badge badge-pill bg-danger float-right  ml-auto" *ngIf="chat.unseen_count != 0">{{chat.unseen_count}}</span> -->

                                <!-- <div class="chat-sidebar-name">
                                    <h6 class="mb-0">{{chats.name}}</h6><span
                                        class="text-muted">{{chats.showMessage}}</span>
                                </div> -->
                            </div>

                        </li>

                    </ul>
                    <!-- <h6 class="px-2 pt-2 pb-25 mb-0">CONTACTS<i
                            class="ficon feather ft-plus float-right cursor-pointer"></i></h6>
                    <ul class="chat-sidebar-list">
                        <li class="chatSidebar" *ngFor="let chat_data of chats"  (click)="getMsgs(chat_data.id,chat_data.image,chat_data.first_name,chat_data.last_name)">
                            <div class="d-flex align-items-center">
                                <div class="avatar m-0 mr-50"><img [src]="chat_data.image">
                                    <span class="avatar-status-away"></span>
                                </div>
                                <div class="chat-sidebar-name">
                                    <h6 class="mb-0">{{chat_data.first_name|titlecase}}  {{chat_data.last_name|titlecase}}</h6>
                                    <span class="text-muted"> {{contact.showMessage}}</span>
                                </div>
                            </div>
                        </li>
                    </ul> -->
                </div>
            </div>
            <!-- app chat sidebar ends -->

        </div>
    </div>
    <div class="content-margin-left" style="margin-left: 299px;" >
        <div class="content-overlay"></div>
        <div class="content-wrapper p-0">
            <div class="content-header row">
            </div>
            <div class="content-body">
                <!-- app chat overlay -->
                <div class="chat-overlay chat-chat-overlay"></div>
                <!-- app chat window start -->
                <!-- <section class="chat-window-wrapper" style=" margin-left: 100px;"> -->
                    <section class="chat-window-wrapper chat-sidebar-margin-left" >
                    <div class="chat-start" *ngIf="chatStarter" style="margin-left: -30px;">
                     
                        <span class="ficon feather ft-message-square chat-sidebar-toggle chat-start-icon font-large-3 p-3 mb-1"></span>
                        <h4 class=" d-lg-block py-50 text-bold-500">Select a user to start a chat!</h4>
                        <button class="btn btn-light-primary chat-start-text chat-sidebar-toggle d-block d-lg-none py-50 px-1">Start
                            Conversation!</button>
                            <button class="btn btn-outline-secondary py-50 px-1 button-hide" (click)="openFormModal(Add)">Users list</button>
                    </div>
                    <div class="chat-area" style="margin-left: -30px;">
                        <div class="chat-header" *ngIf="visibility" style="padding-left: 13px;">
                            <header class="d-flex justify-content-between align-items-center px-1 py-75">
                                <div class="d-flex align-items-center" style="width: 100% !important; ">
                                    <div class="chat-sidebar-toggle d-block d-lg-none mr-1">
                                        <!-- <i class="ficon feather ft-align-justify font-large-1 cursor-pointer"></i> -->
                                    </div>
                                    <div class="avatar chat-profile-toggle m-0 mr-1">
                                        <span>
                                            <img *ngIf="image!=null" class="rounded-circle" src="{{image}}" alt="image"
                                            style="width: 40px;height: 40px;"  />
                                            <img *ngIf="image==null" src="assets/images/no-image1.png" alt="avatar" style="width: 40px;height: 40px;">
                                        </span>
                                        <span class="avatar-status-busy"></span>
                                    </div>
                                    <h6 class="mb-0">{{first_name|titlecase}} {{last_name|titlecase}}</h6>
                                    <!-- <div > -->
                                        <i class="feather ft-users button-hide" style="margin-left: auto" (click)="openFormModal(Add)"></i>
                                    <!-- </div> -->
                                </div>
                                <!-- <div class="chat-header-icons">

                                    <span class="chat-icon-favorite">
                                        <i class="ficon feather ft-star font-medium-5 cursor-pointer"></i>
                                    </span>
                                    <span ngbDropdown class="d-inline-block dropdown">
                                        <i class="ficon feather ft-more-vertical font-medium-4 ml-25 cursor-pointer dropdown-toggle nav-hide-arrow cursor-pointer"
                                            id="dropdownMenuButton" dropdown-menu dropdown-menu-left show
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            role="menu" ngbDropdownToggle>
                                        </i>
                                        <div ngbDropdownMenu="dropdownMenuButton">
                                            <a class="dropdown-item">Pin to top</a>
                                            <a class="dropdown-item">Delete chat</a>
                                            <a class="dropdown-item ">Block</a>
                                        </div>
                                    </span>
                                </div> -->
                            </header>
                        </div>
                        <!-- chat card start -->
                        <div class="card chat-wrapper shadow-none mb-0  " *ngIf="chat_window_show">
                            <div class="card-content">
                                <div class="card-body chat-container " style="overflow-y: scroll;" id="test" fxFlex="auto">
                                    <div class="chat-content">
                                        <div *ngFor="let single_msg of chat_msgs;let indexOfelement=index;">
                                            <div class="text-center">
                                                <span class="text-center my-1" *ngIf="single_msg.show_date" style="color: #6b6f82;"> ---- {{single_msg.show_date }} ----</span>
                                            </div>
                                            <div class="chat mt-1" [ngClass]="{'chat-right': single_msg.sender === null , 'chat-left': single_msg.sender !== null }">
                                                <!-- <div class="chat-avatar"  [ngClass]="{'chat-avatar-left': single_msg.msg_user_type === 'admin' }">
                                                <a [routerLink]="" class="avatar m-0 mt-1">
                                                    <img [src]="single_msg.image" />
                                                    <img *ngIf="!single_msg.image" src="assets/images/no-image.png" alt="avatar">
                                                </a>
                                            </div> -->

                                                <br>
                                                <div class="chat-body">
                                                    <div class="chat-message" [ngClass]="{'chat-left-message': single_msg.sender !== null }">

                                                        <div *ngIf="single_msg.msg_type == 'text'">
                                                            <p class="mb-0" *ngIf="!single_msg.is_link">{{single_msg.msg}}</p>

                                                            <a class="mb-0" *ngIf="single_msg.is_link" [href]="single_msg.msg" target="_blank">{{single_msg.msg}}</a>
                                                        </div>

                                                        <div *ngIf="single_msg.msg_type == 'image'">
                                                            <img [src]="single_msg.msg" alt="message" style="width: 150px;height: auto;">
                                                        </div>
                                                        <div *ngIf="single_msg.msg_type == 'pdf' ">
                                                            <a [href]="single_msg.msg" alt="pdf" target="_blank" style="color:black">
                                                                <i class="feather ft-external-link"> </i> {{single_msg.pdf_name}}</a>
                                                        </div>
                                                        <span *ngIf="single_msg.sender !== null" class="chat-time1">{{single_msg.msg_time | datetime }} </span>
                                                        <span *ngIf="single_msg.sender == null" class="chat-time">{{single_msg.msg_time | datetime }} </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="card-body chat-container" id="test" fxFlex="auto">
                                    <div class="chat-content">
                                        <div class="chat" >
                                            <div class="chat-avatar">
                                                <a  class="avatar m-0">
                                                    <img src="../../../assets/images/portrait/small/avatar-s-19.png" >
                                                </a>
                                            </div>
                                            <div class="chat-body">
                                                <div class="chat-message">
                                                    <p class="mb-0">HIII</p>
                                                    <span class="chat-time">12.06Pm</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div> -->
                            </div>
                            <div class="card-footer chat-footer px-2 py-1 pb-0" >
                                <form class="d-flex align-items-center">
                                    <!-- <i class="ficon feather ft-user cursor-pointer"></i>
                                     <i class="ficon feather ft-paperclip ml-1 cursor-pointer"></i> -->
                                    <label class="cursor-pointer">
                                        <i class="ficon feather ft-paperclip ml-1 "></i><input type="file" id="importFile" (change)="onFileSelected($event)" accept="image/*, application/pdf">
                                     </label>

                                    <input type="text" class="form-control chat-message-send mx-1" name="newmessage" [(ngModel)]="message_text" placeholder="Type your message here...." maxlength="100" *ngIf="text_input_visibilty">
                                    <label class="form-control chat-message-send mx-1 mb-0" *ngIf="imageURL && imageURL !== ''">
                                            <img [src]="imageURL" 
                                              class="img-fluid rounded" style="width: auto; height: 100%;" />
                                          </label>
                                    <button type="submit" class="btn btn-primary d-lg-flex" (click)="sendMessage()"><i class="ficon feather ft-play"></i>
                                        <span class="d-lg-block mx-50">Send</span></button>
                                </form>
                            </div>
                        </div>
                        <!-- chat card ends -->
                    </div>
                </section>
                <!-- <section class="chat-profile " id="chat-profile">
                    <header class="chat-profile-header text-center border-bottom">
                        <span class="chat-profile-close" (click)="ChatProfile($event)">
                            <i class="ficon feather ft-x"></i>
                        </span>
                        <div class="my-2" *ngFor="let chats of headerChat">

                            <img [src]="chats.headerImage" class="round mb-1" alt="chat avatar" height="100"
                                width="100">

                            <h5 class="app-chat-user-name mb-0">{{chats.senderName}}</h5>
                            <span>Devloper</span>
                        </div>
                    </header>
                    <div class="chat-profile-content p-2">
                        <h6 class="mt-1">ABOUT</h6>
                        <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                        <h6 class="mt-2">PERSONAL INFORMATION</h6>
                        <ul class="list-unstyled">
                            <li class="mb-25">email@gmail.com</li>
                            <li>+1(789) 950-7654</li>
                        </ul>
                    </div>
                </section> -->
                <!-- app chat window ends -->
            </div>
        </div>
    </div>
    <ng-template class="modal text-left" #Add let-c="close" let-d="dismiss">
        <div class="modal-lg">
            <div class="modal-header">
                <h6 id="myModalLabel23">Users</h6>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click');">
              <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body p-0">
                <div class="chat-sidebar card" style="transform: none !important; transition: all 0.3s ease-in-out; z-index: 5;margin: 0px !important;width: auto !important;  left: 0px !important; position: relative; ">
                    <!-- <span class="chat-sidebar-close" (click)="Sidebar($event)">
                        <i class="ficon feather ft-x"></i>
                    </span> -->
                    <div class="chat-sidebar-search p-1" style="padding-top: 8px !important; padding: 0px;width: 100% !important;">
                        <div class="d-flex align-items-center" style="width: 100%;">
                            <div class="chat-sidebar-profile-toggle" style="width: 100%;">
                                <!-- <div class="col-sm-3"> -->
                                    <div class="dataTables_filter" style="width: 100%;">
                                        <input type='text' style=' width: 100%; height: 34px;border-radius: .22rem; border: 1px solid #BABFC7;' placeholder='Search' (keyup)='updatedefaultFilter($event)' class="search-input-small" />
                                    </div>
                                <!-- </div> -->
                                <!-- <div class="avatar">
                                    <h3 class="">User List</h3>
                                </div> -->
                            </div>
                            <!-- <fieldset class="form-group position-relative has-icon-left mx-75 mb-0">
                                <input type="text" class="form-control round" id="chat-search" style="height:32px;"
                                    (keyup)="updateFilter($event)" (keyup)="updateFilter1($event)" placeholder="Search">
                                <div class="form-control-position" style="margin-top:-6px;">
                                    <i class="ficon feather ft-search text-dark"></i>
                                </div>
                            </fieldset> -->
                        </div>
                    </div>
                    <div class="chat-sidebar-list-wrapper"  style="top: 0px; height: 642px; margin-top: 50px;border-top: 1px solid #E4E5EC;width: auto !important;overflow: scroll;">
                        <ul *ngIf="this.chatss.length == 0" class="chat-sidebar-list" >
                            <li class="chatSidebar" *ngFor="let chat of chats" (click)="getMsgs(chat.id,chat.chat_image,chat.chat_first_name,chat.chat_last_name);d('Cross click');" [ngClass]="(chat.id === conversation_id) ? 'active': ''">
    
                                <div class="d-flex align-items-center" style="gap: 5px;">
                                    <div class="avatar m-0 mr-50">
                                        <img *ngIf="chat.chat_image" class="rounded-circle" src="{{chat.chat_image}}" alt="image" style="width: 40px;height: 40px;" />
                                        <!-- <img *ngIf="!chat.chat_image" src="assets/images/no-image.png" alt="avatar" style="width: 40px;height: 40px;"> -->
                                        <img *ngIf="!chat.chat_image" src="assets/images/no-image1.png" alt="avatar" style="width: 40px;height: 40px;">
    
                                        <span class="avatar-status-busy"></span>
    
                                    </div>
                                    <div class="chat-sidebar-name">
                                        <h6 class="mb-0">
                                            {{chat.chat_first_name|titlecase}} {{chat.chat_last_name|titlecase}}
                                        </h6>
                                        <!-- <span class="text-muted">6</span> -->
                                    </div>
                                    <span class="badge badge-pill  float-right ml-auto" style="color: black;" *ngIf="chat.last_message && chat.unseen_count == 0">{{chat.last_message.day}}</span>
                                    <span class="badge badge-pill bg-danger float-right ml  ml-auto" *ngIf="chat.unseen_count != 0">{{chat.unseen_count}}</span>
    
                                    <!-- <div class="chat-sidebar-name">
                                        <h6 class="mb-0">{{chats.name}}</h6><span
                                            class="text-muted">{{chats.showMessage}}</span>
                                    </div> -->
                                </div>
    
                            </li>
    
                        </ul>
                        <ul *ngIf="this.chatss" class="chat-sidebar-list" >
                            <li class="chatSidebar" *ngFor="let chat of chatss" (click)="getMsgs(chat.id,chat.chat_image,chat.chat_first_name,chat.chat_last_name);d('Cross click');" [ngClass]="(chat.id === conversation_id) ? 'active': ''">
    
                                <div class="d-flex align-items-center" style="gap: 5px;">
                                    <div class="avatar m-0 mr-50">
                                        <img *ngIf="chat.chat_image" class="rounded-circle" src="{{chat.chat_image}}" alt="image" style="width: 40px;height: 40px;" />
                                        <!-- <img *ngIf="!chat.chat_image" src="assets/images/no-image.png" alt="avatar" style="width: 40px;height: 40px;"> -->
                                        <img *ngIf="!chat.chat_image" src="assets/images/no-image1.png" alt="avatar" style="width: 40px;height: 40px;">
    
                                        <span class="avatar-status-busy"></span>
    
                                    </div>
                                    <div class="chat-sidebar-name">
                                        <h6 class="mb-0">
                                            {{chat.chat_first_name|titlecase}} {{chat.chat_last_name|titlecase}}
                                        </h6>
                                        <!-- <span class="text-muted">6</span> -->
                                    </div>
                                    <span class="badge badge-pill  float-right ml-auto" style="color: black;" *ngIf="chat.last_message && chat.unseen_count == 0">{{chat.last_message.day}}</span>
                                    <span class="badge badge-pill bg-danger float-right ml  ml-auto" *ngIf="chat.unseen_count != 0">{{chat.unseen_count}}</span>
                                    <!-- <span class="badge badge-pill float-right ml-auto" style="color: black;" *ngIf="chat.last_message" >{{chat.last_message.day}}</span>
                                     <span class="badge badge-pill bg-danger float-right ml-auto" *ngIf="chat.unseen_count != 0">{{chat.unseen_count}}</span> -->
                                    <!-- <span class="badge badge-pill bg-danger float-right  ml-auto" *ngIf="chat.unseen_count != 0">{{chat.unseen_count}}</span> -->
    
                                    <!-- <div class="chat-sidebar-name">
                                        <h6 class="mb-0">{{chats.name}}</h6><span
                                            class="text-muted">{{chats.showMessage}}</span>
                                    </div> -->
                                </div>
    
                            </li>
    
                        </ul>
                        <!-- <h6 class="px-2 pt-2 pb-25 mb-0">CONTACTS<i
                                class="ficon feather ft-plus float-right cursor-pointer"></i></h6>
                        <ul class="chat-sidebar-list">
                            <li class="chatSidebar" *ngFor="let chat_data of chats"  (click)="getMsgs(chat_data.id,chat_data.image,chat_data.first_name,chat_data.last_name)">
                                <div class="d-flex align-items-center">
                                    <div class="avatar m-0 mr-50"><img [src]="chat_data.image">
                                        <span class="avatar-status-away"></span>
                                    </div>
                                    <div class="chat-sidebar-name">
                                        <h6 class="mb-0">{{chat_data.first_name|titlecase}}  {{chat_data.last_name|titlecase}}</h6>
                                        <span class="text-muted"> {{contact.showMessage}}</span>
                                    </div>
                                </div>
                            </li>
                        </ul> -->
                    </div>
                </div>
            </div>

        </div>
    </ng-template>
</div>
<!-- END: Content-->

<!-- app chat profile right sidebar ends -->