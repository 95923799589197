// WARNING - Values under "firebase" and value of "googleApiKey" needs to be replaced from your own accounts
// If left as is, it firbase and google map related functionality will not work on LIVE instance.
// motus_dev
export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyCSG-EGU2RLITgpjf_ogf1w47edC_DXlWc',
  authDomain: 'evolvision-rnd.firebaseapp.com',
  databaseURL: 'https://evolvision-rnd.firebaseio.com',
  projectId: 'evolvision-rnd',
  storageBucket: 'evolvision-rnd.appspot.com',
  messagingSenderId: '890895206035',
  appId: '1:890895206035:web:a6d0e1574d59ffe40bef66',
  measurementId: 'G-WYKKLCYFHE'
  },
  googleApiKey: 'AIzaSyAIIYOxA7qeetFz6TuR1Qewc0Rrjhzx7ZU',

  admin_server: 'https://app.motus9.com/motus_prod_api/',
  SOCKET_ENDPOINT: 'https://app.motus9.com/',
  SOCKET_NAMESPACE: '/motus_prod.io/',
  S3_URL: 'https://' + "motus9" + '.s3.' + "us-east-1" + '.amazonaws.com/',

};