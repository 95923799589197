<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" [fullScreen]="true" type="ball-spin-clockwise">
    <p style="color: white">Loading... </p>
</ngx-spinner>

<div class="app-content content">
    <div class="content-wrapper p-0 p-1">

        <div class="content-body p-0">
            <section id="configuration">
              <div class="card py-1 px-2">
                <ul class="d-flex flex-wrap align-items-center mb-0 pl-0" style="list-style: none;">
                    <li class="mr-1 li-min-width" >
                        <!-- <div class="form-group my-auto"> -->
                            <div style="height: 33px;" class="custom-file" style="font-size: 1rem;">
                                <input  type="file" class="custom-file-input" id="importFile" (change)="onFileSelected($event,'csv')" accept=".csv">
                                <label style="    height: 34px !important;  margin-top: 4px;" class="custom-file-label mb-0" for="inputGroupFile01">Upload CSV</label>
                            </div>
                        <!-- </div> -->
                    </li>
                    <li class="mr-1 li-min-width" >
                        <button  style="height: 33px;"  type="button" class="btn btn-outline-dark  btn-sm width-full" (click)="importCoaches()">Import</button>
                    </li>
                    <li class="mr-1 li-min-width" >
                        <button  style="height: 33px;"  type="button" class="btn btn-outline-dark  btn-sm width-full" (click)="openCreateForm(Add)"> Add Coach</button>
                    </li>
                    <li class="mr-1 li-min-width" >
                        <a  style="height: 33px;"   [href]="coaches_excel_sample"  class="btn btn-outline-dark btn-sm width-full">
                            <!-- <button type="button" class="btn btn-outline-dark btn-sm"> -->
                            <i class="la la-cloud-download"></i> Sample Coaches
                            <!-- </button> -->
                        </a>           
                    </li>
                    <li class="mr-1 li-min-width" >
                        <div class="dataTables_filter" style="text-align: left !important;">
                            <input  style="height: 33px; width: 100%;"  type="text"  placeholder="Search" (keyup)='updatedefaultFilter($event)'>
                        </div>
                    </li>
                </ul>
            </div>
                <div class="row">
                    <div class="col-12" *blockUI="'zeroConfiguration'; message: 'Loading'">
                        <m-card [options]="options" (reloadFunction)="reloadZeroConfiguration($event)">
                            <ng-container mCardHeaderTitle>
                                <!-- <div class="row ">
                                <div class="col-sm-4">
                                  <div class="form-group" >
                                    <div class="custom-file">
                                      <input type="file" class="custom-file-input"  id="importFile"
                                      (change)="onFileSelected($event)" accept=".csv">
                                      <label class="custom-file-label"for="inputGroupFile01">Upload CSV</label>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-2">
                                  <button type="button" class="btn grey btn-outline-secondary" (click)="importCoaches()">Import</button>
                                </div>
                                <div class="col-sm-6 text-end">
                                  <button type="button" class="btn btn-outline-dark btn-min-width box-shadow-1 mr-1 mb-1"  (click)="openCreateForm(Add)">Add Coach</button>
                                </div>
                              </div> -->
                            </ng-container>
                            <ng-container mCardBody>
                                <div class="card-dashboard">
                                    <!-- <p class="card-text">DataTables</p> -->
                                    <div style="overflow-x: scroll !important; scroll-behavior: smooth !important; width: 100%; ">
                                      <ngx-datatable class="bootstrap table-bordered width-fit-content " [limit]="50" [rows]="coachs" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="50"     >
                                        <ngx-datatable-column name="Id" [sortable]="false" [flexGrow]="1" [minWidth]="30" [maxWidth]="40">
                                            <ng-template ngx-datatable-cell-template let-value="value" let-rowIndex="rowIndex" let-row="row">
                                                {{rowIndex + 1}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Image" [sortable]="false" [flexGrow]="1" [minWidth]="90" [maxWidth]="110" >
                                            <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                                <img *ngIf="row.image!=null && row.image != 'null' " class="rounded-circle" src="{{row.image}}" alt="image" style="width: 30px; height: 30px;" />
                                                <img *ngIf="row.image==null || row.image=='null' " class="rounded-circle" src="assets/images/no-image.png" alt="image" style="width: 30px; height: 30px;" />
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Name" [draggable]="true" [sortable]="true" [flexGrow]="1" prop="first_name" [flexGrow]="1" [minWidth]="200" [maxWidth]="230">
                                            <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                                {{row.first_name + ' ' +row.last_name| titlecase}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Mobile" [flexGrow]="1" [minWidth]="fit-content">
                                            <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                                {{row.mobile}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Email" [flexGrow]="1" [minWidth]="220">
                                            <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                                {{row.email}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <!-- <ngx-datatable-column name="Company Name" [flexGrow]="1" [minWidth]="90">
                                            <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                                {{row.company_name}}
                                            </ng-template>
                                        </ngx-datatable-column> -->
                                        <ngx-datatable-column name="Last Login" [draggable]="true" [sortable]="true" [flexGrow]="1" prop="last_login" [minWidth]="200" [minWidth]="210">
                                            <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                                {{row.last_login | datetime:'DD-MMM-YY'}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Status" [sortable]="false" [flexGrow]="1" [minWidth]="90">
                                            <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                                <!-- <div class="badge badge-success" *ngIf="row.status == 'active'">Active</div>
                                              <div class="badge badge-danger" *ngIf="row.status == 'inactive'">Inactive</div> -->
                                                <select [value]="row.status" (change)="updateUserStatus(row.user_id)" [disabled]="row.access_level == 'admin' " [id]="'status_'+row.id">
                                                <option value="active">Active</option>
                                                <option value="inactive">Inactive</option>
                                              </select>

                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Action" [sortable]="false" [flexGrow]="1" [minWidth]="90">
                                            <ng-template ngx-datatable-cell-template let-value="value" let-row="row">

                                                <i (click)="editCoach(row.id,Edit)" class="la la-pencil-square-o text-success hoverclass mr-1" aria-hidden="true"></i>
                                                <i class="la la-trash text-light " aria-hidden="true" *ngIf="row.access_level == 'admin'"></i>

                                                <i (click)="deleteUser(row.user_id)" class="la la-trash text-danger hoverclass" aria-hidden="true" *ngIf="row.access_level != 'admin'"></i>
                                            </ng-template>
                                        </ngx-datatable-column>
                                    </ngx-datatable></div>
                                  
                                </div>
                            </ng-container>
                        </m-card>
                    </div>
                </div>
            </section>
            <section id="form-components">

                <!-- Modal -->
                <ng-template class="modal text-left" #Add let-c="close" let-d="dismiss">
                    <div class="modal-lg">
                        <div class="modal-header">
                            <h6 id="myModalLabel23">Add Coach</h6>
                            <button type="button" class="close" aria-label="Close" (click)="d('Cross click');emptyFile();formStatusChange()">
                          <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="userInfo" (ngSubmit)="onProjectInfoSubmit()" class="m-2">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput1">First Name *</label>
                                                <input type="text" id="projectinput1" class="form-control" formControlName="first_name" placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" maxlength="50" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                                      <div *ngIf=" f.first_name.errors.required">
                                        First Name is required</div>
                                    </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput2">Last Name *</label>
                                                <input type="text" id="projectinput2" class="form-control" formControlName="last_name" placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" maxlength="50" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                                      <div *ngIf="f.last_name.errors.required">
                                        Last Name is required</div>
                                    </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput6">E-mail *</label>
                                                <input type="text" id="projectinput6" class="form-control" formControlName="email" placeholder="E-mail" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                      <div *ngIf="f.email.errors.required">Email
                                        is required</div>
                                      <div *ngIf="f.email.errors.email">Email must
                                        be a valid email address</div>
                                    </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput4">Mobile No. *</label>
                                                <input type="text" id="projectinput4" class="form-control" formControlName="mobile" placeholder="Mobile No." [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" (keypress)="keyPress($event)" maxlength="12" minlength="10" />
                                                <small class="form-text text-muted danger" *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                      <div *ngIf="f.mobile.errors.required">Mobile No.
                                        is required</div>
                                      <div *ngIf="f.mobile.errors.minlength">Mobile No. must be 10 digits long</div>
                                    </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4 d-flex  align-items-center">
                                            <div>
                                                <input type="checkbox" id="as_admin" formControlName="as_admin" /> &nbsp;
                                                <label for="as_admin">Coach as admin</label>

                                            </div>

                                        </div>
                                        <!-- <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="projectinput3">Password *</label>
                                    <input type="password" id="projectinput3" class="form-control" formControlName="password"
                                      placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.password.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.password.errors.required">Password
                                        is required</div>
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="projectinput3">Confirm Password *</label>
                                    <input type="password" id="projectinput3" class="form-control" formControlName="password_confirmation"
                                      placeholder="Confirm Password " [ngClass]="{ 'is-invalid': submitted && f.password_confirmation.errors }" />
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.password_confirmation.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.password_confirmation.errors.required">Password Confirmation
                                        is required</div>
                                    </small>
                                  </div>
                                </div> -->
                                        <!-- <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="projectinput6">Company Name *</label>
                                    <input type="text" id="company_name" class="form-control" formControlName="company_name"
                                      placeholder="Company Name" [ngClass]="{ 'is-invalid': submitted && f.company_name.errors }" />
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.company_name.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.company_name.errors.required">Company Name
                                        is required</div>
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="title">Title *</label>
                                    <input type="text" id="title" class="form-control" formControlName="title"
                                      placeholder="Title" [ngClass]="{ 'is-invalid': submitted && f.title.errors }"
                                       />
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.title.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.title.errors.required">Title
                                        is required</div>
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="division">Division *</label>
                                    <input type="text" id="division" class="form-control" formControlName="division"
                                      placeholder="Division" [ngClass]="{ 'is-invalid': submitted && f.division.errors }" />
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.division.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.division.errors.required">Division
                                        is required</div>
                  
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="reportees">Reportees *</label>
                                    <input type="text" id="reportees" class="form-control" formControlName="reportees"
                                      placeholder="Reportees" [ngClass]="{ 'is-invalid': submitted && f.reportees.errors }"
                                      />
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.reportees.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.reportees.errors.required">Reportees
                                        is required</div>
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="dob">Date of birth *</label>
                                    <input type="date" id="dob" class="form-control" formControlName="dob"
                                      placeholder="Date of birth" [ngClass]="{ 'is-invalid': submitted && f.dob.errors }" [max]="today_date"/>
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.dob.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.dob.errors.required">Date of birth
                                        is required</div>
                  
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="work_anniversary">Work anniversary *</label>
                                    <input type="date" id="work_anniversary" class="form-control" formControlName="work_anniversary"
                                      placeholder="Work anniversary" [ngClass]="{ 'is-invalid': submitted && f.work_anniversary.errors }"
                                      />
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.work_anniversary.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.work_anniversary.errors.required">Work anniversary
                                        is required</div>
                                    </small>
                                  </div>
                                </div> -->
                                        <div class="col-md-4">
                                            <label>Upload Image</label>
                                            <label id="projectinput7" class="file center-block">
                                    <input type="file" id="file" style="margin-left: 3px;"
                                      (change)="onFileSelected($event)" accept="image/*">
                                    <span class="file-custom"></span>
                                  </label>
                                            <div class="col-4" *ngIf="imageURL && imageURL !== ''">
                                                <img [src]="imageURL" [alt]="userInfo.value.name" class="img-fluid mb-2 rounded">
                                            </div>
                                            <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.image.errors" class="invalid-feedback">
                                    <div *ngIf="f.image.errors.required">Image
                                      is required</div>
                                  </small> -->
                                        </div>
                                    </div>


                                </div>

                                <div class="modal-footer">
                                    <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal');emptyFile();formStatusChange()">Close</button>
                                    <button type="submit" class="btn btn-outline-primary">Submit</button>
                                </div>
                                <!-- <button type="submit" class="btn btn-primary">
                                <i class="la la-check"></i> Submit
                              </button> -->

                            </form>
                        </div>

                    </div>
                </ng-template>

                <ng-template class="modal text-left" #Edit let-c="close" let-d="dismiss">
                    <div class="modal-lg">
                        <div class="modal-header">
                            <h6 id="myModalLabel23">Edit Coach</h6>
                            <button type="button" class="close" aria-label="Close" (click)="d('Cross click');emptyFile();formStatusChange()">
                          <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                            <form #edit_coach_form="ngForm" (ngSubmit)="updateCoach(coach,coach.user_id)" class="m-2">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput1">First Name *</label>
                                                <input type="text" id="projectinput1" class="form-control" placeholder="First Name" [(ngModel)]="coach.first_name" name="first_name" maxlength="50" />
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.first_name.errors"
                                      class="invalid-feedback">
                                      <div *ngIf=" f.first_name.errors.required">
                                        First Name is required</div>
                                    </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput2">Last Name *</label>
                                                <input type="text" id="projectinput2" class="form-control" placeholder="Last Name" [(ngModel)]="coach.last_name" name="last_name" maxlength="50" />
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.last_name.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.last_name.errors.required">
                                        Last Name is required</div>
                                    </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput6">E-mail *</label>
                                                <input type="text" id="projectinput6" class="form-control" placeholder="E-mail" [(ngModel)]="coach.email" name="email" />
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.email.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.email.errors.required">Email
                                        is required</div>
                                      <div *ngIf="f.email.errors.email">Email must
                                        be a valid email address</div>
                                    </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="projectinput4">Mobile No. *</label>
                                                <input type="text" id="projectinput4" class="form-control" placeholder="Mobile No." (keypress)="keyPress($event)" [(ngModel)]="coach.mobile" name="mobile" maxlength="12" />
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.mobile.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.mobile.errors.required">Mobile No.
                                        is required</div>
                                    </small> -->
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="projectinput3">Password *</label>
                                    <input type="password" id="projectinput3" class="form-control" formControlName="password"
                                      placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.password.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.password.errors.required">Password
                                        is required</div>
                                    </small>
                                  </div>
                                </div> 
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="projectinput6">Company Name *</label>
                                    <input type="text" id="company_name" class="form-control" 
                                      placeholder="Company Name" [ngClass]="{ 'is-invalid': submitted && f.company_name.errors }" [(ngModel)]="coach.company_name" name="company_name"/>
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.company_name.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.company_name.errors.required">Company Name
                                        is required</div>
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="title">Title *</label>
                                    <input type="text" id="title" class="form-control" 
                                      placeholder="Title" [ngClass]="{ 'is-invalid': submitted && f.title.errors }"
                                      [(ngModel)]="coach.title" name="title"/>
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.title.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.title.errors.required">Title
                                        is required</div>
                                    </small>
                                  </div>
                                </div>-->
                                        <div class="col-md-4">
                                            <div class="form-group ">
                                                <label for="projectinput5">Status*</label>
                                                <select id="projectinput5" class="form-control" name="status" [(ngModel)]="coach.status" [disabled]="coach.access_level == 'admin' ">
                                      <option disabled>--Select--</option>
                                      <option value="active">Active</option>
                                      <option value="inactive">Inactive</option>
                                    </select>
                                                <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.status.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.status.errors.required">
                                        Status is required</div>
                                    </small> -->
                                            </div>
                                        </div>
                                        <div class="col-md-4"> </div>
                                        <div class="col-md-4 d-flex  align-items-center">
                                            <div>
                                                <input type="checkbox" id="as_admin" [(ngModel)]="coach.as_admin" name="as_admin" [disabled]="coach.access_level == 'admin' " /> &nbsp;
                                                <label for="as_admin" style="font-weight: 700;">Coach as admin</label>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="division">Division *</label>
                                    <input type="text" id="division" class="form-control" name="division"
                                      placeholder="Division" [ngClass]="{ 'is-invalid': submitted && f.division.errors }" [(ngModel)]="coach.division"/>
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.division.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.division.errors.required">Division
                                        is required</div>
                  
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="reportees">Reportees *</label>
                                    <input type="text" id="reportees" class="form-control" 
                                      placeholder="Reportees" [ngClass]="{ 'is-invalid': submitted && f.reportees.errors }"
                                      [(ngModel)]="coach.reportees" name="reportees"/>
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.reportees.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.reportees.errors.required">Reportees
                                        is required</div>
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="dob">Date of birth *</label>
                                    <input type="date" id="dob" class="form-control" 
                                      placeholder="Date of birth" [ngClass]="{ 'is-invalid': submitted && f.dob.errors }" [(ngModel)]="coach.dob" name="dob"[max]="today_date"/>
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.dob.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.dob.errors.required">Date of birth
                                        is required</div>
                  
                                    </small>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="work_anniversary">Work anniversary *</label>
                                    <input type="date" id="work_anniversary" class="form-control" 
                                      placeholder="Work anniversary" [ngClass]="{ 'is-invalid': submitted && f.work_anniversary.errors }"
                                      [(ngModel)]="coach.work_anniversary" name="work_anniversary"/>
                                    <small class="form-text text-muted danger" *ngIf="submitted && f.work_anniversary.errors"
                                      class="invalid-feedback">
                                      <div *ngIf="f.work_anniversary.errors.required">Work anniversary
                                        is required</div>
                                    </small>
                                  </div>
                                </div> -->
                                    </div>
                                    <div class="row col-md-4 mt-1">
                                        <label class="pl-0">Upload Image</label>
                                        <label id="projectinput7" class="file center-block pl-0">
                                  <input type="file" id="file" 
                                    [ngClass]="{ 'is-invalid': submitted && f.image.errors }" style="margin-left: 3px;"
                                    (change)="onFileSelected($event)" accept="image/*">
                                  <span class="file-custom"></span>
                                </label>
                                        <div class="col-4 pl-0" *ngIf="imageURL && (imageURL !== '') && (imageURL !== 'null')">
                                            <img [src]="imageURL" alt="coach" class="img-fluid mb-2 rounded">
                                        </div>
                                        <!-- <small class="form-text text-muted danger" *ngIf="submitted && f.image.errors" class="invalid-feedback">
                                  <div *ngIf="f.image.errors.required">Image
                                    is required</div>
                                </small> -->
                                    </div>


                                </div>

                                <div class="modal-footer">
                                    <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal');emptyFile();formStatusChange()">Close</button>
                                    <button type="submit" class="btn btn-outline-primary">Submit</button>
                                </div>
                                <!-- <button type="submit" class="btn btn-primary">
                                <i class="la la-check"></i> Submit
                              </button> -->

                            </form>
                        </div>

                    </div>
                </ng-template>
            </section>
        </div>
    </div>
</div>