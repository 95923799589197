import { NgModule } from "@angular/core";
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
} from "@angular/platform-browser";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import {
  NgbModule,
  NgbCarouselConfig,
  NgbModalConfig
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AlertComponent } from './_helpers/alert.component';
import { AlertService } from './_services/alert.service';
import { ChartApiService } from './_services/chart.api';
import { TableApiService } from './_services/table-api.service';
import { ApplicationApiService } from './_services/application-api.service';
import { QuillInitializeServiceService } from './_services/quill-initialize-service.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { CalendarModule } from 'angular-calendar';
import { CalendarMonthModule } from 'angular-calendar';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { DatePipe } from "@angular/common";
// Routing
import { routing } from "./app.routing";
// Components
import { AppComponent } from "./app.component";
import { SettingsModule } from "./_layout/settings/settings.module";
import { ThemeSettingsConfig } from "./_layout/settings/theme-settings.config";
import { MenuSettingsConfig } from "./_layout/settings/menu-settings.config";
import { HeaderComponent } from "./_layout/header/header.component";
import { VerticalComponent as HeaderVerticalComponent } from "./_layout/header/vertical/vertical.component";
import { HorizontalComponent as HeaderHorizontalComponent } from "./_layout/header/horizontal/horizontal.component";
import { FullLayoutNavbarComponent } from "./_layout/header/full-layout-navbar/full-layout-navbar.component";
import { FooterComponent } from "./_layout/footer/footer.component";
import { NavigationComponent as AppNavigationComponent } from "./_layout/navigation/navigation.component";
import { PublicLayoutComponent } from "./_layout/public-layout/public-layout.component";
import { PrivateLayoutComponent } from "./_layout/private-layout/private-layout.component";
import { NavbarService } from "./_services/navbar.service";
import { VerticalnavComponent } from "./_layout/navigation/verticalnav/verticalnav.component";
import { HorizontalnavComponent } from "./_layout/navigation/horizontalnav/horizontalnav.component";
// perfect scroll bar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
// spinner
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { DeviceDetectorService } from './_services/device-detector.service';
import { RouterModule } from '@angular/router';
import { CustomizerComponent } from './_layout/customizer/customizer.component';
import { NgChartsModule } from 'ng2-charts';
import { BreadcrumbModule } from './_layout/breadcrumb/breadcrumb.module';
import { HorizontalCustomizerComponent } from './_layout/customizer/horizontal-customizer/horizontal-customizer.component';
import { BlockTemplateComponent } from './_layout/blockui/block-template.component';
import { BlockUIModule } from 'ng-block-ui';
import { FullLayoutComponent } from './_layout/full-layout/full-layout.component';
import { ToastrModule } from 'ngx-toastr';
import { UserService } from './_api/user/user.service';
import { InvoiceComponent } from './content/invoice/invoice.component';
import { InvoiceViewComponent } from './content/invoice/invoice-view/invoice-view.component';
import { CardModule } from './content/card/card.module';
import { PartialsModule } from './content/partials/partials.module';
import { MatchHeightModule } from './content/partials/match-height/match-height.module';
import { MatchHeightDirective } from './_directives/match-height.directive';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NgFontawesomeModule } from "ng-fontawesome-icon";
import { ChartistModule } from "ng-chartist";
import { LoginComponent } from './motus/components/login/login.component';
import { CoachsListComponent } from './motus/components/coachs-list/coachs-list.component';
import { ClientsListComponent } from './motus/components/clients-list/clients-list.component';
import { OrganizationComponent } from './motus/components/organization/organization.component';
import { ProfileComponent } from "./motus/components/profile/profile.component";
import { AdminComponent } from "./motus/components/admin/admin.component";
import { BannerComponent } from './motus/components/banner/banner.component';

import { NgCircleProgressModule } from 'ng-circle-progress';
import { ClipboardModule } from 'ngx-clipboard';
import { ChatComponent } from "./motus/components/chat/chat.component";
import { DatetimePipe } from './motus/_pipes/datetime.pipe';
import { SetNewPasswordComponent } from './motus/components/set-new-password/set-new-password.component';
import { InvalidClientsComponent } from './motus/components/invalid-clients/invalid-clients.component';
import { ReportComponent } from './motus/components/report/report.component';
import { QuestionsComponent } from './motus/components/questions/questions.component';
import { QuestionsFiveComponent } from './motus/components/questions-five/questions-five.component';
import { SuccessComponent } from './motus/components/success/success.component';
import { MobileAppComponent } from './motus/components/mobile-app/mobile-app.component';
import { ShowAssessmentFormOutputComponent } from './motus/components/show-assessment-form-output/show-assessment-form-output.component';
import { PrivacyComponent } from './motus/components/privacy/privacy.component';
@NgModule({
  imports: [
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    ClipboardModule,
    MatchHeightModule,
    PartialsModule,
    CalendarMonthModule,
    CalendarModule,
    ChartistModule,
    NgxDropzoneModule,
    NgFontawesomeModule,
    BrowserModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "rgb(193, 186, 187)",
      animationDuration: 300,
      showTitle:false,
      showSubtitle:false,
      showUnits:false,
    }),
    ReactiveFormsModule,
    HttpClientModule,
    NgChartsModule,
    BrowserAnimationsModule,
    BreadcrumbModule,
    NgbModule,
    FormsModule,
    NgxPaginationModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    routing,
    // Settings modules
    SettingsModule.forRoot(ThemeSettingsConfig, MenuSettingsConfig),
    PerfectScrollbarModule,
    NgxDatatableModule,
    CardModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    LoadingBarRouterModule,
    BlockUIModule.forRoot({
      template: BlockTemplateComponent,
    }),
    
  ],
  declarations: [
    AppComponent,
    PublicLayoutComponent,
    PrivateLayoutComponent,
    HeaderComponent,
    FullLayoutNavbarComponent,
    HeaderHorizontalComponent,
    HeaderVerticalComponent,
    FooterComponent,
    AppNavigationComponent,
    AlertComponent,
    VerticalnavComponent,
    HorizontalnavComponent,
    CustomizerComponent,
    HorizontalCustomizerComponent,
    BlockTemplateComponent,
    FullLayoutComponent,
    InvoiceComponent,
    InvoiceViewComponent,
    LoginComponent,
    CoachsListComponent,
    ClientsListComponent,
    OrganizationComponent,
    ProfileComponent,
    AdminComponent,
    BannerComponent,
    ChatComponent,
    DatetimePipe,
    SetNewPasswordComponent,
    InvalidClientsComponent,
    ReportComponent,
    QuestionsComponent,
    QuestionsFiveComponent,
    SuccessComponent,
    MobileAppComponent,
    ShowAssessmentFormOutputComponent,
    PrivacyComponent,
  ],
  providers: [
    DatePipe,
    ChartApiService,
    AlertService,
    NavbarService,
    TableApiService,
    ApplicationApiService,
    DeviceDetectorService,
    QuillInitializeServiceService,
    UserService,
    // {
    //   provide: HAMMER_GESTURE_CONFIG,
    //   useClass: HammerGestureConfig
    // },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    NgbCarouselConfig,
    NgbModalConfig,
  ],
  bootstrap: [AppComponent],
  exports: [RouterModule],
})
export class AppModule {}
