import { Component, Input, } from '@angular/core';
import { ApiService } from '../../_services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import {  Renderer2, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-questions-five',
  templateUrl: './questions-five.component.html',
  styleUrls: ['./questions-five.component.css'],
})

export class QuestionsFiveComponent {
  @ViewChild('slider') slider!: ElementRef;
  progressWidth: number = 20; // Assuming 5 steps, so each step represents 20%
  logo_url = environment.S3_URL + 'motus9/logo.png';
  questions: any;
  member_id: string;
  form_datas: any = [];
  currentStep = 1;
  totalSteps = 5; // Update this if you have more or fewer steps

  value: number = 1; // Default value is set to 1
  max: number = 5;
  min: number = 0;
  step: number = 1;

  stepValues: number[] = [];

  valueOne: number = 1;
  valueThree: number = 1;
  onbording: any;
  required: string;

  constructor(
    private renderer: Renderer2,
    private ApiService: ApiService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute, 
    private activatedRoute: ActivatedRoute,
    private router :Router,
  ) {}

  ngOnInit() {
    this.generateStepValues();
    this.route.paramMap.subscribe(params => {
       this.member_id = params.get('id');
    });
    this.getOnbordingQuestions();
    
  }

  getOnbordingQuestions(){
    this.spinner.show();
    this.ApiService.getOnbordingQuestion().subscribe( (response:any) => {
      if (response.data.success) {
        this.onbording = response.data.data;
      } else {
        this.toastr.error(response.data.message);
      }
      this.spinner.hide();
    });
  }
  
  onSubmit(question_form: FormGroup) {
    let data =  question_form.value;
    let array = []
    Object.keys(data).forEach(key => {
      if(data[key]!== '') { 
        if(key?.split('.')[1] ){
            array.push({"question":key?.split('.')[1], "answer":[data[key]]})
        }
      }else{
        this.required = 'All fields are require';
        array.push({"question":key, "answer":[data[key]]})
      }
    });
    this.form_datas = array
    if (question_form.valid) {
        this.spinner.show();
        this.ApiService.createOnboardingFormAns(this.member_id, this.form_datas).subscribe(
          (response: any) => {
            if (response.data.success) {
              this.router.navigate(['/Success']);
            } else {
              this.toastr.warning(response.data.message);
            }
            this.spinner.hide();
          },
          (error) => {
            if(error.response.data.message == "Invalid initialization vector"){
              this.toastr.warning("Invalid user ID");
              this.spinner.hide();
            }else{
              this.toastr.error("Something went wrong");
              this.spinner.hide();
            }
          }
        );
    } else {
      console.log('Form is invalid==>',data);
    }


   
  
  }
 
  
  ngAfterViewInit() {
    this.updateSliderBackground();
  }

  generateStepValues() {
    for (let i = this.min; i <= this.max; i += this.step) {
      this.stepValues.push(i);
    }
  }

  getLeftPosition(value: number): number {
    const range = this.max - this.min;
    return ((value - this.min) / range) * 100;
  }

  onSliderChangeOne(event: any) {
    this.valueOne = event.target.value;
    this.updateSliderBackgroundOne();
  }

  updateSliderBackgroundOne() {
    const valuePercentage = ((this.valueOne - this.min) / (this.max - this.min)) * 100;
    // this.renderer.setStyle(this.slider.nativeElement, '--value', `${valuePercentage}%`);
  }

  onSliderChange(event: any) {
    this.value = event.target.value;
    this.updateSliderBackground();
  }

  updateSliderBackground() {
    const valuePercentage = ((this.value - this.min) / (this.max - this.min)) * 100;
    // this.renderer.setStyle(this.slider.nativeElement, '--value', `${valuePercentage}%`);
  }

  onSliderChangeThree(event: any) {
    this.valueThree = event.target.value;
    this.updateSliderBackgroundThree();
  }

  updateSliderBackgroundThree() {
    const valuePercentage = ((this.valueThree - this.min) / (this.max - this.min)) * 100;
    // this.renderer.setStyle(this.slider.nativeElement, '--value', `${valuePercentage}%`);
  }

  currentSteps(data: any) {
    if (data >= 1 && data <= this.totalSteps) {
      this.currentStep = data;
    }
  }

  
}
